import React from "react";

function SignUpSuccess() {
  return (
    <div 
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        minWidth: '300px',
        border: '1px solid #5a5a5a',
        padding: '1em',
        backgroundColor: 'white',
        zIndex: 10
      }}>
      <h1
        style={{textAlign: 'center', zIndex: 10}}
      >
        Thank you for signing up!
      </h1>
      <hr></hr>
      <p
        style={{textAlign: 'center', zIndex: 10}}
      >
        Please wait while we verify your information
      </p>
    </div>
  );
}

export default SignUpSuccess