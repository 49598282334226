import types from './types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import {fetchPatients} from '../../Patients/actions/fetchPatientsAction';
import {fetchBatches} from '../../Patients/actions/fetchBatchesAction'

export const onClickSignIn = (email, password, database, cookies, firestore) => (dispatch) => {
  firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        let currentPatientsData;
        database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid).on('value', (snapshot) => {
          const isDataLogged = false
          const snapshotVal = snapshot.val()
          if (currentPatientsData != snapshotVal.Patients) {
            currentPatientsData = snapshotVal.Patients;
            dispatch(fetchPatients(snapshotVal.Patients));
          }
          if (snapshotVal.OnlineTests && snapshotVal.OnlineTests.Count > 0) {
            const batchIdList = Object.keys(snapshotVal.OnlineTests.Tests)
            dispatch(fetchBatches(batchIdList, firestore, []))
          } else {
            dispatch(fetchBatches())
          }
          if (snapshotVal.Membership && snapshotVal.Membership.Active === 'created') {
            dispatch({
              type: types.SIGN_IN,
              payload: {
                signedIn: false,
                // userData: {...snapshotVal, Patients: null},
                userMessage: 'Please wait while we review your qualifications',
                subscriptionData: {
                  status: 'error',
                  subPlan: 'error',
                  subID: 'error',
                  cusID: 'error',
                },
              },
            });
          } else if (snapshotVal.Sub) {
            cookies.set('email', email, {path: '/'});
            cookies.set('password', password, {path: '/'});
            const dataToUse = snapshotVal.Sub;
            if (dataToUse['ID'] === 'Promo') {
              const checkTime = dataToUse['Expires'];
              const nowTime = Date.now();
              if (nowTime < checkTime) {
              // Go to Patient List

                dispatch({
                  type: types.SIGN_IN,
                  payload: {
                    signedIn: true,
                    userMessage: null,
                    userData: {...snapshotVal, Patients: null},
                    isSubscriptionActive: true,
                    subscriptionData: {
                      status: 'active',
                      subPlan: 'Promo',
                      expires: checkTime,
                      cusID: 'None',
                    },
                  },
                });
              } else {
                // alert('Your promo trial has finished.');

                // Go to Onboarding
                dispatch({
                  type: types.SIGN_IN,
                  payload: {
                    signedIn: true,
                    userMessage: null,
                    userData: snapshotVal,
                    isSubscriptionActive: false,
                    subscriptionData: {
                      status: 'Expired',
                      subPlan: 'Promo',
                      expires: checkTime,
                      cusID: 'None',
                    },
                  },
                });
              }
            } else {
              fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/checkSub?subID=` + dataToUse['ID'])
                  .then((res) => res.json())
                  .then((res) => {
                    if (res['status'] == 'active') {
                      dispatch({
                        type: types.SIGN_IN,
                        payload: {
                          signedIn: true,
                          userMessage: null,
                          userData: snapshotVal,
                          isSubscriptionActive: true,
                          subscriptionData: {
                            status: res['status'],
                            subPlan: res['plan']['id'],
                            subID: res['id'],
                            cusID: dataToUse['CusID'],
                            amount: res['items']['data'][0]['plan']['amount'],
                            interval: res['items']['data'][0]['plan']['interval'],
                            start: res['current_period_start'],
                            end: res['current_period_end'],
                          },
                        },
                      });
                    } else if (res['status'] == 'ERROR') {
                      if (isDataLogged) {
                        database.ref('Dermatology/Errors/Subscription/Open').push().update({
                          time: new Date().getTime,
                          type: 'checkSub',
                          error: res['note'],
                          user: firebase.auth().currentUser.uid,
                        });
                      }
                      dispatch({
                        type: types.SIGN_IN,
                        payload: {
                          signedIn: false,
                          userMessage: 'There was an error checking your subscription. Please try again',
                          subscriptionData: {
                            status: 'error',
                            subPlan: 'error',
                            subID: 'error',
                            cusID: 'error',
                          },
                        },
                      });
                    } else {
                      dispatch({
                        type: types.SIGN_IN,
                        payload: {
                          signedIn: true,
                          userMessage: null,
                          userData: snapshotVal,
                          isSubscriptionActive: false,
                          subscriptionData: {
                            status: res['status'],
                            subPlan: res['plan']['id'],
                            subID: res['id'],
                            cusID: dataToUse['CusID'],
                            amount: res['items']['data'][0]['plan']['amount'],
                            interval: res['items']['data'][0]['plan']['interval'],
                            start: res['current_period_start'],
                            end: res['current_period_end'],
                          },
                        },
                      });
                    }
                    // If res["status"] == "active", go to Patient List, else Onboarding
                  })
                  .catch(function(error) {
                    console.log(error)
                    if (isDataLogged) {
                      database.ref('Dermatology/Errors/Subscription/Open').push().update({
                        time: new Date().getTime,
                        type: 'checkSub',
                        error: error,
                        user: firebase.auth().currentUser.uid,
                      });
                    }
                    dispatch({
                      type: types.SIGN_IN,
                      payload: {
                        signedIn: false,
                        userMessage: 'There was an error checking your subscription. Please try again',
                      },
                    });
                  });
            }
          } else {
            // Go to settings if the user is approved
            cookies.set('email', email, {path: '/'})
            cookies.set('password', password, {path: '/'})
            dispatch({
              type: types.SIGN_IN,
              payload: {
                signedIn: true,
                userMessage: null,
                userData: snapshotVal,
                isSubscriptionActive: false,
                subscriptionData: {
                  status: 'error',
                  subPlan: 'error',
                  subID: 'error',
                  cusID: 'error',
                },
              },
            });
          }
        });
      })
      .catch(() => {
        console.log('sign in failure');
        dispatch({
          type: types.SIGN_IN,
          payload: {
            signedIn: false,
            userMessage: 'You entered an incorrect email, password, or both',
          },
        });
      });
};
