import React, { Component, Fragment } from 'react'
import ReactCrop from 'react-image-crop'
import { connect } from 'react-redux'
import 'react-image-crop/dist/ReactCrop.css'
import './index.scss'
import { postPersonImage } from '../../actions/postPersonImageAction.js'
import { toggleLoader } from '../../actions/toggleLoaderAction'
import plus from '../../../img/Plus.svg'
import firebase from 'firebase/app';
import 'firebase/auth';
import Loading from '../../../RootComponents/Loading'
import loadImage from '../../../../node_modules/blueimp-load-image/js'

class ImageUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cropHasBeenAdjusted: false,
      crop: {
        unit: '%',
        height: 100,
        width: 100,
        aspect: 1 / 1
      },

      minWidth: null,
      maxWidth: null,
      isImageSelected: false,
      imageFile: [],
      imageUrl: [],
      croppedImageFile: null,
      croppedImageUrl: null,
      isImageCropped: false,

      loading: false,
      tooSmall: false
    }
  }

  onSelectImage = event => {
    loadImage( event.target.files[0], (img) => {
      const blobPromise = new Promise((resolve, reject) => {
        if (img.height < 480 || img.width < 480) {
          // alert("Warning: Image should be at least 480 x 480 pixels")
          this.setState({ tooSmall: true })
        } else {
          this.setState({ tooSmall: false })
        }
        img.toBlob(blob => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'fileName';
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve([this.fileUrl, blob]);
        }, 'image/jpeg');
      });

      blobPromise
        .then((imageArr) => {
          this.setState({
            isImageSelected: true,
            imageUrl: imageArr[0],
            imageFile: imageArr[1],
            isImageCropped: false
          })
        })
    }, { orientation: true })
  }

  onImageLoaded = image => {
    this.imageRef = image;
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  }

  onCropChange = (crop, percentCrop) => {
    // if ( this.state.cropHasBeenAdjusted ) {
      this.setState({ crop: percentCrop })
    // } else {
    //   this.setState({ cropHasBeenAdjusted: true, crop: { ...percentCrop, unit: '%', x: 50 - (percentCrop.width / 2), y: 50 - (percentCrop.height / 2)  } });
    // }
    // this.setState({ crop });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      const croppedImageUrl = croppedImage[0]
      const croppedImageFile = croppedImage[1]

      this.setState({ croppedImageUrl, croppedImageFile });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const maxWidth = 960
    const shrinkRatio = maxWidth / image.naturalWidth
    
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    if (image.naturalWidth > maxWidth) {
      canvas.width = Math.floor(crop.width * scaleX * shrinkRatio);
      canvas.height = Math.floor(crop.height * scaleY * shrinkRatio);
    } else {
      canvas.width = Math.floor(crop.width * scaleX);
      canvas.height = Math.floor(crop.height * scaleY);
    }
    const ctx = canvas.getContext('2d')

    /* Math for guaranteeing minimum size of 480 x 480 */
    let minWidth

    if (image.naturalWidth > maxWidth) {
      minWidth =  480 / scaleX / shrinkRatio
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX * shrinkRatio,
        crop.height * scaleY * shrinkRatio,
      );
  
    } else {
      minWidth =  480 / scaleX
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
    }

    this.setState({ minWidth })

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
        }
        blob.name = fileName;
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve([this.fileUrl, blob]);
      }, 'image/jpeg');
    });
  }

  /* Triggered if this.props.isPatient is true (from AddPatient component) */
  onClickSubmitPersonImage(event, personType) {
    event.preventDefault()
    event.stopPropagation()
    let imageName2
    if (this.props.userData && this.props.userData.Info.ImageName !== '') {
      imageName2 = this.props.userData.Info.ImageName
    } else {
      imageName2 = ''
    }
    if (!this.state.isImageCropped) {
      this.setState({
        isImageCropped: true
      })
    } else {
      this.props.postPersonImage(
        imageName2,
        this.state.croppedImageFile,
        this.props.storage,
        this.props.database,
        personType
      ) // replace
      this.setState({ isImageSelected: false })
    }
  }

  onClickSubmitTestImage(ev) {
    if (!this.state.isImageCropped) {
      this.setState({
        cropHasBeenAdjusted: false,
        isImageCropped: true
      })
    } else {
      this.setState({
        isImageSelected: false
      })
      this.props.toggleLoader(true)
      const uuidv4 = require('uuid/v4');
      let storageRef = this.props.storage.ref("Tests")
      let self = this
      if (this.state.croppedImageFile) {
        let testname = uuidv4()
        let newRef = storageRef.child(testname).put(this.state.croppedImageFile).then((snapshot) => //edit this
          snapshot.ref.getDownloadURL().then((downloadURL) => {

            let newDate = new Date()
            let date = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let hour = newDate.getHours();
            let min = newDate.getMinutes();
            let sec = newDate.getSeconds();

            self.props.firestore.collection("Tests").doc(`${testname}`).set({
              DateTaken : `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`} ${hour<10?`0${hour}`:`${hour}`}:${min<10?`0${min}`:`${min}`}:${sec<10?`0${sec}`:`${sec}`}`,
              ImageRef : downloadURL
            })

            setTimeout(function() {
            (async () => {
              let dataToSend = JSON.stringify({
                patient : self.props.patientId2,
                ids : [testname]
              })
              const rawResponse = await fetch('https://us-central1-raw-compute.cloudfunctions.net/dermato-1', {
                method: 'POST',
                body: dataToSend
              });
              const content = await rawResponse.json();


              if (content["message"] == "Success!") {
                let newDate = new Date()
                let date = newDate.getDate();
                let month = newDate.getMonth() + 1;
                let year = newDate.getFullYear();
                let hour = newDate.getHours();
                let min = newDate.getMinutes();
                let sec = newDate.getSeconds();


                self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Patients/'+ self.props.patientId).update({
                  LastUpdate : `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`} ${hour<10?`0${hour}`:`${hour}`}:${min<10?`0${min}`:`${min}`}:${sec<10?`0${sec}`:`${sec}`}`
                })
                self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/' + month + "/Patients").once("value", snapshot => {
                  if (snapshot.exists()) {
                    let dataToUse = snapshot.val()
                    if (dataToUse.includes(self.props.patientId)) {
                    } else {
                      let numInput = String(dataToUse.length)
                      self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/' + month + "/Patients/" + numInput).set(self.props.patientId)
                    }
                  } else {
                    self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + '/' + month + "/Patients/0").set(self.props.patientId)
                  }
                })

                self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + "/Patients").once("value", snapshot => {
                  if (snapshot.exists()) {
                    let dataToUse = snapshot.val()
                    if (dataToUse.includes(self.props.patientId)) {
                    } else {
                      let numInput = String(dataToUse.length)
                      self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + "/Patients/" + numInput).set(self.props.patientId)
                    }
                  } else {
                    self.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientCount/' + year + "/Patients/0").set(self.props.patientId)
                  }
                })
                self.props.database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/TestsTaken`).once("value").then(function(snapshotNum) {
                  if (snapshotNum.exists()) {
                    self.props.database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/TestsTaken`).set(snapshotNum.val() + 1)
                  } else {
                    self.props.database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/TestsTaken`).set(1)
                  }
                })
                self.setState({
                  addImage: false,
                  grayScreen: false,
                  loading: false,
                  croppedImage: []
                })
              } else {
                self.setState({
                  grayScreen:false,
                  loading: false
                })
                alert("There was an error submitting your tests.")
              }

            })();
            }, 2500)
          })
        )
      } else {
        alert("There is no test to submit.")
      }
    }
  }

  onClickCancelCrop = (event) => {
    event.stopPropagation()
    this.state.isImageCropped
    ? this.setState({
      croppedImageUrl: null,
      croppedImageFile: null,
      isImageCropped: false
    })
    : this.setState({
      cropHasBeenAdjusted: false,
      croppedImageUrl: null,
      croppedImageFile: null,
      imageUrl: [],
      imageFile: [],
      isImageSelected: false,
      crop: {
        unit: '%',
        height: 100,
        width: 100,
        aspect: 1 / 1
      }
    })
    document.getElementById('AddImageInput').value = null
  }

  render() {

    const clickableRender = () => {
      if (this.props.isPhysician && this.props.physicianPhoto) { // for settings user image
        return (
          <img
            className="settings-img"
            src={this.state.croppedImageUrl
              ? this.state.croppedImageUrl
              : this.props.physicianPhoto}
            alt="User Image"
          />
        )
      } else if (this.props.isPhysician || this.props.isPatient) { // for sign up image
        return (
          <div id="add-image-div">
            <img
              src={this.state.croppedImageUrl
                ? this.state.croppedImageUrl
                : plus}
              alt='Add Image'
            />
          </div>
        )
      } else {
        return ( // for test image
          <h3 className="image-btn-h3">Upload Image</h3>
        )
      }
    }

    const cropper = !this.state.isImageCropped ?
      <ReactCrop
        className='elAddImageCropper'
        src={this.state.imageUrl}
        crop={this.state.crop}
        ruleOfThirds
        onImageLoaded={this.onImageLoaded}
        onComplete={this.onCropComplete}
        onChange={this.onCropChange}
        minWidth={this.state.minWidth}
        minHeight={this.state.minWidth}
        maxWidth={this.state.maxWidth}
        maxHeight={this.state.maxHeight}
        keepSelection={'true'}
      />
    : <img className='elAddImageCropper' src={this.state.croppedImageUrl}></img>

    const cropperContainer = this.state.isImageSelected && (
      <div className="cropperContainer">
        <div className="imageContainer">
          {cropper}
        </div>
        <div className="buttonContainer">
          <p
            className='cropper-btn'
            onClick={(event) => this.onClickCancelCrop(event)}>
            Cancel
          </p>
          <p
            className='cropper-btn'
            onClick={!this.props.isPatient && !this.props.isPhysician
            ? (event) => {
              event.preventDefault()
              this.onClickSubmitTestImage(event)
            }
            : (event) => {
              this.props.isPatient
              ? this.onClickSubmitPersonImage(event, "Patients")
              : this.onClickSubmitPersonImage(event, "Physicians")
              }
            }
          > { this.state.isImageCropped ? 'Submit' : 'Crop' }
          </p>
        </div>
        { this.state.tooSmall && !this.props.isPhysician && !this.props.isPatient &&
          <Fragment>
            <p className="center-text red-text margin-0">Warning: Image should be at least 480 x 480 pixels</p>
            <p className="center-text red-text margin-0">For best results, choose a photo with higher resolution</p>
          </Fragment>
        }
      </div>
    )

    return (
      <Fragment>
        <form>
          <label className="upload-image-label" htmlFor="AddImageInput">
            { clickableRender() }
          </label>
          <input
            hidden
            id="AddImageInput"
            type="file"
            accept="image/*"
            onChange={this.onSelectImage}
            onClick={(event)=> {
              event.target.value = null
            }}
          />
          {cropperContainer}
        </form>
        { this.state.loading && <Loading /> }
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientList: state.patientsReducer.patientList,
  imageName: state.patientsReducer.imageName,
  userData: state.authReducer.userData
})

export default connect(mapStateToProps, { postPersonImage, toggleLoader })(ImageUpload)
