import React, {Component, Fragment} from 'react'
import './App.scss'
import {connect, Provider} from 'react-redux'
import {Route, BrowserRouter as Router} from 'react-router-dom'
import {withRouter} from "react-router";
import {withCookies} from 'react-cookie'
import queryString from 'query-string'

// Actions
import {createFirebaseApp} from './actions/createFirebaseAppAction'
import {onClickSignIn} from './Auth/actions/signInAction'

// Firebase
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'

// Components
import SignIn from './Auth/components/SignIn'
import SignUp from './Auth/components/SignUp'
import Patients from './Patients'
import AddPatient from './AddPatient'
import Patient from './Patients/components/Patient'
import Batch from './Patients/components/Batch'
import Test from './Patients/components/Test'
import Nav from './Patients/components/Navbar'
import Settings from './Settings'
import PayoutConnect from './Payout'
import OnBoard from './RootComponents/OnBoard'
import Loading from './RootComponents/Loading'
import SignUpSuccess from './Auth/components/SignUpSuccess'

import store from './store'

class App extends Component {
  /* Renders pages to their respective paths
     Handles cookies for signing in
  */

  constructor(props) {
    super(props);
    this.state = {
      signUp: false,
      cookies: false,
      signedIn: false,
      /* cookies:
            false:
              -cookies don't exist
              -cookies were unsuccessfull in sign in ('incorrect' userMessage)
            true:
              -cookies exist && sign in hasn't been attempted
      */
    }

    this.switchInAndUp = this.switchInAndUp.bind(this)
    this.signOut = this.signOut.bind(this)
    this.areCookiesStored = this.areCookiesStored.bind(this)
  }

  checkDevMode() { // Ask Anish...
    /*
      Will pull the following json from firebase:
        [
          "State" : string,
          "Message" : string
        ]
    */
    this.props.database.ref('Dermatology/Status').on('value', (snapshot) => {
      const snapCheck = snapshot.val()
      if (snapCheck['State'] != 'UP') {
        this.setState({
          devmode: true,
          message: snapCheck['Message'],
        })
      } else {
        this.setState({
          devMode: false,
        })
      }
    })
  }

  componentDidMount() {
    console.log('Deployed on 9/4 at 4:58')
    createFirebaseApp()
    const firebasePromise = new Promise((resolve, reject) => {
      resolve(this.props.createFirebaseApp())
    })
    firebasePromise.then(() => {
      // this.checkDevMode()
    })
  }

  switchInAndUp() {
    // Switch sign in link with sign up link
    this.setState({signUp: !this.state.signUp})
  }

  signOut() {
    this.props.signOut()
  }

  UNSAFE_componentWillReceiveProps(props) {
    const isNewUserMessage = (
      props.userMessage &&
      props.userMessage.length > 0 &&
      this.state.userMessage !== props.userMessage
    )
    if (isNewUserMessage) { // keep user message without clearing
      this.setState({userMessage: props.userMessage, signedIn: props.signedIn})
    } else {
      this.setState({signedIn: props.signedIn})
    }
  }

  areCookiesStored() {
    return (
      this.props.cookies &&
      this.props.cookies.get('email') &&
      this.props.cookies.get('password')
    )
  }

  componentDidUpdate() {
    if (!this.state.cookies && !this.props.signedIn) {
      if (this.areCookiesStored()) {
        // cookies exist

        const email = this.props.cookies.get('email')
        const password = this.props.cookies.get('password')

        if (email && password &&
            Object.keys(this.props.database).length > 0 &&
            !this.state.userMessage && !this.state.cookies) {
          // database exists and sign in hasn't been attempted
          this.setState({cookies: true})
          this.props.onClickSignIn(
              email,
              password,
              this.props.database,
              this.props.cookies,
              this.props.firestore,
          )
        }
      } else {
        // cookies don't exist
      }
    }
    if (this.state.userMessage &&
        this.state.userMessage.includes('incorrect') &&
        this.state.cookies) {
      // Cookies are incorrect
      this.setState({cookies: false})
    }
  }

  render() {
    console.log('9/24/2020 12.25')
    const customRoute = (path, component, isExact = false) => {
      // Handles logging in with cookies, loading screen, and redirect to login
      if (this.props.signedIn) {
        const defaultPath = (
          isExact ?
          <Route exact path={path} component={component} /> :
          <Route path={path} component={component} />
        )
        return defaultPath
      } else if (this.areCookiesStored() &&
        (!this.state.userMessage || !this.state.userMessage.includes('incorrect'))
      ) {
        // No signin attempt or no unsuccessful signin attempt
        return <Route path={path} component={Loading} />
      } else {
        return isExact ?
        <Route exact path={path} component={SignIn} /> :
        <Route path={path} component={SignIn} />
      }
    }

    return (
      <Provider store={store}>
        <Router>
          <Fragment>
            {this.props.signedIn &&
              <Route path="/" component={Nav} />
            }
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/add" component={AddPatient} />
            <Route exact path="/signupsuccess" component={SignUpSuccess} />
            {customRoute('/', Patients, true)}
            {customRoute('/patient', Patient)}
            {customRoute('/batch', Batch)}
            {customRoute('/settings', Settings)}
            {/* Currently doesn't support direct path to test */}
            <Route path='/test' component={Test} />
            <Route exact path='/plan' component={OnBoard} />
            <Route path='/payoutConnect' component={PayoutConnect} />
          </Fragment>
        </Router>
      </Provider>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  signedIn: state.authReducer.signedIn,
  userMessage: state.authReducer.userMessage,
  patientList: state.patientsReducer.patientList,
  isSubscriptionActive: state.authReducer.isSubscriptionActive,
  userData: state.authReducer.userData,
  cookies: ownProps.cookies,
  batches: state.patientsReducer.batches,
})

export default withRouter(withCookies(connect(mapStateToProps, {createFirebaseApp, onClickSignIn})(App)))
