import types from './types'

export const storeTestSetData = (test, patient, testSet, testSetIndex, testIndex) => (dispatch) => {
   dispatch({
     type: types.STORE_TESTSET_DATA,
     payload: {
       test,
       patient,
       testSet,
       notes: testSet.Notes,
       testSetIndex: testSetIndex,
       testIndex: testIndex
     }
    })
}