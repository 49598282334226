import React, { Component, Fragment } from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import { Page, Text, Font, Canvas, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import lazarusAI from '../../../img/lazarusAI2.png'
import Dm from '../../../img/Dm.png'
import profile from '../../../img/Profile.png'
import workSans from '../../../fonts/WorkSans-Regular.ttf'
import bold from '../../../fonts/WorkSans-SemiBold.ttf'
import no from '../../../img/No.jpg'
import yes from '../../../img/Yes.jpg'
import circle1_3 from '../../../img/1_3.jpg'
import circle2_3 from '../../../img/2_3.jpg'
import circle3_3 from '../../../img/3_3.jpg'

class TestPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    Font.register({
      family: 'Work Sans',
      fonts: [
        { src: workSans },
        { src: bold, fontWeight: 'bold'}
      ]
      })
    
    const wirePersonHeight = 232
    const wirePersonWidth = 165

    const styles = StyleSheet.create({
      page: {
        // height: "774",
        // width: 612
      },
      pageContainer: {
        width: "90vw",
        height: "95vh",
        margin: "5vw",
        marginBottom: 0
      },
      header: {
        justifyContent: "flex-start",
        flexDirection: "row",
      },
      flex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
      flexColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
      },
      patientSection: {
        width: "90vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
      },
      decisionRect: {
        width: "22vw",
        height: 24
      },
      mainSection: {
        width: "90vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
      },
      headingMarginBottom: {
        marginBottom: 12
      },
      marginBottom: {
        marginBottom: "2vw"
      },
      marginBottom2: {
        marginBottom: "3vw"
      },
      marginBottom3: {
        marginBottom: "4vw"
      },
      imageContainer: {
        width: "5vw"
      },
      dm: {
        width: "5vw",
      },
      twoEighteenths: {
        width: "11vw"
      },
      fourEighteenths: {
        width: "22vw",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        marginRight: "2vw"
      },
      alignCenter: {
        alignItems: "center"
      },
      fourteenEighteenths: {
        width: "65vw"
      },
      fontTwelve: {
        fontFamily: 'Work Sans',
        fontSize: 12
      },
      workSans: {
        fontFamily: 'Work Sans',
      },
      aside: {
        width: "22vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginRight: "2vw"
      },
      name: {
        marginBottom: "1vw",
        fontFamily: 'Work Sans'
      },
      bold: {
        fontFamily: 'Work Sans',
        fontWeight: 'bold',
      },
      marginLeft: {
        marginLeft: "1vw"
      },
      dob: {
        marginRight: "5vw"
      },
      height2: {
        height: "5vw"
      },
      pageNumber: {
        // fontFamily: 'Times-Roman',
        position: 'absolute',
        fontSize: 12,
        bottom: "3vw",
        width: "100%",
        // left: "-5vw",
        right: 0,
        textAlign: 'center',
        color: 'grey',
      },
      canvas: {
        width: '90vw',
        height: 15,
        marginBottom: 10,
        marginTop: 25
      },
      circleView: {
        position: 'relative',
        height: '12vw',
        width: '22vw',
        padding: '1vw'
      },
      yesOrNo: {
        height: '10vw',
        width: '10vw',
        // objectPosition: '50%, 50%',
        // position: 'absolute',
        // left: '50%',
        // top: '50%',
        // transform: 'translate(-50%, -50%)'
      },
      testCircle: {
        height: '10vw',
        width: '10vw',
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2
      },
      // circleText: {
      //   // objectPosition: '50%, 50%',
      //   // left: '50%',
      //   top: '-77%',
      //   left: '9%'
      // },
      footer: {
        position: 'absolute',
        bottom: "3vw",
        width: "90vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
      logoContainer: {
        width: "18vw",
        marginRight: "2vw"
      },
      positionAbsolute: {
        position: "absolute"
      },
      positionAbsolute2: {
        position: "absolute",
        top: 4
      },
      lazarusAI: {
        width: "18vw"
      },
      moleView: {
        width: "38vw",
        marginRight: "1vw",
        marginBottom: "3vw"
      },
      notesView: {
        width: "37vw",
        marginRight: "2vw",
        marginBottom: "3vw"
      },
      wireColumn: {
        width: "27vw",
        justifyContent: "space-between"
      },
      wireColumn2: {
        width: "27vw",
      },
      wireView: {
        // height: "38vw",
        height: 232, // equivalent of 38vw (based on 72 dpi) (8.5 * .38 * 72 = 232 ...slightly too big)
        // width: 161,
        marginBottom: "3vw",
        position: "relative"
      },
      wirePerson: {
        position: 'absolute'
      },
      wireLine: {
        position: 'absolute',
        height: wirePersonHeight,
        width: wirePersonWidth,
        zIndex: 2
      },
      testResults: {
        width: "38vw",
        marginRight: "1vw",
      },
      z2: {
        zIndex: 2
      }
    })
    

    // For some reason using this.props.testSet.Tests[0].data.ImageRef doesn't work but this does

    const {testSet} = this.props
    // const test1 = testSet.Tests[0]
    // const test2 = testSet.Tests[1]
    // const test3 = testSet.Tests[2]

    const xAxis = Math.floor(testSet.MoleCoordinates[1] / 100 * 232)
    const yAxis = Math.floor(testSet.MoleCoordinates[0] / 100 * 165)

    const testNumber = (i) => {
      switch (i) {
        case 0: return circle1_3
        case 1: return circle2_3
        case 2: return circle3_3
      }
    }

    return (
      <Document>
        <Page size={"LETTER"} style={styles.page}>
          <View style={styles.pageContainer} debug={false}>
          <View style={styles.header}>
            <View style={[styles.fourEighteenths, styles.alignCenter]}>
              <View style={styles.imageContainer}>
                <Image src={Dm} style={styles.dm}></Image>
              </View>
              <Text style={[styles.fontTwelve, styles.bold, styles.marginLeft]}>Dermatology</Text>
            </View>
            <View style={styles.fourteenEighteenths}>
              <Text style={styles.fontTwelve}>{this.props.physicianInfo.Practice || ''}</Text>
              <Text style={[styles.fontTwelve, styles.bold]}>{this.props.physicianInfo.Name || ''}</Text>
            </View>
          </View>
          <Canvas
              style={styles.canvas}
              paint={painter => 
                painter
                .moveTo(0, 0)
                .lineTo(600, 0)
                .stroke()
              }
            />
          <View style={styles.patientSection}>
            <View style={styles.fourEighteenths}>
              <View style={styles.twoEighteenths}>
                <Image src={profile}></Image>
              </View>
            </View>
            <View style={styles.fourteenEighteenths}>
              <Text style={styles.fontTwelve}>Patient</Text>
              <Text style={[styles.name, styles.bold]}>{this.props.patient.name}</Text>
              <View style={styles.flex}>
                <View style={styles.dob}>
                  <Text style={styles.fontTwelve}>Date of Birth</Text>
                  <Text style={[styles.fontTwelve, styles.bold]}>{this.props.patientBirthday}</Text>
                </View>
                <View>
                  <Text style={styles.fontTwelve}>Sex</Text>
                  <Text style={[styles.bold, styles.fontTwelve]}>{this.props.patient.sex || 'N/A'}</Text>
                </View>
              </View>
            </View>
          </View>
          <Canvas
              style={styles.canvas}
              paint={painter => {
                painter
                .moveTo(0, 0)
                .lineTo(600, 0)
                .stroke()
              }}
            />
          <View style={styles.mainSection}>
            <View style={styles.aside}>
              <View style={[styles.marginBottom3, styles.height2]}>
                <Text style={styles.fontTwelve}>
                  Test ID
                </Text>
                <Text style={styles.bold}>
                  {this.props.testSetId}
                </Text>
              </View>
              <View style={styles.marginBottom2}>
                <Text style={styles.fontTwelve}>
                  Test Date
                </Text>
                <Text style={[styles.fontTwelve, styles.bold]}>
                  {this.props.testDate}
                </Text>
              </View>
              <View>
                <Text style={[styles.fontTwelve, styles.bold, styles.marginBottom]}>
                  TEST RESOLVED?
                </Text>
                <View style={[styles.circleView, styles.marginBottom2]}>
                  <Image style={styles.yesOrNo} src={this.props.isEmailSent ? yes : no}></Image>
                </View>
              </View>
              {this.props.isEmailSent && this.props.decision &&
              <View>
                <Text style={[styles.fontTwelve, styles.bold, styles.headingMarginBottom]}>
                  DECISION
                </Text>
                  <Text style={styles.fontTwelve}>
                    {this.props.decision === 'malignant' && 'Possibly Malignant'}
                    {this.props.decision === 'benign' && 'Benign'}
                  </Text>
                  {/* <Canvas
                    style={[styles.decisionRect, styles.positionAbsolute]}
                    paint={painter => {
                      painter
                      .moveTo(0, 0)
                      .lineWidth(2)
                      .rect(0, 0, 120, 23)
                      .stroke()
                    }}
                  /> */}
              </View>
              }
              {/* <View>
                <Text style={[styles.fontTwelve, styles.bold]}>
                  PHYSICIAN NOTES
                </Text>
                <Text style={styles.fontTwelve}>
                  {test1.physicianNotes}
                </Text>
              </View> */}
            </View>
            <View style={styles.fourteenEighteenths}>
              <View style={[styles.marginBottom3, styles.height2]}>
                <Text style={styles.bold}>TEST SUMMARY</Text>
              </View>
              <View style={styles.flex}>
                  <View style={[styles.moleDiffView, styles.flexColumn]}>
                  <View style={styles.notesView}>
                    <Text style={[styles.fontTwelve, styles.bold, styles.headingMarginBottom]}>PATIENT NOTES</Text>
                    <Text style={[styles.fontTwelve, styles.marginBottom2]}>{this.props.testSet.Notes || 'N/A'}</Text>
                    <Text style={[styles.fontTwelve, styles.bold, styles.headingMarginBottom]}>OUTREACH MESSAGE</Text>
                    <Text style={[styles.marginBottom, styles.fontTwelve, styles.marginBottom2]}>{this.props.physicianMessage || 'N/A'}</Text>
                  </View>
                  </View>
                  <View style={[styles.flexColumn, styles.wireColumn2]}>
                    <Text style={[styles.fontTwelve, styles.bold, styles.headingMarginBottom]}>LOCATION</Text>
                    <Text style={[styles.fontTwelve, styles.marginBottom2]}>
                      {testSet.MoleLocation}
                    </Text>
                    <View style={styles.wireView}>
                      <Image src={this.props.wirePerson}></Image>
                      <Canvas
                        style={styles.wireLine}
                        paint={painter => {
                          painter
                          .moveTo(0, xAxis)
                          .lineTo(wirePersonWidth, xAxis)
                          .stroke('#919191')
                        }}
                      />
                      <Canvas
                        style={styles.wireLine}
                        paint={painter => {
                          painter
                          .moveTo(yAxis, 0)
                          .lineTo(yAxis, wirePersonHeight)
                          .stroke('#919191')
                        }}
                      />
                    </View>
                  </View>
                </View>
              {/* <View style={styles.flex}>
                <View style={styles.moleView}>
                  <Text style={[styles.fontTwelve, styles.bold]}>PATIENT NOTES</Text>
                  <Text style={styles.fontTwelve}>{this.props.testSet.Notes || 'N/A'}</Text>
                  <Text style={[styles.fontTwelve, styles.bold]}>OUTREACH MESSAGE</Text>
                  <Text style={[styles.marginBottom, styles.fontTwelve, styles.marginBottom2]}>{this.props.physicianMessage || 'N/A'}</Text>
                </View>
                <View style={styles.wireView}>
                  <Image src={this.props.wirePerson}></Image>
                  <Canvas
                    style={styles.wireLine}
                    paint={painter => {
                      painter
                      .moveTo(0, xAxis)
                      .lineTo(wirePersonWidth, xAxis)
                      .stroke('#919191')
                    }}
                  />
                  <Canvas
                    style={styles.wireLine}
                    paint={painter => {
                      painter
                      .moveTo(yAxis, 0)
                      .lineTo(yAxis, wirePersonHeight)
                      .stroke('#919191')
                    }}
                  />
                </View>
              </View> */}
            </View>
          </View>
          <View style={styles.footer}>
            <View style={styles.logoContainer}>
              <Image src={lazarusAI} style={styles.lazarusAI}></Image>
            </View>
          </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`)} fixed
          />
          </View>
        </Page>
        {testSet.Tests.map((test, i) => {
          return (
            <Page size={"LETTER"} style={styles.page} key={i}>
            <View style={styles.pageContainer}>
              <View style={styles.header}>
                <View style={[styles.fourEighteenths, styles.alignCenter]}>
                  <View style={styles.imageContainer}>
                    <Image src={Dm} style={styles.dm}></Image>
                  </View>
                  <Text style={[styles.fontTwelve, styles.bold, styles.marginLeft]}>Dermatology</Text>
                </View>
                <View style={styles.fourteenEighteenths}>
                  <Text style={styles.fontTwelve}>{this.props.physicianInfo.Practice || ''}</Text>
                  <Text style={[styles.fontTwelve, styles.bold]}>{this.props.physicianInfo.Name || ''}</Text>
                </View>
              </View>
              <Canvas
                  style={styles.canvas}
                  paint={painter => 
                    painter
                    .moveTo(0, 0)
                    .lineTo(600, 0)
                    .stroke()
                  }
                />
              <View style={styles.patientSection}>
                <View style={styles.fourEighteenths}>
                  <View style={styles.twoEighteenths}>
                    <Image src={profile}></Image>
                  </View>
                </View>
                <View style={styles.fourteenEighteenths}>
                  <Text style={styles.fontTwelve}>Patient</Text>
                  <Text style={[styles.name, styles.bold]}>{this.props.patient.name}</Text>
                  <View style={styles.flex}>
                    <View style={styles.dob}>
                      <Text style={styles.fontTwelve}>Date of Birth</Text>
                      <Text style={styles.fontTwelve}>{this.props.patientBirthday}</Text>
                    </View>
                    <View>
                      <Text style={styles.fontTwelve}>Sex</Text>
                      <Text style={styles.fontTwelve}>{this.props.patient.sex || 'N/A'}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <Canvas
                  style={styles.canvas}
                  paint={painter => {
                    painter
                    .moveTo(0, 0)
                    .lineTo(600, 0)
                    .stroke()
                  }}
                />
              <View style={styles.mainSection}>
                <View style={styles.aside}>
                  <View style={[styles.marginBottom3, styles.height2]}>
                    <Text style={styles.fontTwelve}>
                      Test ID
                    </Text>
                    <Text style={styles.bold}>
                      {this.props.testSetId}
                    </Text>
                  </View>
                  <View style={styles.marginBottom2}>
                    <Text style={styles.fontTwelve}>
                      Test Date
                    </Text>
                    <Text style={[styles.fontTwelve, styles.bold]}>
                      {this.props.testDate}
                    </Text>
                  </View>
                  <View>
                    <View style={[styles.circleView, styles.marginBottom3]}>
                      <Image style={styles.testCircle} src={testNumber(i)}></Image>
                    </View>
                  </View>
                  {/* <View>
                    <Text style={[styles.fontTwelve, styles.bold, styles.marginBottom]}>
                      LOCATION
                    </Text>
                    <Text style={[styles.fontTwelve, styles.marginBottom]}>
                      {testSet.MoleLocation}
                    </Text>
                  </View> */}
                  <View>
                    <Text style={[styles.fontTwelve, styles.bold, styles.headingMarginBottom]}>
                      PHYSICIAN NOTES
                    </Text>
                    <Text style={styles.fontTwelve}>
                      {test.PhysicianNotes || 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={styles.fourteenEighteenths}>
                  <View style={[styles.marginBottom3, styles.height2, styles.flex]}>
                    <View style={styles.testResults}>
                      <Text style={styles.bold}>TEST RESULTS</Text>
                    </View>
                    {/* <View style={styles.moleLocation}>
                      <Text style={[styles.bold, styles.fontTwelve]}>LOCATION</Text>
                    </View> */}
                  </View>
                  <View style={styles.flex}>
                    <View style={[styles.moleDiffView, styles.flexColumn]}>
                      <View style={styles.moleView}>
                        <Image src={test.data.ImageRef}></Image>
                      </View>
                      <View style={styles.diff}>
                        <Text style={[styles.bold, styles.fontTwelve, styles.marginBottom2]}>DIFFERENTIAL</Text>
                        {test.data.Reasoning.map((condition, i)=> {
                          const lastDigit = condition.Confidence.toString().indexOf('.') + 3
                          const confidence = condition.Confidence.toString().slice(0, lastDigit)
                          return (
                            <Text
                              style={
                                condition.Name === test.data.Results ? 
                                [styles.bold, styles.fontTwelve, styles.marginBottom] : 
                                [styles.fontTwelve, styles.marginBottom]}
                              key={'1-' + i}>{condition.Name + ': ' + confidence + '%'}
                            </Text>
                          )
                        })
                        }
                      </View>
                    </View>
                    <View style={[styles.flexColumn, styles.wireColumn]}>
                      <View style={styles.wireView}>
                        <Image src={this.props.wirePerson}></Image>
                        <Canvas
                          style={styles.wireLine}
                          paint={painter => {
                            painter
                            .moveTo(0, xAxis)
                            .lineTo(wirePersonWidth, xAxis)
                            .stroke('#919191')
                          }}
                        />
                        <Canvas
                          style={styles.wireLine}
                          paint={painter => {
                            painter
                            .moveTo(yAxis, 0)
                            .lineTo(yAxis, wirePersonHeight)
                            .stroke('#919191')
                          }}
                        />
                      </View>
                      <Text style={[styles.bold, styles.fontTwelve, styles.marginBottom2]}>AI HEAT MAP</Text>
                      <View style={styles.heatMapView}>
                        <Image src={test.data.HeatMapRef}></Image>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.footer}>
                <View style={styles.logoContainer}>
                  <Image src={lazarusAI} style={styles.lazarusAI}></Image>
                </View>
              </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`)} fixed
              />
            </View>
          </Page>
          )
        })}
      </Document>
    )
  }
}

export default TestPdf