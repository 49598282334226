const types = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_UP: 'SIGN_UP',
  CLEAR_USER_MESSAGE: 'CLEAR_USER_MESSAGE',
  CLEAR_PROMO_MESSAGE: 'CLEAR_PROMO_MESSAGE',
  HIDE_NAV: 'HIDE_NAV',
  SUBMIT_PROMO_CODE: 'SUBMIT_PROMO_CODE',
  STORE_USAGE: 'STORE_USAGE',
  FETCH_SUBSCRIPTION: 'FETCH_SUBSCRIPTION',
  ACTIVATE_SUBSCRIPTION: 'ACTIVATE_SUBSCRIPTION',
  STORE_COOKIES: 'STORE_COOKIES'
}

export default types
