import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import StripeCheckout from 'react-stripe-checkout'
import { hideNav } from '../Auth/actions/hideNavAction'
import { submitPromoCode } from '../Auth/actions/submitPromoCodeAction'
import { handleTokenClick } from '../Settings/actions/handleTokenClickAction'
import { activateSubscription } from '../Auth/actions/activateSubscriptionAction'
import { clearPromoMessage } from '../Auth/actions/clearPromoMessageAction'
import { signOut } from '../Auth/actions/signOutAction'
import check from '../img/Check.svg'
import exclam from '../img/Exclamation.svg'
import greenCheck from '../img/GreenCheck.svg'
import '../index.scss';
import logo from '../img/Dm.svg'
import Loading from '../RootComponents/Loading'
import { withCookies, Cookies } from 'react-cookie';

class OnBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      perPatient: true,
      promoCode: '',
      isLoadingDone: true
    }

    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    this.props.hideNav(true)
    let mainHeight = window.innerHeight
    this.setState({ mainHeight })
    window.addEventListener('resize', () => {
      mainHeight = window.innerHeight
      this.setState({ mainHeight })
    })
  }

  componentDidUpdate() {
    if (this.state.userMessage || this.state.promoMessage) {
      setTimeout(() => {
        this.setState({ userMessage: null, promoMessage: null })
      }, 3000);
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  submitPromo = () => {
    if (!this.state.promoMessage) {
      if (this.state.promoCode.length === 0) {
        this.setState({ promoMessage: 'Please enter your promo code'})
      } else {
        this.props.submitPromoCode(this.props.database, this.state.promoCode)
      }
    }
  }

  onClickStart = (token, plan) => {
    const data = {
      ...this.props.userSubscription,
      name: this.props.userData.Info.Name,
      email: this.props.userData.Info.ContactEmail
    }
    this.props.handleTokenClick(this.props.database, token, plan, data)
  }

  componentWillReceiveProps(props) {
    if (props.userMessage && props.userMessage.includes('updat')) {
      this.props.activateSubscription()
    }
    this.setState({ isLoadingDone: props.isLoadingDone, userMessage: props.userMessage, promoMessage: props.promoMessage })
    setTimeout(() => {
      this.props.clearPromoMessage()
    }, 3000);
  }

  render() {
    return (
      <Fragment>
      <Fragment>
      { this.state.isLoadingDone ?
        <div id="onboard-page" style={{ height: `${this.state.mainHeight - 10}px` }}>
          <div id="onboard-container">
            <div id="onboard-logo-div">
              <img src={logo} />
            </div>
            <div id="onboard-welcome-div">
              <h1>Welcome to Lazarus Clinical Decision Support</h1>
              <h3 className="not-bold">Please Select a Service Plan</h3>
              <p className="grey-text">Select the size and duration of the plan
              that is right for you. You can always edit the terms of your agreement
              on the settings page. Payment will be processed as soon as you complete
              the form.
              </p>
            </div>
            <aside id ="onboard-aside">
              <div
                className={
                  this.state.monthly
                  ? 'settings-aside-div selected-div'
                  : 'settings-aside-div'
                }
                onClick={() => this.setState({ monthly: true, yearly: false, perPatient: false })}
              >
                <h3>Monthly</h3>
              </div>
              <div
                className={
                  this.state.yearly
                  ? 'settings-aside-div selected-div'
                  : 'settings-aside-div'
                }
                onClick={() => this.setState({ monthly: false, yearly: true, perPatient: false })}
              >
                <h3>Yearly</h3>
              </div>
              <div
                className={
                  this.state.perPatient
                  ? 'settings-aside-div selected-div'
                  : 'settings-aside-div'
                }
                onClick={() => this.setState({ monthly: false, yearly: false, perPatient: true })}
              >
                <h3>Per Patient</h3>
              </div>
              { this.state.userMessage && !this.state.promoMessage &&
                <div className={
                  (this.state.userMessage && this.state.userMessage.includes('error')) ? 'failure-snack slideInLeft' : 'success-snack slideInLeft'
                  }
                >
                  <img src={ this.state.userMessage.includes('error') ? exclam : check } />
                  <p>{ this.state.userMessage }</p>
                </div>
              }
              { this.state.promoMessage &&
                <div className={
                  this.state.promoMessage && !this.state.promoMessage.includes('registered') ? 'failure-snack slideInLeft' : 'success-snack slideInLeft'
                  }
                >
                  <img src={ this.state.promoMessage.includes('Error') || this.state.promoMessage.includes('Please') ? exclam : check } />
                  <p>{ this.state.promoMessage }</p>
                </div>
              }
            </aside>
            <main id="onboard-main">
              { this.state.monthly &&
              <Fragment>
                <div id="onboard-main-row">
                  <div className="onboard-main-div">
                    <div>
                      <h3>Basic</h3>
                      <h1 className="green-text">$300/mo</h1>
                    </div>
                    <div className="per-month-div">
                      <div className="row">
                        <img src={greenCheck} /><p>AI screening for 100 patients per month</p>
                      </div>
                    </div>
                    { this.props.userSubscription && this.props.userSubscription.subPlan === 'month-basic' 
                    ?
                    <div id="current-plan" className="onboard-btn">
                      <p>Current Plan</p>
                    </div>
                    :
                    <StripeCheckout
                      stripeKey = {process.env.REACT_APP_STRIPE_KEY}
                      token = {token => this.onClickStart(token, 'month-basic')}
                      email={ this.props.userData ? this.props.userData.Info.ContactEmail : null }
                    >
                      <div className="onboard-btn">
                        <p>Start</p>
                      </div>
                    </StripeCheckout>
                    }
                  </div>
                  <div className="onboard-main-div">
                    <div>
                      <h3>Standard</h3>
                      <h1 className="green-text">$600/mo</h1>
                    </div>
                    <div className="per-month-div">
                      <div className="row">
                        <img src={greenCheck} /><p>AI screening for 200 patients per month</p>
                      </div>
                      <div className="row">
                        <img src={greenCheck} /><p>EHR integration</p>
                      </div>
                    </div>
                    { this.props.userSubscription && this.props.userSubscription.subPlan === 'month-standard' 
                    ?
                    <div id="current-plan" className="onboard-btn">
                      <p>Current Plan</p>
                    </div>
                    :
                    <StripeCheckout
                      stripeKey = {process.env.REACT_APP_STRIPE_KEY}
                      token = {token => this.onClickStart(token, 'month-standard')}
                      email={ this.props.userData ? this.props.userData.Info.ContactEmail : null }
                    >
                      <div className="onboard-btn">
                        <p>Start</p>
                      </div>
                    </StripeCheckout>
                    }
                  </div>
                  <div className="onboard-main-div last-third">
                    <div>
                      <h3>Premium</h3>
                      <h1 className="green-text">$1,200/mo</h1>
                    </div>
                    <div className="per-month-div">
                      <div className="row">
                        <img src={greenCheck} /><p>AI screening for 400 patients per month</p>
                      </div>
                      <div className="row">
                        <img src={greenCheck} /><p>EHR integration</p>
                      </div>
                    </div>
                    { this.props.userSubscription && this.props.userSubscription.subPlan === 'month-premium' 
                    ?
                    <div id="current-plan" className="onboard-btn">
                      <p>Current Plan</p>
                    </div>
                    :
                    <StripeCheckout
                      stripeKey = {process.env.REACT_APP_STRIPE_KEY}
                      token = {token => this.onClickStart(token, 'month-premium')}
                      email={ this.props.userData ? this.props.userData.Info.ContactEmail : null }
                      style={{ borderRadius: '0' }}
                    >
                      <div className="onboard-btn">
                        <p>Start</p>
                      </div>
                    </StripeCheckout>
                    }
                  </div>
                </div>
              </Fragment>
              }
              { this.state.yearly &&
                <Fragment>
                  <div id="onboard-main-row">
                    <div className="onboard-main-div">
                      <div>
                        <h3>Basic</h3>
                        <h1 className="green-text">$3,000/yr</h1>
                      </div>
                      <div className="per-month-div">
                        <div className="row">
                          <img src={greenCheck} /><p>AI screening for 1,200 patients per year</p>
                        </div>
                      </div>
                      { this.props.userSubscription && this.props.userSubscription.subPlan === 'year-basic' 
                      ?
                      <div id="current-plan" className="onboard-btn">
                        <p>Current Plan</p>
                      </div>
                      :
                      <StripeCheckout
                        stripeKey = {process.env.REACT_APP_STRIPE_KEY}
                        token = {token => this.onClickStart(token, 'year-basic')}
                        email={ this.props.userData ? this.props.userData.Info.ContactEmail : null }
                      >
                        <div className="onboard-btn">
                          <p>Start</p>
                        </div>
                      </StripeCheckout>
                      }
                    </div>
                    <div className="onboard-main-div">
                      <div>
                        <h3>Standard</h3>
                        <h1 className="green-text">$6,000/yr</h1>
                      </div>
                      <div className="per-month-div">
                        <div className="row">
                          <img src={greenCheck} /><p>AI screening for 2,400 patients per year</p>
                        </div>
                        <div className="row">
                          <img src={greenCheck} /><p>EHR integration</p>
                        </div>
                      </div>
                      { this.props.userSubscription && this.props.userSubscription.subPlan === 'year-standard' 
                      ?
                      <div id="current-plan" className="onboard-btn">
                        <p>Current Plan</p>
                      </div>
                      :
                      <StripeCheckout
                        stripeKey = {process.env.REACT_APP_STRIPE_KEY}
                        token = {token => this.onClickStart(token, 'year-standard')}
                        email={ this.props.userData ? this.props.userData.Info.ContactEmail : null }
                      >
                        <div className="onboard-btn">
                          <p>Start</p>
                        </div>
                      </StripeCheckout>
                      }
                    </div>
                    <div className="onboard-main-div last-third">
                      <div>
                        <h3>Premium</h3>
                        <h1 className="green-text">$12,000/yr</h1>
                      </div>
                      <div className="per-month-div">
                        <div className="row">
                          <img src={greenCheck} /><p>AI screening for 4,800 patients per year</p>
                        </div>
                        <div className="row">
                          <img src={greenCheck} /><p>EHR integration</p>
                        </div>
                      </div>
                      { this.props.userSubscription && this.props.userSubscription.subPlan === 'year-premium' 
                      ?
                      <div id="current-plan" className="onboard-btn">
                        <p>Current Plan</p>
                      </div>
                      :
                      <StripeCheckout
                        stripeKey = {process.env.REACT_APP_STRIPE_KEY}
                        token = {token => this.onClickStart(token, 'year-premium')}
                        email={ this.props.userData ? this.props.userData.Info.ContactEmail : null }
                        style={{ height: '100%', width: '100%' }}
                      >
                        <div className="onboard-btn">
                          <p>Start</p>
                        </div>
                      </StripeCheckout>
                      }
                    </div>
                  </div>
                </Fragment>
              }
              { this.state.perPatient &&
                <div id="per-patient">
                  <div id="onboard-main-row">
                    <div className="onboard-main-div">
                      <div>
                        <h3>Per Patient</h3>
                        <h1 className="green-text">$4/patient</h1>
                      </div>
                      <div className="per-month-div">
                        <div className="row">
                          <img src={greenCheck} /><p>AI Screening for unlimited patients</p>
                        </div>
                      </div>
                      { this.props.userSubscription && this.props.userSubscription.subPlan === 'month-metered' 
                      ?
                      <div id="current-plan" className="onboard-btn">
                        <p>Current Plan</p>
                      </div>
                      :
                      <StripeCheckout
                        stripeKey = {process.env.REACT_APP_STRIPE_KEY}
                        token = {token => this.onClickStart(token, 'month-metered')}
                        email={ this.props.userData ? this.props.userData.Info.ContactEmail : null }
                      >
                        <div className="onboard-btn">
                          <p>Start</p>
                        </div>
                      </StripeCheckout>
                      }
                    </div>
                  </div>
                </div>
              }
            </main>
            <h3 id="have-a-promo-h3">Have a Promo Code?</h3>
              <div id="onboard-back" className="link" onClick={this.goBack}>
                <h3>Back</h3>
              </div>
              {/*

                Used when user was brought to this page after signing up

              <div
                id="onboard-back"
                className="link"
                onClick={() => {
                  this.props.cookies.remove('email', this.state.email, {path: '/'})
                  this.props.cookies.remove('password', this.state.password, {path: '/'})
                  this.props.signOut()
                }}
              >
                <h3>Sign Out</h3>
              </div>
              */}
            <div id="promo-div">
              <div id="promo-code-form">
                <input id="promo-code-input" onChange={event => this.setState({ promoCode: event.target.value })} type="text" placeholder="Enter code here"/>
                <a id="promo-code-submit" onClick={this.submitPromo}>Submit</a>
              </div>
            </div>
          </div>
        </div>
        :
        <Fragment>
          <Loading />
        </Fragment>
      }
      </Fragment>
      { this.props.userMessage && this.props.userMessage.includes('updated') && !this.props.location.state &&
        <Redirect to="/" />
      }
    </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  userData: state.authReducer.userData,
  database: state.firebaseReducer.database,
  isSubscriptionActive: state.authReducer.isSubscriptionActive,
  userSubscription: state.settingsReducer.userSubscription,
  userData: state.authReducer.userData,
  isLoadingDone: state.settingsReducer.isLoadingDone,
  userMessage: state.settingsReducer.userMessage,
  promoMessage: state.authReducer.promoMessage
})

export default withCookies(connect(mapStateToProps, { hideNav, submitPromoCode, handleTokenClick, activateSubscription, clearPromoMessage, signOut })(OnBoard))
