import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Link, Redirect } from 'react-router-dom'
import { onClickSignUp } from '../../actions/signUpAction'
import { clearUserMessage } from '../../actions/clearUserMessageAction'
import ImageUpload from '../../../Patients/components/ImageUpload'
import exclam from '../../../img/Exclamation.svg'

import logo from '../../../img/Dm.svg'
import '../index.scss';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userMessage: null,
      userInfo: {
        email: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
        practiceName: '',
        practiceAddress: '',
        image: null,
        imageName: null
      }
    }
  }

  componentDidMount() {
    this.props.clearUserMessage()
    let mainHeight = window.innerHeight
    this.setState({ mainHeight })
    window.addEventListener('resize', () => {
      mainHeight = window.innerHeight
      this.setState({ mainHeight })
    })
  }

  componentDidUpdate() {
    if (this.state.userMessage) {
      setTimeout(() => {
        this.setState({ userMessage: null })
      }, 3000);
    }
  }

  componentWillReceiveProps = props => {
    this.setState({ signedIn: props.signedIn, userMessage: props.userMessage,
      userInfo: {
        ...this.state.userInfo, image: props.personImageUrl, imageName: props.imageName
      }
    })
    setTimeout(() => {
      this.props.clearUserMessage()
    }, 3000);
  }

  handleChange = event => {
    this.setState({
      ...this.state, userInfo: {
        ...this.state.userInfo,
        [event.target.name]: event.target.value
      }
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    if (!this.state.userMessage) {
      const requiredInputs = [ this.state.userInfo.email, this.state.userInfo.password, this.state.userInfo.passwordConfirmation,
      this.state.userInfo.firstName, this.state.userInfo.lastName, this.state.userInfo.practiceName,
      this.state.userInfo.practiceAddress]

      const areAnyRequiredInputsEmpty = requiredInputs.some(input => input === '')

      if (areAnyRequiredInputsEmpty) {
        this.setState({ userMessage: 'No fields can be blank' })
      }
      else if (this.state.userInfo.password === this.state.userInfo.passwordConfirmation) {
        this.setState({isSignUpClicked: true})
        this.props.onClickSignUp(this.state.userInfo, this.props.database)
      } else {
        this.setState({ userMessage: "Passwords don't match" })
      }
    }
  }

  renderRedirect = () => {
    if (this.props.userMessage && this.props.userMessage.includes('wait')) {
      setTimeout(() => {
        return <Redirect to='/' />
      }, 3000);
    }
  }

  render() {
    return (
      <div id="sign-up-container" style={{ height: `${this.state.mainHeight - 20}px` }}>
        <div id="logo-div">
          <img src={logo} alt="Lazarus" id="logo"/>
        </div>
        <h2 id="sign-in-h3">Sign Up</h2>
        <div id="sign-up-div">
          <p id="no-account-p">Already have an account?</p>
          <Link id="no-margin" to="/">
            <u>Sign In</u>
          </Link>
        </div>
        <form id="sign-up-form" onSubmit={this.handleSubmit}>
          <div>
            <label>Physician First Name</label>
            <br></br>
              <input className="text-input width-65" type="text" name="firstName" onChange={this.handleChange}/>
          </div>
          <div className="signup-input-field">
            <label>Physician Last Name</label>
            <br></br>
              <input className="text-input width-65" type="text" name="lastName" onChange={this.handleChange}/>
          </div>
          <div className="signup-input-field">
            <label>Contact Email</label>
            <br></br>
              <input className="text-input" type="email" name="email" onChange={this.handleChange}/>
          </div>
          <div className="signup-input-field">
            <label>Practice Name</label>
            <br></br>
              <input className="text-input" type="text" name="practiceName" onChange={this.handleChange}/>
          </div>
          <div className="signup-input-field">
            <label>Practice Address</label>
            <br></br>
              <input className="text-input" type="text" name="practiceAddress" onChange={this.handleChange}/>
          </div>
          <div className="signup-input-field">
            <label>Password</label>
            <br></br>
            <input className="text-input" type="password" name="password" onChange={this.handleChange}/>
          </div>
          <div className="signup-input-field">
            <label>Password Confirmation</label>
            <br></br>
            <input className="text-input" type="password" name="passwordConfirmation" onChange={this.handleChange}/>
          </div>
          <div id="sign-up-name-pic-div">
            <div id="sign-up-img-upload">
              <p>Choose Image (optional)</p>
              <ImageUpload isPhysician={true} />
            </div>
          </div>
          <div
            className="btn-div submit-btn"
            onClick={this.handleSubmit}
          >
            {this.state.isSignUpClicked ?
              <svg className="loader-svg" viewBox="25 25 50 50">
                <circle className="loader-circle" cx="50" cy="50" r="20"></circle>
              </svg>
              :
              <p>Sign Up</p>
            }
          </div>
        </form>
        { this.state.userMessage
          && 
          <div className="failure-snack slideInLeft">
            <img src={ exclam } /><p>{this.state.userMessage}</p>
          </div>
        }
        { this.props.userMessage && this.props.userMessage.includes('wait') &&
        <Redirect 
          to={{
            pathname: '/signupsuccess',
            state: { userMessage: 'Please wait whie we review your qualifications' }
          }} /> 
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  signInSuccess: state.authReducer.signInSuccess,
  signedIn: state.authReducer.signedIn,
  userMessage: state.authReducer.userMessage,
  personImageUrl: state.patientsReducer.personImageUrl,
  imageName: state.patientsReducer.imageName
})

export default connect(mapStateToProps, { onClickSignUp, clearUserMessage } )(SignUp)
