import types from './types'

export const fetchBatches = (batchIdList, firestore, oldBatchList = []) => async (dispatch) => {
  if (!batchIdList) {
    dispatch({
      type: types.FETCH_BATCHES,
      payload: {
        batches: [],
        isLoadingBatches: false
      }
    })
  } else {
    let sortedNewBatchList = oldBatchList
    for (let i = 0; i < batchIdList.length; i++) {
      const batchId = batchIdList[i]
      await firestore.collection('PatientTests').doc(batchId).onSnapshot(async (doc) => {
        if (doc.exists) {
          let res = doc.data()
          await firestore.collection('OnlinePatients').doc(res.Patient).onSnapshot((patientDoc) => {
            if (patientDoc.exists) {
              res = {
                ...res,
                PatientInfo: patientDoc.data(),
                BatchId: batchId,
              }
              // const oldBatchList = this.state.batchList.filter((batch, i) => {
              //   return batch.BatchId !== res.BatchId
              // })
              sortedNewBatchList.push(res)
              if (sortedNewBatchList.length === batchIdList.length) {
                sortedNewBatchList = sortedNewBatchList.sort((a, b) => {
                  if (a.Time > b.Time) {
                    return -1;
                  }
                  return 1
                })
                const batches = Object.keys(sortedNewBatchList)
                dispatch({
                  type: types.FETCH_BATCHES,
                  payload: {
                    batches: [...sortedNewBatchList],
                    isLoadingBatches: false
                  }
                })
              }
            }
          })
        }
      })
    }
  }
}