import firebase from 'firebase/app'
import types from './types'

export const handleTokenClick = (database, sourceToken, plan, data) => dispatch => {
  dispatch({
    type: types.LOADING_SUBSCRIPTION,
    payload: {
      isLoadingDone: false,
      userMessage: null
    }
  })
  try {
    let subID = data["subID"]
    let status = data["status"]
    let subPlan = data["subPlan"]
    let cusID = data["cusID"]
    let sourceID = sourceToken['id']

    const name = data.name
    const email = data.email

    if (status === 'Active' || subPlan === 'Promo' || status === 'None') {
      if (cusID === 'None') {

        (async () => {
          let rawResponse = await fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/createSub?name=` + name + `&email=` + email + `&plan=` + plan + `&source=` + sourceID, {
            method: 'GET',
          })
          const res = await rawResponse.json();
          if (res['status'] != 'ERROR') {
            database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Sub').set({
              CusID: res["customer"],
              ID: res["id"]
            })
            dispatch({
              type: types.FETCH_SUBSCRIPTION,
              payload: {
                status: res["status"],
                subPlan: res["plan"]["id"],
                subID: res["id"],
                cusID: res["customer"],
                amount: res["items"]["data"][0]["plan"]["amount"],
                interval: res["items"]["data"][0]["plan"]["interval"],
                start: res["current_period_start"],
                end: res["current_period_end"]
              }
            })
            dispatch({
              type: types.LOADING_SUBSCRIPTION,
              payload: {
                isLoadingDone: true,
                userMessage: 'Your subscription has been updated'
              }
            })
          } else {
            dispatch({
              type: types.LOADING_SUBSCRIPTION,
              payload: {
                isLoadingDone: true,
                userMessage: 'There was an error updating your subscription'
              }
            })
          }
        })()
      } else {
        (async () => {
          let rawResponse = await fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/createSub?cusID=` + cusID + `&plan=` + plan + `&source=` + sourceID, {
            method: 'GET',
          })
          const res = await rawResponse.json();
          if (res['status'] != 'ERROR') {
            database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Sub').set({
              CusID: res["customer"],
              ID: res["id"],
              Updated: Date.now()
            })
            dispatch({
              type: types.FETCH_SUBSCRIPTION,
              payload: {
                status: res["status"],
                subPlan: res["plan"]["id"],
                subID: res["id"],
                cusID: res["customer"],
                amount: res["items"]["data"][0]["plan"]["amount"],
                interval: res["items"]["data"][0]["plan"]["interval"],
                start: res["current_period_start"],
                end: res["current_period_end"]
              }
            })
            dispatch({
              type: types.LOADING_SUBSCRIPTION,
              payload: {
                isLoadingDone: true,
                userMessage: 'Your subscription has been updated'
              }
            })
          } else {
            dispatch({
              type: types.LOADING_SUBSCRIPTION,
              payload: {
                isLoadingDone: true,
                userMessage: 'There was an error updating your subscription'
              }
            })
          }
        })()
      }
    } else {
      if ((subPlan.includes("metered") && !plan.includes("metered")) || (!subPlan.includes("metered") && plan.includes("metered"))) {
        (async () => {
          let rawResponse = await fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/modifySub2?cusID=` + cusID + `&plan=` + plan + `&source=` + sourceID + `&subID=` + subID, {
            method: 'GET',
          })
          const res = await rawResponse.json();
          if (res['status'] != 'ERROR') {
            database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Sub').set({
              CusID: res["customer"],
              ID: res["id"],
              Updated: Date.now()
            })
            dispatch({
              type: types.FETCH_SUBSCRIPTION,
              payload: {
                status: res["status"],
                subPlan: res["plan"]["id"],
                subID: res["id"],
                cusID: res["customer"],
                amount: res["items"]["data"][0]["plan"]["amount"],
                interval: res["items"]["data"][0]["plan"]["interval"],
                start: res["current_period_start"],
                end: res["current_period_end"]
              }
            })
            dispatch({
              type: types.LOADING_SUBSCRIPTION,
              payload: {
                isLoadingDone: true,
                userMessage: 'Your subscription has been updated'
              }
            })
          } else {
            dispatch({
              type: types.LOADING_SUBSCRIPTION,
              payload: {
                isLoadingDone: true,
                userMessage: 'There was an error updating your subscription'
              }
            })
          }
        })()
      } else {
        (async () => {
          let rawResponse = await fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/modifySub?cusID=` + cusID + `&plan=` + plan + `&source=` + sourceID + `&subID=` + subID, {
            method: 'GET',
          })
          const res = await rawResponse.json();
          if (res['status'] != 'ERROR') {
            database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Sub').set({
              CusID: res["customer"],
              ID: res["id"],
              Updated: Date.now()
            })
            dispatch({
              type: types.FETCH_SUBSCRIPTION,
              payload: {
                status: res["status"],
                subPlan: res["plan"]["id"],
                subID: res["id"],
                cusID: res["customer"],
                amount: res["items"]["data"][0]["plan"]["amount"],
                interval: res["items"]["data"][0]["plan"]["interval"],
                start: res["current_period_start"],
                end: res["current_period_end"]
              }
            })
            dispatch({
              type: types.LOADING_SUBSCRIPTION,
              payload: {
                isLoadingDone: true,
                userMessage: 'Your subscription has been updated'
              }
            })
          } else {
            dispatch({
              type: types.LOADING_SUBSCRIPTION,
              payload: {
                isLoadingDone: true,
                userMessage: 'There was an error updating your subscription'
              }
            })
          }
        })()
      }
    }
  } catch {
    dispatch({
      type: types.LOADING_SUBSCRIPTION,
      payload: {
        isLoadingDone: true,
        userMessage: 'There was an error updating your subscription'
      }
    })
  }
}
