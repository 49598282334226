import types from '../actions/types'
import firebase from 'firebase/app';

export const fetchPatientData = (firestore, patientId) => dispatch => {
  let sortingDict = {}
  let currentGender = null
  let detach = firestore.collection("Patients").doc(patientId).onSnapshot(doc => {
    if (doc.exists) {
      const res = doc.data()
      const resObject = {
        birthday: res.Birthday,
        gender: res.Gender,
        testArray: res.Tests
      }
      currentGender = res.Gender
      // let sortingDict = {}
      // let newTests = []
      if (res.Tests.length === 0) {
        dispatch({
          type: types.FETCH_PATIENT_DATA,
          payload: {
            fullList: [],
            patientTests: [],
            tests: [],
            loading: false,
            gender: res.Gender
          }
        })
      }
      resObject.testArray.forEach((test, i) => {
        if (!sortingDict[i]) {
          firestore.collection("Tests").doc(test).get()
            .then(doc => {
              // push object with { id: test, data: doc.data() }
              // newTests.push({ id: test, data: doc.data() })
              sortingDict[i] = { id: test, data: doc.data(), num: i }
            })
            .then(() => {
              let fullList = []
              let newTests = []
              let patientNewTests = []
              for (let index = 0; index < resObject.testArray.length; index++) {
                if (sortingDict[index]) {
                  // console.log(sortingDict[index])
                  // newTests.push(sortingDict[index])
                  if (sortingDict[index].data.Source) {
                    if (sortingDict[index].data.Source === "Patient") {
                      patientNewTests.push(sortingDict[index])
                      fullList.push(sortingDict[index])
                    } else {
                      newTests.push(sortingDict[index])
                      fullList.push(sortingDict[index])
                    }
                  } else {
                    newTests.push(sortingDict[index])
                    fullList.push(sortingDict[index])
                  }
                }
              }
              const loading = res.Tests.length !== (newTests.length + patientNewTests.length)
              dispatch({
                type: types.FETCH_PATIENT_DATA,
                payload: {
                  fullTestList: fullList.reverse(),
                  patientTests: patientNewTests.reverse(),
                  tests: newTests.reverse(),
                  loading: loading,
                  gender: res.Gender
                }
              })
            })
            .catch((error) => console.log(error))
        }
      })

      // for(i = 0; i < length; i++) {
      //   let testNum = i
      //   let inputNum = (length - i - 1)
      //   self.props.firestore.collection("Tests").doc(self.state.testArray[testNum]).get().then(function(doc) {
      //     if (doc.exists) {
      //       let dataToUse = doc.data()
      //       newTests[inputNum] = ({
      //         Ref: self.state.testArray[testNum],
      //         Test: inputNum,
      //         Notes: (dataToUse.Notes) ? dataToUse.Notes : "",
      //         Confidence: (dataToUse.Confidence) ? dataToUse.Confidence : "Confidence Not Found",
      //         DateTaken: (dataToUse.DateTaken) ? dataToUse.DateTaken : "Date Not Found",
      //         Results: (dataToUse.Results) ? dataToUse.Results : "Result Unknown",
      //         ImageRef: dataToUse.ImageRef,
      //         HeatMapRef: (dataToUse.HeatMapRef) ? dataToUse.HeatMapRef : dataToUse.ImageRef,
      //         Reasoning: (dataToUse.Reasoning) ? dataToUse.Reasoning : "Refer to Image Above"
      //       });
      //     }
      //   });
      // }
      // self.setState({
      //   tests: newTests,
      //   loading: false
      // })

      // const loading = res.Tests.length !== newTests.length ? true : false
      // console.log('res tests length', res.Tests.length, 'new tests length', newTests.length)
      // dispatch({
      //   type: types.FETCH_PATIENT_DATA,
      //   payload: {
      //     tests: newTests.reverse(),
      //     loading: loading,
      //     gender: res.Gender
      //   }
      // })
    }
  })
  let detatchFunc = function () {
    detach()
  }

  let modifyFunc = function (index, type, value) {
    if (sortingDict[index]) {
      if (type == "notes") {
        sortingDict[index]["data"]["Notes"] = value
      } else if (type == "flag") {
        sortingDict[index]["data"]["Flag"] = value
      }
      let fullList = []
      let newTests = []
      let patientNewTests = []
      for (let index = 0; index < Object.keys(sortingDict).length; index++) {
        if (sortingDict[index]) {
          if (sortingDict[index].data.Source) {
            if (sortingDict[index].data.Source === "Patient") {
              patientNewTests.push(sortingDict[index])
              fullList.push(sortingDict[index])
            } else {
              newTests.push(sortingDict[index])
              fullList.push(sortingDict[index])
            }
          } else {
            newTests.push(sortingDict[index])
            fullList.push(sortingDict[index])
          }
        }
      }
      const loading = false
      dispatch({
        type: types.FETCH_PATIENT_DATA,
        payload: {
          fullTestList: fullList.reverse(),
          patientTests: patientNewTests.reverse(),
          tests: newTests.reverse(),
          loading: loading,
          gender: currentGender
        }
      })
    }
  }

  dispatch({
    type: types.UPDATE_PATIENT_LISTENER_FUNCTIONS,
    payload: {
      from : "fetchPatients",
      detatch: detatchFunc,
      modify: modifyFunc,
    }
  })
}
