import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Link } from 'react-router-dom'
import { onClickSignIn } from '../../actions/signInAction'
import firebase from 'firebase/app';
import logo from '../../../img/Dm.svg'
import check from '../../../img/Check.svg'
import exclam from '../../../img/Exclamation.svg'
import { clearUserMessage } from '../../actions/clearUserMessageAction'
import { startUpUpdateFunctions } from '../../../Patients/actions/startUpUpdateFunctionsAction'
import { withCookies, Cookies } from 'react-cookie';

import '../index.scss';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      forgotPassword: false,
      signInError: null,
      mobileWarning: 1,
      isSignInClicked: false
    }
  }

  componentDidMount() {
    if (this.props.userMessage && !this.props.userMessage.includes('wait')) {
      this.props.clearUserMessage()
    }
    let mainHeight = window.innerHeight
    this.setState({ mainHeight })
    window.addEventListener('resize', () => {
      mainHeight = window.innerHeight
      this.setState({ mainHeight })
    })
  }

  componentWillReceiveProps(props) {
    this.setState({ signInError: props.userMessage })
  }

  componentDidUpdate() {
    if (this.state.changePasswordMessage) {
      setTimeout(() => {
        this.setState({ changePasswordMessage: null })
      }, 5000);
    }
    if (this.state.signInError) {
      if (this.state.isSignInClicked) {
        this.setState({ isSignInClicked: false })
      }
      setTimeout(() => {
        this.props.clearUserMessage()
      }, 3000);
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ isSignInClicked: true })
    this.props.startUpUpdateFunctions()
    this.props.onClickSignIn(this.state.email, this.state.password, this.props.database, this.props.cookies, this.props.firestore)
    // this.props.cookies.set('email', this.state.email, {path: '/'})
    // this.props.cookies.set('password', this.state.password, {path: '/'})
  }

  onClickForgotPassword = () => {
    this.setState({
      forgotPassword: true
    })
  }

  sendPasswordEmail = () => {

    firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
      // Email sent.
      this.setState({ forgotPassword: false, changePasswordMessage: "An email has been sent to the specified address." })
    }).catch((error) => {
      // An error happened.
      this.setState({ changePasswordMessage: "Unable to send an email to the address specified." })
    });
  }

  render() {
    // Lock scroll for modal
    const overflow = this.state.mobileWarning === 3 ? 'scroll' : 'hidden'
    return (
      <Fragment>
        { this.state.mobileWarning > 0 && this.state.mobileWarning < 3 && <div className="mobile-grey-screen"></div> }
        <div id="sign-in-container" style={{ height: `${this.state.mainHeight - 20}px`, overflow: overflow }}>
          <header id="not-supported">
            <img src={logo} />
            <h2>Please update your browser to use Lazarus CDS</h2>
          </header>
          { this.state.mobileWarning > 0 && this.state.mobileWarning < 3 &&
          <div id="mobile-warning">
            <p>Warning: Lazarus CDS should not be used on mobile devices</p>
            <button
              onClick={() => this.setState({ mobileWarning: ++this.state.mobileWarning }) }
              className={ this.state.mobileWarning === 2 ? 'mobile-warning-btn-red' : 'mobile-warning-btn' }
            >{ this.state.mobileWarning === 2 ? 'Are you sure?' : 'Continue' }
            </button>
          </div>
          }
          <div id="logo-div">
            <img src={logo} alt="Lazarus" id="logo"/>
          </div>
          <h2 id="sign-in-h3">Sign In</h2>
          <div id="sign-up-div">
            <p id="no-account-p">Don't have an account?</p>
            { this.state.forgotPassword
              ?
              <a href="" onClick={() => this.setState({ forgotPassword: false })}>Back</a>
              :
              <Link id="no-margin" to="/signup">
                <u>Sign Up</u>
              </Link>
            }
          </div>
          <form id="sign-in-form" onSubmit={this.handleSubmit}>
            <div>
              <p className="no-margin grey-text">Enter your details below</p>
            </div>
            <div>
              <div>
                <label>Email</label>
              </div>
              <input className="text-input" type="email" name="email" onChange={this.handleChange}/>
            </div>
            <div>
              <div className="space-between">
                <label
                  className= { this.state.forgotPassword ? 'visibility-hidden' : null }
                >Password</label>
                <a
                  className= { this.state.forgotPassword ? 'visibility-hidden' : null }
                  id="forgot-password-a"
                  onClick={this.onClickForgotPassword}
                >Forgot Password?</a>
              </div>
              <input 
                className= { this.state.forgotPassword ? 'visibility-hidden' : 'text-input' }
                type="password"
                name="password"
                onChange={this.handleChange}
              />
            </div>
            <div id="input-button-container">
              { this.state.forgotPassword
              ?
              <a className="forgot-pw-btn" onClick={() => this.sendPasswordEmail()}>Send Email to Change Password</a>
              :
              // <input className="submit-btn" type="submit" value="Sign In"/>
              <div
                className="btn-div submit-btn"
                onClick={this.handleSubmit}
              >
                {this.state.isSignInClicked ?
                  <svg className="loader-svg" viewBox="25 25 50 50">
                    <circle className="loader-circle" cx="50" cy="50" r="20"></circle>
                  </svg>
                  :
                  <p>Sign In</p>
                }
              </div>
              }
            </div>
          </form>
          { this.state.changePasswordMessage &&
            <div
              className={ this.state.changePasswordMessage.includes('sent') ? 'success-snack slideInLeft' : 'failure-snack slideInLeft' }
            >
              <img src={ this.state.changePasswordMessage.includes('sent') ? check : exclam } />
              <p>{ this.state.changePasswordMessage }</p>
            </div>
          }
          { this.props.location.state && this.props.location.state.userMessage &&
            <div
              className={ 'success-snack slideInLeft'}
            >
              <img src={ check } />
              <p>{ this.props.location.state.userMessage }</p>
            </div>
          }
          { this.state.signInError &&
            <div className='failure-snack slideInLeft'>
              <img src={ exclam } />
              <p>{ this.state.signInError }</p>
            </div>
          }
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  signInSuccess: state.authReducer.signInSuccess,
  userMessage: state.authReducer.userMessage,
  batches: state.authReducer.batches
})

export default withCookies(connect(mapStateToProps, { onClickSignIn, clearUserMessage, startUpUpdateFunctions } )(SignIn))
