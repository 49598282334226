import types from './types'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export const fetchUserStats = (database) => dispatch => {

  const newDate = new Date()
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

  database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}`).on("value", snapshot => {
    const dataToRead = snapshot.val() ? snapshot.val() : {
        NewPatients : 0,
        FlaggedTests : 0,
        TestsTaken : 0
    }
    const dataToPush = {
        NewPatients: dataToRead["NewPatients"] ? dataToRead["NewPatients"] : 0,
        FlaggedTests: dataToRead["FlaggedTests"] ? dataToRead["FlaggedTests"] : 0,
        TestsTaken: dataToRead["TestsTaken"] ? dataToRead["TestsTaken"] : 0,
    }

     dispatch({
       type: types.FETCH_USER_STATS,
       payload: dataToPush
     })
  })

}
