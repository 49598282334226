import types from './types'

export const fetchPatients = (patientObj) => dispatch => {
  let patientList = []
  let formattedPatientList = []
  const patientObjKeys = Object.keys(patientObj)
  patientObjKeys.forEach(key => {
    patientList.push({ ...patientObj[key], key: key })
  })
  patientList.sort((a, b) => {
    if (a.LastUpdate > b.LastUpdate) {
      return -1
    }
    if (a.LastUpdate < b.LastUpdate) {
      return 1
    }
    return 0
    // return new Date(a.LastUpdate).now() - new Date(b.LastUpdate).now()
  })
  
  patientList.forEach(patient => {
    formattedPatientList.push({
      id: patient.key,
      id2: patient.ID,
      name: patient.Name,
      lastDate: patient.LastUpdate,
      image: patient.ImageRef,
      birthday: patient.Birthday
    })
  })
  dispatch({
    type: types.FETCH_PATIENTS,
    payload: formattedPatientList
  })
}
