const helpers = {
  isPotentialMelanoma(arrayOfDiseaseObjects) {
    const melanomaArr = arrayOfDiseaseObjects.filter((obj) => {
      return obj.Name === 'Melanoma'
    })
    if (melanomaArr[0] && melanomaArr[0].Confidence > 20) {
      return true
    } else {
      return false
    }
  },
  formatDate(str) {
    if (str) {
      const dashB = str.slice(5, str.length) + '/' + str.slice(0, 4)
      return dashB.replace('-', '/')
    } else {
      return ''
    }
  },
  generateColorByClassification(results, reasoning) {
    if (helpers.isPotentialMelanoma(reasoning) && results !== 'Melanoma') {
      return '#f8e71c'
    } else {
      switch (results) {
        case 'Melanoma': case 'Basal cell carcinoma':
          return '#da3030'
        case 'Actinic keratoses': case 'Vascular lesions':
          return '#f8e71c'
        default:
          return 'rgb(0, 212, 77)'
      }
    }
  },
  determinePathColor(results, confidence, reasoning) {
    const defaultColor = this.generateColorByClassification(results, reasoning)
    if (defaultColor === 'rgb(0, 212, 77)' && confidence < 0.7) {
      return '#f8e71c'
    } else {
      return defaultColor
    }
  },
}

export default helpers
