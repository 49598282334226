import { combineReducers } from 'redux'
import firebaseReducer from './firebaseReducer'
import authReducer from './Auth/reducers/authReducer'
import patientsReducer from './Patients/reducers/patientsReducer'
import settingsReducer from './Settings/reducers/settingsReducer'

const appReducer = combineReducers({
  firebaseReducer: firebaseReducer,
  authReducer: authReducer,
  patientsReducer: patientsReducer,
  settingsReducer: settingsReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = {
      ...state,
      authReducer: undefined,
      patientsReducer: undefined,
      settingsReducer: undefined
    }
  }
  return appReducer(state, action)
}

export default rootReducer
