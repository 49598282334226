import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {fetchPatients} from './actions/fetchPatientsAction'
import {fetchPatientData} from './actions/fetchPatientDataAction'
import {storeIsBatchView} from './actions/storeIsBatchViewAction'
import {toggleLoader} from './actions/toggleLoaderAction'
import {fetchUserStats} from './actions/fetchUserStatsAction'
import {withRouter, Link, Redirect} from 'react-router-dom'
import {hideNav} from '../Auth/actions/hideNavAction'
import {storeUsage} from '../Auth/actions/storeUsageAction'
import helpers from '../helperFunctions.js'
import Switch from '@material-ui/core/Switch'
import Loading from '../RootComponents/Loading'
import './index.scss'
import profilepic from '../img/User.svg'
import firebase from 'firebase/app'
import greenCheck from '../img/GreenCheck.svg'

/**
 * Renders a list of local patients and remote uploads (batches)
 */

class Patients extends Component {
  constructor(props) {
    super(props)

    this.state = {
      testingLink: 'https://patient.lazarusderm.com/?state=',
      // Used to detect new batches added to physician branch on db
      userData: null,
      batchList: [], // Full unfiltered list
      patientList: [],
      filteredBatchList: [], // Filtered from search
      loading: false,
      isUnresolvedSelected: false,
    };

    this.handleRedirect = this.handleRedirect.bind(this)
    this.determineIfSamePatient = this.determineIfSamePatient.bind(this)
    this.copyLinkToClipBoard = this.copyLinkToClipBoard.bind(this)
    this.generateUsageFraction = this.generateUsageFraction.bind(this)
    this.abbreviatedPatientName = this.abbreviatedPatientName.bind(this)
    this.unResolvedBatches = this.unResolvedBatches.bind(this)
    this.batchList = this.batchList.bind(this)
    this.setBatchInfo = this.setBatchInfo.bind(this)
  }

  componentDidMount() {
    const mainHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.setState({mainHeight})
    });
    if (this.props.signedIn) {
      const usageFraction = this.generateUsageFraction()
      this.props.fetchUserStats(this.props.database)
      this.props.hideNav(false) // renders navbar
      this.setState({
        mainHeight,
        userData: this.props.userData,
        usageFraction: usageFraction,
      })
      this.setBatchInfo()
    }
  }

  setBatchInfo(props = this.props) { // Map batches from props to state
    if (!props.isLoadingBatches) {
      const searchedRemotePatients = props.batches.filter(batch => {
        if (!props.search) {
          return true
        } else {
          if ( batch.PatientInfo) {
            return batch.PatientInfo.name.toLowerCase().includes(props.search)
          }
        }
      })
      const searchedLocalPatients = props.patientList.filter(patient => {
        if (!props.search) {
          return true
        }
        return patient.name.toLowerCase().includes(props.search)
      })
      this.setState({
        batchList: props.batches,
        patientList: searchedLocalPatients, 
        filteredBatchList: searchedRemotePatients, 
        loading: false, 
        userData: props.userData 
      })
    } else {
      this.setState({loading: true})
    }
  }

  componentWillReceiveProps(props) {
    this.setBatchInfo(props)
  }

  handleRedirect() {
    if (!this.props.signedIn) {
      return <Redirect to='/' />
    }
  }

  determineIfSamePatient(ev, currentPatient, newPatient) {
    if (currentPatient) {
      if (currentPatient.id == newPatient.id) {
        this.props.toggleLoader(false)
      } else {
        this.props.detatch()
        this.props.toggleLoader(true)
        this.props.fetchPatientData(this.props.firestore, newPatient.id2)
      }  
    } else {
      this.props.fetchPatientData(this.props.firestore, newPatient.id2)
    }
  }

  copyLinkToClipBoard() {
    const self = this
    const copyText = this.state.testingLink + firebase.auth().currentUser.uid
    navigator.permissions.query({name: "clipboard-write"}).then(result => {
      if (result.state == "granted" || result.state == "prompt") {
        /* write to the clipboard now */
        navigator.clipboard.writeText(copyText).then(() => {
          /* clipboard successfully set */
          self.setState({isLinkCopied: true})
        }, function() {
          /* clipboard write failed */
          console.log('clipboard write failed')
        })
      }
    })
  }

  generateUsageFraction() {
    if (this.props.subscriptionData && this.props.subscriptionData.subPlan !== 'error') {
      let patientsUsed
      const today = new Date()
      const year = today.getFullYear()
      if (this.props.subscriptionData.subPlan.includes('year')) {
        patientsUsed = () => {
          if (this.props.userData.PatientCount && this.props.userData.PatientCount[year]) { // Year property is created after first test is added
            return this.props.userData.PatientCount[year].Patients.length
          }
          this.props.storeUsage(0)
          return 0
        }
        switch (this.props.subscriptionData.subPlan) {
          case 'year-basic':
            this.props.storeUsage([patientsUsed(), 1200])
            return `${patientsUsed()}/1,200`
          case 'year-standard':
            this.props.storeUsage([patientsUsed(), 2400])
            return `${patientsUsed()}/2,400`
          case 'year-premium':
            this.props.storeUsage([patientsUsed(), 4800])
            return `${patientsUsed()}/4,800`
          default:
            return 'N/A'
        }
      } else {
        const month = today.getMonth() + 1
        patientsUsed = () => {
          if (this.props.userData.PatientCount && this.props.userData.PatientCount[year] && this.props.userData.PatientCount[year][month]) {
            return this.props.userData.PatientCount[year][month].Patients.length
          }
          this.props.storeUsage(0)
          return 0
        }
        switch (this.props.subscriptionData.subPlan) {
          case 'month-basic': case 'Promo':
            this.props.storeUsage([patientsUsed(), 100])
            return `${patientsUsed()}/100`
          case 'month-standard':
            this.props.storeUsage([patientsUsed(), 200])
            return `${patientsUsed()}/200`
          case 'month-premium':
            this.props.storeUsage([patientsUsed(), 400])
            return `${patientsUsed()}/400`
          case 'month-metered':
            this.props.storeUsage([0, 1])
            return `${patientsUsed()}`
        }
      }
    } else {
      return 'N/A'
    }
  }

  abbreviatedPatientName(name) {
    if (name && name.length > 20) {
      return name.slice(0, 20) + '...'
    } else {
      return name
    }
  }

  unResolvedBatches() {
    return this.state.filteredBatchList.filter((batch) => !batch.HasBeenResolved)
  }

  batchList() { // filtered based on the unresolved switch
    return this.state.isUnresolvedSelected ? this.unResolvedBatches() : this.state.filteredBatchList
  }

  render() {
    if (this.props.userData) {
      const {patientList} = this.state

      const batchRender = (
        <div id="batch-render">
          {(!this.props.userData.OnlineTests || this.props.userData.OnlineTests.Count === 0) &&
          <div className="testing-link-container">
            <div className="flex space-between">
              <span>You have no remote tests. Patients can send you tests through this link.</span>
              <div
                className="m-proc"
              >
                <span><strong>M-PROC</strong></span>
              </div>
            </div>
            <a
              id="link"
              href={this.state.testingLink + firebase.auth().currentUser.uid}
              target="_blank"
              rel= "noopener noreferrer"
            >
              {this.state.testingLink + firebase.auth().currentUser.uid}
            </a>
            <div className="flex justify-flex-end">
              <div
                id="copy-link-btn"
                className={this.state.isLinkCopied ? "link-copied-btn" : "copy-link-btn"}
                onClick={this.copyLinkToClipBoard}
                style={{height: '3em'}}
              >
                {this.state.isLinkCopied ?
                  <span className="copied-span">Link Copied<img src={greenCheck} alt="check" /></span> :
                  <p>Copy Link</p>
                }
              </div>
            </div>
          </div>
          }
          {this.state.batchList.length > 0 && this.batchList().length === 0 &&
          <h3 className="text-align-center">You have no unviewed mobile uploads</h3>
          }
          <div className="list-div" style={{paddingBottom: '300px'}}>
          {this.state.batchList.length > 0 &&
          this.batchList().map((batch, batchIndex) => {
            return (
              <Link
                className="link" 
                to={{
                  pathname: `/batch/?id=${batch.BatchId}`
                }}
                key={'batch-' + batchIndex}
              >
                {/*<div className="batch-div">
                  <p className="background-white patient-name-p">{abbreviatedPatientName(batch.PatientInfo.name)}</p>
                  <div className="patient-flex-center">
                    <div className="background-white align-center">
                      <p className="background-white last-updated-p">Birthday:</p>
                      { <p className="no-margin background-white">
                          { batch.PatientInfo.birthday && helpers.formatDate(batch.PatientInfo.birthday) }
                        </p>
                      }
                    </div>
                  </div>
                  <div className="background-white align-center align-flex-end">
                    { batch.Time &&
                      <div className="align-center update-div">
                        <p className="background-white last-updated-p">Last Updated:</p>
                        <p className="no-margin background-white">
                          { helpers.formatDate(batch.Time.slice(0, batch.Time.length - 9)) }
                        </p>
                      </div>
                    }
                  </div>
                  </div>*/}
                <div className={batch.HasBeenResolved ? "patient-div grey-filter" : "patient-div"}>
                  <div className="patient-img-div">
                    <img className="patient-img" src={profilepic} />
                  </div>
                  <p className="background-white patient-name-p">{this.abbreviatedPatientName(batch.PatientInfo.name)}</p>
                  <div className="patient-flex-center">
                    <div className="background-white align-center">
                      <p className="background-white last-updated-p">Birthday:</p>
                      { <p className="no-margin background-white">
                        { batch.PatientInfo.birthday && helpers.formatDate(batch.PatientInfo.birthday) }
                        </p>
                      }
                    </div>
                  </div>
                  <div className="background-white align-center align-flex-end">
                    { batch.Time &&
                      <div className="align-center update-div">
                        <p className="background-white last-updated-p">Last Updated:</p>
                        <p className="no-margin background-white">
                          { helpers.formatDate(batch.Time.slice(0, batch.Time.length - 9)) }
                        </p>
                      </div>
                    }
                  </div>
                </div>
              </Link>
            )
          })
          }
          </div>
        </div>
      )
      return (
        <Fragment>
          { this.state.loading && <Loading /> }
          <div id="signed-in-home-page" style={{ height: `${this.state.mainHeight - 110}px` }}>
            {this.handleRedirect()}
            <header id="patientList-header">
              <div className="user-img-div">
                <img className="user-img" src={this.props.userData && this.props.userData.Info.ImageRef !== "none" ? this.props.userData.Info.ImageRef : profilepic } />
              </div>
              <div className="align-vertical">
                <h3 className="margin-bottom-9">Welcome!</h3>
                <h3>{ this.props.userData && this.props.userData.Info.Name }</h3>
              </div>
              <div className="practice-div">
                <div className="align-vertical">
                  <h3 className="grey-text margin-bottom-9">Practice:</h3>
                  <h3>{ this.props.userData && this.props.userData.Info.Practice }</h3>
                </div>
              </div>
              <div className="usage-div">
                <div className="align-vertical">
                  <h3 className="grey-text margin-bottom-9">Usage:</h3>
                  <div>
                    <span>{`${this.state.usageFraction} `}</span><span>{` Patients`}</span>
                  </div>
                </div>
              </div>
            </header>
            <div className="list-content" style={{overflow: 'visible'}}>
              <aside id="patients-aside">
                {/*<div className='aside-div'>
                  <p>New Patients</p>
                  <h2>{this.props.userStats ? this.props.userStats.NewPatients : 'n/a'}</h2>
                </div>
                <div className='aside-div'>
                  <p>Tests Today</p>
                  <h2>{this.props.userStats ? this.props.userStats.TestsTaken : 'n/a'}</h2>
                </div>
                */}
                <div
                  className={ this.props.isBatchView ? 'aside-div pointer' : 'aside-div selected-div pointer' }
                  onClick={() => {
                    this.props.storeIsBatchView(false)
                    this.setState({ isUnresolvedSelected: false })
                  }}
                >
                  <p>Local Testing</p>
                  <h2>{ this.props.patientList ? this.props.patientList.length : 'n/a' }</h2>
                </div>

                {/* Batch List */}

                <div
                  className={ this.props.isBatchView ? 'aside-div selected-div pointer' : 'aside-div pointer' }
                  onClick={() => {
                    this.props.storeIsBatchView(true)
                  }}
                >
                  <p>Remote Testing</p>
                  <h2>{this.state.batchList.length}</h2>
                </div>
                {this.props.isBatchView &&
                <div
                  className={
                    this.state.isUnresolvedSelected ?
                    "filter-div selected-div" :
                    "filter-div"
                  }
                >
                  <span>Unresolved</span>
                  <div>
                    <span>{this.unResolvedBatches.length}</span>
                    <Switch
                      color={'default'}
                      onChange={() => this.setState({ isUnresolvedSelected: !this.state.isUnresolvedSelected })}
                    />
                  </div>
                </div>
                }
                {this.props.isBatchView &&
                  <div
                    className="btn-div2"
                    onClick={() => {
                      this.setState({})
                    }}
                  >
                    <p>Send Test Request</p>
                  </div>
                }
              </aside>
              <div className="list-div">
                { this.props.patientList.length === 0 && !this.state.loading && !this.props.isBatchView
                  && <h3 className="center-text">Click "Add Patient" to get started</h3>
                }
                { this.props.patientList.length !== 0
                  && patientList.length === 0 /* state patient list */
                  && !this.props.isBatchView
                  && <h3 className="center-text">Patient Not Found</h3>
                }
                { this.state.batchList.length !== 0
                  && this.state.filteredBatchList.length === 0 /* state patient list */
                  && this.props.isBatchView
                  && <h3 className="center-text">Patient Not Found</h3>
                }
                { patientList && !this.props.isBatchView &&
                  patientList.map((patient, i) =>
                    <Link
                      className="link"
                      key={'patient-' + i}
                      to={{
                        pathname: `/patient/?id2=${patient.id2}&id=${patient.id}`,
                        query: {
                          id: patient.id2,
                          id2: patient.id
                        }
                      }}
                    >
                    <div className={ "patient-div" } onClick={(event) => this.determineIfSamePatient(event, this.props.currentPatient, patient)}>
                      <div className="patient-img-div">
                        <img className="patient-img" src={patient.image ? patient.image : profilepic} />
                      </div>
                      <p className="background-white patient-name-p">{this.abbreviatedPatientName(patient.name)}</p>
                      <div className="patient-flex-center">
                        <div className="background-white align-center">
                          <p className="background-white last-updated-p">Birthday:</p>
                          { <p className="no-margin background-white">
                              { patient.birthday && helpers.formatDate(patient.birthday) }
                            </p>
                          }
                        </div>
                      </div>
                      <div className="background-white align-center align-flex-end">
                        { patient.lastDate &&
                          <div className="align-center update-div">
                            <p className="background-white last-updated-p">Last Updated:</p>
                            <p className="no-margin background-white">
                              { patient.lastDate
                                && helpers.formatDate(patient.lastDate.slice(0, patient.lastDate.length - 9))
                              }
                            </p>
                          </div>
                        }
                      </div>
                    </div>
                    </Link>
                  )
                }
                {this.props.isBatchView &&
                  batchRender
                }
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  signedIn: state.authReducer.signedIn,
  userStats: state.patientsReducer.userStats,
  patientList: state.patientsReducer.patientList,
  search: state.patientsReducer.search,
  subscriptionData: state.authReducer.subscriptionData,
  userData: state.authReducer.userData,
  currentPatient: state.patientsReducer.patientData,
  detatch: state.patientsReducer.detatch,
  isBatchView: state.patientsReducer.isBatchView,
  batches: state.patientsReducer.batches,
  isLoadingBatches: state.patientsReducer.isLoadingBatches
})

export default withRouter(connect(mapStateToProps, {fetchPatients , fetchPatientData , storeIsBatchView, fetchUserStats, hideNav, storeUsage, toggleLoader})(Patients))
