import React, {Component, Fragment} from 'react'
import logo from '../../../img/Dm.svg'
import '../../index.scss';
import {Link, Redirect} from 'react-router-dom'
import {signOut} from '../../../Auth/actions/signOutAction'
import {fetchSubscriptionData} from '../../../Settings/actions/fetchSubscriptionDataAction'
import {connect} from 'react-redux'
import {searchPatients} from '../../actions/searchPatientsAction'
import settings from '../../../img/Gear.svg'
import ProfilePopup from '../../../RootComponents/profilePopup2'
import {withCookies} from 'react-cookie';

class Navbar extends Component {
  /*
    Renders alongside other components when !this.props.isNavHidden
    Includes search bar, add patient, settings, and breadcrumbs
  */
  constructor(props) {
    super(props);

    this.state = {
      signedOut: false,
      searching: false,
      isHovering: false,
      hasBeenHovered: false,
      subFullModal: false,
    }

    this.onClickProfile = this.onClickProfile.bind(this)
    this.onChangeSearchBar = this.onChangeSearchBar.bind(this)
    this.onHover = this.onHover.bind(this)
    this.formatDate = this.formatDate.bind(this)
  }

  componentDidMount() {
    this.props.fetchSubscriptionData(this.props.database)
  }

  componentWillReceiveProps(props) {
    this.setState({searching: false})
    if (window.location.pathname !== this.state.pathName) {
      this.setState({isHovering: false, pathName: window.location.pathname})
    }
  }

  onClickProfile() {
    this.props.cookies.remove('email', this.state.email)
    this.props.cookies.remove('password', this.state.password)
    // console.log('signing out')
    this.props.signOut()
    this.setState({signedOut: true})
  }

  onChangeSearchBar(event) {
    const searchValue = event.target.value.toLowerCase()
    // const searchedPatients = this.props.patientList.filter(patient => {
    //   return patient.name.toLowerCase().includes(searchValue)
    // })
    this.props.searchPatients(searchValue)
    this.setState({searching: true})
  }

  onHover(bool) {
    this.setState({isHovering: bool, hasBeenHovered: true})
  }

  formatDate(str) {
    return str.replace(new RegExp('/', 'g'), '-');
  }

  render() {
    return (
      <Fragment>
        {this.state.subFullModal &&
        <div
          id="grey-screen"
          onClick={() => this.setState({subFullModal: false})}
        >
        </div>
        }
        {!this.props.isNavHidden &&
        <nav id="navbar">
          <div id="signedin-logo-div">
            <Link id="logo-link" to="/">
              <img id="logo" src={logo} alt="Lazarus" />
            </Link>
          </div>
          <div className="content-div">
            <div id="search-bar-div">
              <input
                id="search-bar"
                placeholder="Search patients"
                type="text"
                name="search"
                onChange={this.onChangeSearchBar}
              />
            </div>
            <div id="signed-in-button-div">
              {this.props.isSubscriptionFull ?
                <div id="add-patient-link">
                  <button
                    className="btn"
                    onClick={() => this.setState({subFullModal: true})}
                  >+ Add Patient
                  </button>
                </div> :
                <Link
                  id="add-patient-link"
                  to={this.props.isSubscriptionActive ? '/add' : '/plan'}
                >
                  <button className="btn">+ Add Patient</button>
                </Link>
              }
              {this.state.searching && <Redirect to='/' />}
              {this.state.signedOut && <Redirect to='/' />}
              <ProfilePopup
                onHover={this.onHover}
                isHovering={this.state.isHovering}
                hasBeenHovered={this.state.hasBeenHovered}
                // cookies={this.props.cookies}
              />
            </div>
          </div>
          <div
            id="settings-btn"
            // onMouseEnter={() => this.onHover(true)}
            // onMouseLeave={() => this.onHover(false)}
            onClick={() => this.onHover(!this.state.isHovering)}
            className={this.state.isHovering ? 'spin' : 'spin2'}
          >
            <img
              src={settings}
              className="profile-img"
            />
          </div>
          <span id="nav-links">
            {window.location.href.includes('batch') ?
              <Link to="/">
                <u>Patients</u>
              </Link> :
              <Fragment>
                <Link to="/">
                  <u>Patients</u>
                </Link>
                {window.location.pathname === '/add' ||
                <Fragment>
                  {this.props.patientData &&
                  this.props.patientData.id &&
                  this.props.patientData.id2 &&
                    <span>
                      <a>{' / '}</a>
                      <Link
                        to={`/patient/?id2=${this.props.patientData.id2}&id=${this.props.patientData.id}`}
                      >
                        <u>
                          {this.props.patientData ?
                          this.props.patientData.patient.name : null }
                        </u>
                      </Link>
                    </span>
                  }
                  {this.props.testData &&
                    <span>
                      <a>{' / '}</a>
                      <Link to={`/test/?id=${this.props.testData.id}`}>
                        <u>{this.formatDate(this.props.testData.dateTaken)}</u>
                      </Link>
                    </span>
                  }
                </Fragment>
                }
              </Fragment>
            }
          </span>
        </nav>
        }
        {this.state.subFullModal &&
        <div className="subFullModal fadeInDown">
          <p>You reached the limit of your subscription</p>
          <div className="space-evenly">
            <button
              className="grey-btn"
              onClick={() => this.setState({subFullModal: false})}
            >Close
            </button>
            <Link className="link" to="/plan">
              <button
                onClick={() => this.setState({subFullModal: false})}
                className="blue-btn"
              >Upgrade
              </button>
            </Link>
          </div>
        </div>
        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  signedIn: state.authReducer.signedIn,
  userData: state.authReducer.userData,
  isNavHidden: state.authReducer.hideNav,
  userSubscription: state.settingsReducer.userSubscription,
  patientList: state.patientsReducer.patientList,
  patientData: state.patientsReducer.patientData,
  testData: state.patientsReducer.testData,
  isSubscriptionActive: state.authReducer.isSubscriptionActive,
  isSubscriptionFull: state.authReducer.isSubscriptionFull,
  cookies: ownProps.cookies,
})

export default withCookies(connect(mapStateToProps, {signOut, searchPatients, fetchSubscriptionData})(Navbar))
