import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Link } from 'react-router-dom'
import './index.scss';
import logo from '../img/Dm.svg'
import check from '../img/Check.svg'
import carrot from '../img/Carrot.svg'
import exclam from '../img/Exclamation.svg'
import defaultImg from '../img/User.svg'
import firebase from 'firebase/app';
import 'firebase/auth';
import { hideNav } from '../Auth/actions/hideNavAction'
import { changePayment } from './actions/changePaymentAction'
import { cancelSubscription } from './actions/cancelSubscriptionAction'
import StripeCheckout from 'react-stripe-checkout'
import Loading from '../RootComponents/Loading'
import ImageUpload from '../Patients/components/ImageUpload'
import greenCheck from '../img/GreenCheck.svg'
import 'firebase/auth'
import { signOut } from '../Auth/actions/signOutAction'
import { withCookies, Cookies } from 'react-cookie';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testingLink: 'https://patient.lazarusderm.com/?state=',
      openDrawers: [],
      animateDrawers: [],
      date: null,
      billing: false,
      password: false,
      contactUs: false,
      receivePayment: true,
      contactUsText: '',
      settingsMessage: '',
      newPassword: '',
      oldPassword: '',
      oldPassword2: '',
      isLoadingDone: true,
      pushKey: this.props.database.ref('Dermatology/Feedback').push().key,

      payoutConnected: false,
      physicianPayments: {
        2020: {
          3: {
            16: 50,
            23: 150,
          },
          4: {
            2: 64,
            3: 50
          },
          5: {
            15: 50
          }
        },
        2019: {
          12: {
            30: 64
          }
        }
      }
    }

    this.goBack = this.goBack.bind(this)
  }

  copyLinkToClipBoard = () => {
    const self = this
    const copyText = this.state.testingLink + firebase.auth().currentUser.uid
    navigator.permissions.query({name: "clipboard-write"}).then(result => {
      if (result.state == "granted" || result.state == "prompt") {
        /* write to the clipboard now */
        navigator.clipboard.writeText(copyText).then(() => {
          /* clipboard successfully set */
          self.setState({isLinkCopied: true})
        }, function() {
          /* clipboard write failed */
          console.log('clipboard write failed')
        })
      }
    })
  }

  componentDidMount() {
    this.checkPayout(this.props)
    this.props.hideNav(true)
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1; //January is 0!

    const yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    today = mm + '/' + dd + '/' + yyyy
    this.setState({ date: today })
  }

  componentWillReceiveProps(props) {
    this.setState({ isLoadingDone: props.isLoadingDone, userMessage: props.userMessage })
    this.checkPayout(props)
  }

  componentDidUpdate() {
    if (this.state.settingsMessage || this.state.contactUsMessage) {
      setTimeout(() => {
        this.setState({ settingsMessage: null, contactUsMessage: null })
      }, 3000);
    }
  }

  checkPayout(props) {
    if (props.userData && props.userData.Payout) {
      this.setState({
        payoutConnected: true
      })
    }
  }

  sendPasswordEmail = () => {
    firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
      // Email sent.
    }).catch((error) => {
      // An error happened.
    });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  changePassword = (event) => {
    event.preventDefault()
    const user = firebase.auth().currentUser
    const cred = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      this.state.oldPassword
    )
    let self = this

    if (this.state.newPassword.length === 0
      || this.state.newPassword2.length === 0
      || this.state.oldPassword.length === 0) {
      this.setState({ settingsMessage: "One or more fields are empty" })
    } else if (this.state.newPassword !== this.state.newPassword2) {
      this.setState({ settingsMessage: "Passwords don't match" })
    } else if (this.state.newPassword.length < 8) {
      this.setState({ settingsMessage: "Password must be at least 8 characters long" })
    } else {
      user.reauthenticateWithCredential(cred)
        .then(() => {
          user.updatePassword(self.state.newPassword)
            .then(() => {
              self.setState({ settingsMessage: "Your password has been updated" })
            }).catch(() => {
              self.setState({ settingsMessage: "There was an error changing your password" })
            })
        }).catch(() => {
          self.setState({ settingsMessage: "You have entered the incorrect current password" })
        })
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  signOut = () => {
    this.props.cookies.remove('email', this.state.email)
    this.props.cookies.remove('password', this.state.password)
    // console.log('signing out')
    this.props.signOut()
  }

  onClickChangePayment = token => {
    this.props.changePayment(token, this.props.userSubscription)
  }

  onClickSendAnon = () => {
    if (!this.state.contactUsMessage) {
      if (this.state.contactUsText.length > 0) {
        this.props.database.ref('Dermatology/Feedback/' + this.state.pushKey).update({
          message: this.state.contactUsText
        }).then(() => this.setState({ contactUsMessage: 'Thank you for your feedback. We will contact you soon.' })
        ).catch(() => this.setState({ contactUsMessage: 'There was an unexpected error. Try again later.' }))
      } else {
        this.setState({ contactUsMessage: "Looks like you haven't written anything. Please try again." })
      }
    }
  }

  onClickSendAssist = () => {
    if (!this.state.contactUsMessage) {
      if (this.state.contactUsText.length > 0) {
        this.props.database.ref('Dermatology/Support/' + this.state.pushKey).update({
          message: this.state.contactUsText,
          from: firebase.auth().currentUser.uid,
          email: firebase.auth().currentUser.email,
          date: new Date().getTime()
        }).then(() => this.setState({ contactUsMessage: 'Thank you for your feedback. We will contact you soon.' })
        ).catch(() => this.setState({ contactUsMessage: 'There was an unexpected error. Try again later.' }))
      } else {
        this.setState({ contactUsMessage: "Looks like you haven't written anything. Please try again." })
      }
    }
  }

  formatDate = dateString => {
    if (!dateString) {
      return 'N/A'
    }
    const dateObj = new Date(dateString * 1000)
    return `${(dateObj.getMonth() + 1).toString()}/${(dateObj.getDay() + 1).toString()}/${dateObj.getFullYear().toString()}`
  }

  formatAmountDue = amount => {
    if (!amount) {
      return '$0'
    }
    const dollarAmountArr = (amount / 100).toString().split('')
    if (dollarAmountArr.length > 3) {
      dollarAmountArr.splice((dollarAmountArr.length - 3), 0, ',')
    }
    const dollarAmount = dollarAmountArr.join('')
    return `$${dollarAmount}`
  }

  currentPlan = userSub => {
    switch (userSub.subPlan.slice(0, 5)) {
      case "month":
        return "Monthly" + ' ' + userSub.subPlan.slice(6, 7).toUpperCase() + userSub.subPlan.slice(7, userSub.subPlan.length)
      case "year-":
        return "Yearly" + ' ' + userSub.subPlan.slice(5, 6).toUpperCase() + userSub.subPlan.slice(6, userSub.subPlan.length)
      default:
        return 'Promo'
    }
  }

  onClickAnishsButton = () => {
    window.open("https://connect.stripe.com/express/oauth/authorize?client_id=ca_H4BrYFrvrK3XjmNprboAZxY1GJUwf4nQ&state=" + firebase.auth().currentUser.uid + " &suggested_capabilities[]=transfers")
  }

  handleDrawerClass = (i, j) => {
    const drawerString = i.toString() + '-' + j.toString()
    // const drawerInt = parseInt(drawerString)
    if (!this.state.animateDrawers.includes(drawerString)) {
      return ''
    }
    else if (this.state.openDrawers.includes(drawerString)) {
      return 'unrotate-180'
    } else {
      return 'rotate-180'
    }
  }

  render() {
    if (this.props.userData) {
      let mainHeight = window.innerHeight - 10
      window.addEventListener('resize', () => {
        mainHeight = window.innerHeight - 10
      })
      const paymentYears = Object.keys(this.state.physicianPayments).reverse()
      const months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      }
      return (
        <Fragment>
          {this.state.isLoadingDone ?
            <div id="settings-page" style={{ height: `${mainHeight}px` }}>
              <div id="settings-content">
                <div id="settings-logo-div">
                  <img className="settings-logo" src={logo} alt="Lazarus Derm" />
                  <div>
                    <h1>Dermatology</h1>
                    <p className="grey-text">v1.0</p>
                  </div>
                </div>
                <div id="settings-doctor-div">
                  <div>
                    <h2>{this.props.userData && this.props.userData.Info.Name}</h2>
                    <p className="grey-text">{this.props.userData && this.props.userData.Info.ContactEmail}</p>
                  </div>
                  <div>
                    <ImageUpload isPhysician={true} physicianPhoto={this.props.userData && this.props.userData.Info.ImageRef !== "none" ? this.props.userData.Info.ImageRef : defaultImg} />
                    <p className="size-10 center-text margin-0">Change photo</p>
                  </div>
                </div>
                <div id="under-logo-div">
                </div>
                <div id="under-doctor-div">
                </div>
                <div id="settings-date-div">
                  <div>
                    <p>Date</p>
                    <h3>{this.state.date}</h3>
                  </div>
                </div>

                {/*
              <p
                id="settings-message"
                className={
                  this.state.settingsMessage === "Your password has been updated" ||
                  this.state.contactUsMessage === 'Thank you for your feedback. We will contact you soon.' ?
                  'green-text' : 'red-text'
                }
              >{ this.state.settingsMessage || this.state.contactUsMessage }
              </p>
              */}

                {this.state.settingsMessage &&
                  <div className={
                    this.state.settingsMessage === 'Your password has been updated' ?
                      'success-snack slideInLeft' : 'failure-snack slideInLeft'
                  }>
                    <img src={this.state.settingsMessage === 'Your password has been updated' ? check : exclam} />
                    <p>{this.state.settingsMessage}</p>
                  </div>
                }

                {this.state.contactUsMessage &&
                  <div className={
                    this.state.contactUsMessage === 'Thank you for your feedback. We will contact you soon.' ?
                      'success-snack slideInLeft' : 'failure-snack slideInLeft'
                  }>
                    <img src={this.state.contactUsMessage === 'Thank you for your feedback. We will contact you soon.' ? check : exclam} />
                    <p>{this.state.contactUsMessage}</p>
                  </div>
                }

                <aside id="settings-aside">
                  <div
                    className={
                      this.state.receivePayment
                        ? 'settings-aside-div selected-div'
                        : 'settings-aside-div'
                    }
                    onClick={() => this.setState({ billing: false, password: false, contactUs: false, receivePayment: true })}
                  >
                    <h3>Remote Testing</h3>
                  </div>
                  <div
                    className={
                      this.state.billing
                        ? 'settings-aside-div selected-div'
                        : 'settings-aside-div'
                    }
                    onClick={() => this.setState({ receivePayment: false, billing: true, password: false, contactUs: false })}
                  >
                    <h3>Billing</h3>
                  </div>
                  <div
                    className={
                      this.state.password
                        ? 'settings-aside-div selected-div'
                        : 'settings-aside-div'
                    }
                    onClick={() => this.setState({ receivePayment: false, billing: false, password: true, contactUs: false })}
                  >
                    <h3>Password</h3>
                  </div>
                  <div
                    className={
                      this.state.contactUs
                        ? 'settings-aside-div selected-div'
                        : 'settings-aside-div'
                    }
                    onClick={() => this.setState({ receivePayment: false, billing: false, password: false, contactUs: true })}
                  >
                    <h3>Contact Us</h3>
                  </div>
                </aside>
                <main id="settings-main">
                  {this.state.billing &&
                    <Fragment>
                      <div id="settings-main-row1">
                        <div className="third">
                          <h3>Current Plan</h3>
                          <h2 className="green-text">{this.currentPlan(this.props.userSubscription)}</h2>
                        </div>
                        <div className="third">
                          <h3>Amount Due</h3>
                          <h2 className="green-text">{this.formatAmountDue(this.props.userSubscription.amount)}</h2>
                        </div>
                        <div className="third last-third">
                          <h3>Due Date</h3>
                          <h2>{this.formatDate(this.props.userSubscription.end)}</h2>
                        </div>
                      </div>
                      <div id="settings-main-row2">
                        <Link className=" third link" to={{
                          pathname: "/plan",
                          state: {
                            showBackButton: true
                          }
                        }}
                        >
                          <div>
                            <p>Change Plan</p>
                          </div>
                        </Link>
                        <div className="third">
                          <div style={{ cursor: 'pointer' }}>
                            <StripeCheckout
                              stripeKey={process.env.REACT_APP_STRIPE_KEY}
                              token={token => this.onClickChangePayment(token)}
                              email={this.props.userData ? this.props.userData.Info.ContactEmail : null}
                            >
                              <p>
                                Change Payment
                          </p>
                            </StripeCheckout>
                          </div>
                        </div>
                        <div className="third last-third" onClick={() => this.props.cancelSubscription(this.props.userSubscription)}>
                          <div>
                            <p className="red-text">Cancel Subscription</p>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  }
                  {this.state.password &&
                    <Fragment>
                      <form id="change-password-form">
                        <div>
                          <label>Old Password</label>
                          <br></br>
                          <input className="text-input" type="password" name="oldPassword" onChange={this.handleChange} />
                        </div>
                        <div>
                          <label>New Password</label>
                          <br></br>
                          <input className="text-input" type="password" name="newPassword" onChange={this.handleChange} />
                        </div>
                        <div>
                          <label>Password Confirmation</label>
                          <br></br>
                          <input className="text-input" type="password" name="newPassword2" onChange={this.handleChange} />
                        </div>
                        <div>
                          <button className="submit-btn" onClick={event => this.changePassword(event)}>Submit</button>
                        </div>
                      </form>
                    </Fragment>
                  }
                  {this.state.contactUs &&
                    <Fragment>
                      <div className="flex-center">
                        <div id="contact-us-container">
                          <textarea id="contact-us-text-area" onChange={event => this.setState({ contactUsText: event.target.value })} />
                          <div className="flex-between">
                            <button className="btn" onClick={this.onClickSendAssist}>Send for Assistance</button>
                            <button className="btn" onClick={this.onClickSendAnon}>Send Anonymously</button>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  }
                  {this.state.receivePayment &&
                    <Fragment>
                      {(this.state.payoutConnected)
                        ? (
                          <div className="testing-link-page">
                            <div className="testing-link-container">
                              <div className="flex space-between">
                                <span>Testing Link</span>
                                <div
                                  className="m-proc"
                                >
                                  <span><strong>M-PROC</strong></span>
                                </div>
                              </div>
                              <a
                                id="link"
                                href={this.state.testingLink + firebase.auth().currentUser.uid}
                                target="_blank"
                                rel= "noopener noreferrer"
                              >
                                {this.state.testingLink + firebase.auth().currentUser.uid}
                              </a>
                              <div className="flex justify-flex-end">
                                <div
                                  id="copy-link-btn"
                                  className={this.state.isLinkCopied ? "link-copied-btn" : "copy-link-btn"}
                                  onClick={this.copyLinkToClipBoard}
                                  style={{height: '3em'}}
                                >
                                  {this.state.isLinkCopied ?
                                    <span className="copied-span">Link Copied<img src={greenCheck} alt="check" /></span> :
                                    <p>Copy Link</p>
                                  }
                                </div>
                              </div>
                            </div>
                            {/*
                            <div className="payment-history-container">
                              <div className="black-border">
                                <p>Next Payment</p>
                                <p><strong>xx/xx/xxxx</strong></p>
                              </div>
                              <Fragment>
                                {paymentYears.map((year, i) => {
                                  const yearObj = this.state.physicianPayments[year]
                                  const paymentMonths = Object.keys(this.state.physicianPayments[year]).reverse()
                                  return (
                                    <Fragment key={i}>
                                      <h1>{year}</h1>
                                      {paymentMonths.map((month, j) => {
                                        const paymentDays = Object.keys(this.state.physicianPayments[year][month])
                                        const drawerString = i.toString() + '-' + j.toString()
                                        return (
                                          <Fragment key={`${i}-${j}`}>
                                            <div className="black-tab"></div>
                                            <div
                                              className="month-div"
                                              onClick={() => {
                                                // const drawerInt = parseInt(drawerString)
                                                if (!this.state.animateDrawers.includes(drawerString)) {
                                                  this.setState({
                                                    animateDrawers: [...this.state.animateDrawers, drawerString],
                                                    // openDrawers: [...this.state.openDrawers, drawerString]
                                                  })
                                                  console.log('drawers first time', this.state.openDrawers)
                                                } else if (this.state.openDrawers.includes(drawerString)) {
                                                  this.setState({
                                                    openDrawers: this.state.openDrawers.filter(drawer => drawer !== drawerString)
                                                  })
                                                  console.log('drawer includes arr', this.state.openDrawers)
                                                } else {
                                                  this.setState({
                                                    openDrawers: [...this.state.openDrawers, drawerString]
                                                  })
                                                  console.log('add drawer', this.state.openDrawers)
                                              }
                                              }}
                                            >
                                              <span>{months[month]}</span>
                                              <span className={this.handleDrawerClass(i, j)}><img className="carrot" src={carrot} /></span>
                                            </div>
                                            {paymentDays.map((day, k) => {
                                              const dayPayment = this.state.physicianPayments[year][month][day]
                                              return (this.state.animateDrawers.includes(drawerString) && !this.state.openDrawers.includes(drawerString)) ?
                                              (
                                                <div
                                                  // className={this.state.openDrawers.includes(drawerString) ? "expand-content flex space-between" : "shrink-content flex space-between"}
                                                  className="flex space-between"
                                                  key={`${i}-${j}-${k}`}
                                                  style={{ margin: '5px 0' }}
                                                >
                                                  <span><strong>{`${month}/${day}`}</strong></span>
                                                  <span>{`$${dayPayment}`}</span>
                                                </div>
                                              ) :
                                              (null)
                                            })}
                                          </Fragment>
                                        )
                                      })}
                                    </Fragment>
                                  )
                                })}
                              </Fragment>
                            </div>
                            */}
                          </div>
                        )
                        : (
                        <div
                          style={{
                            height: '338px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              border: '3px solid black',
                              borderRadius: '2em',
                              padding: '0.5em',
                              width: '7em',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <span><strong>M-PROC</strong></span>
                          </div>
                          <h1 style={{fontWeight: 500}}>
                            Set up payment info to access remote testing
                          </h1>
                          <div
                            id="stripe-btn"
                            className="btn-div"
                            onClick={this.onClickAnishsButton}
                          >
                            <p>Payment Setup</p>
                          </div>
                        </div>
                        )
                      }
                    </Fragment>
                  }
                </main>
                <div className="back-link-container">
                  <div className="link back-link" onClick={this.goBack} style={{marginBottom: '10px'}}>
                    <div id="settings-back">
                      <h3>Back</h3>
                    </div>
                  </div>
                  <div className="link back-link" onClick={this.signOut}>
                    <div id="settings-back">
                      <h3>Sign Out</h3>
                    </div>
                  </div>
                </div>
                {this.state.userMessage &&
                  <div className={
                    this.state.userMessage.includes('error') ? 'failure-snack slideInLeft' : 'success-snack slideInLeft'
                  }>
                    <img src={check} /><p>{this.state.userMessage}</p>
                  </div>
                }
              </div>
            </div>
            :
            <Loading />
          }
        </Fragment>
      )
    } else {
        return null
      }
    }
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  userData: state.authReducer.userData,
  userSubscription: state.settingsReducer.userSubscription,
  isLoadingDone: state.settingsReducer.isLoadingDone,
  userMessage: state.settingsReducer.userMessage,
  cookies: ownProps.cookies
})

export default withCookies(connect(mapStateToProps, { hideNav, changePayment, cancelSubscription, signOut })(Settings))
