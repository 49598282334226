import types from '../actions/types'

const initialState = { isLoadingDone: true }

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SUBSCRIPTION:
      return {
        ...state, userSubscription: action.payload
      }
    case types.LOADING_SUBSCRIPTION:
      return {
        ...state,
        isLoadingDone: action.payload.isLoadingDone,
        userMessage: action.payload.userMessage
      }
    default:
      return state
  }
}
