import types from './types'
import firebase from 'firebase/app';

export const submitPromoCode = (database, promoCode) => dispatch => {
  let self = this
  database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Membership/CodesUsed/' + promoCode).once("value", snapshot => {
    if (snapshot.exists()) {
      let dataToUse = snapshot.val()
      if (dataToUse) {
        dispatch({
          type: types.SUBMIT_PROMO_CODE,
          payload: {
            promoMessage: "Error: Code has already been used",
            time: Date.now()
          }
        })
      } else if (promoCode = "LazMProc") {
        let today = new Date()
        let month = today.getMonth() + 1
        let date = today.getDate()
        let year = today.getFullYear()

        let dateString = `${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`}/${year}`
        let expireDate = new Date(dateString).getTime() + 7257600000
        database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Sub').set({
          Expires: expireDate,
          Start: new Date().getTime(),
          ID: "Promo"
        }).then(function () {
          dispatch({
            type: types.SUBMIT_PROMO_CODE,
            payload: {
              isSubscriptionActive: true,
              promoMessage: "Code registered"
            }
          })
        })
      } else {
        database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Membership/CodesUsed/' + promoCode).set(true)
          .then(function () {
            let today = new Date()
            let month = today.getMonth() + 1
            let date = today.getDate()
            let year = today.getFullYear()

            let dateString = `${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`}/${year}`
            let expireDate = new Date(dateString).getTime() + 604800000
            database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Sub').set({
              Expires: expireDate,
              Start: new Date().getTime(),
              ID: "Promo"
            }).then(function () {
              dispatch({
                type: types.SUBMIT_PROMO_CODE,
                payload: {
                  isSubscriptionActive: true,
                  promoMessage: "Code registered"
                }
              })

              //Go to setttings/patient list

            })
          })
      }
    } else {
      dispatch({
        type: types.SUBMIT_PROMO_CODE,
        payload: {
          promoMessage: "Error: Invalid Code Entered"
        }
      })
    }
  })
}
