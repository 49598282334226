/*

Batch was introduced to incorporate remote-patient testing.
Much of the code is from Test component

*/

import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import {fetchPatientData} from '../../actions/fetchPatientDataAction'
import {storePatientData} from '../../actions/storePatientNameAction'
import {storeTestSetData} from '../../actions/storeTestSetDataAction'
import {storeBatch} from '../../actions/storeBatchAction'
import {hideNav} from '../../../Auth/actions/hideNavAction'
import helpers from '../../../helperFunctions.js'
import Loading from '../../../RootComponents/Loading'
import TestPdf from '../Test/TestPdf2.js'
import PatientContactModal from './PatientContactModal'

import {PDFDownloadLink} from '@react-pdf/renderer'
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import profilePic from '../../../img/User.svg'
import deck from '../../../img/Deck.svg'

import manFront from '../../../img/manFront.jpg'
import manBack from '../../../img/manBack.jpg'

import womanFront from '../../../img/womanFront.jpg'
import womanBack from '../../../img/womanBack.jpg'

import queryString from 'query-string'

import firebase from 'firebase/app';
import 'firebase/auth';

function ValueLabelComponent(props) {
  const {children, open, value} = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

class Batch extends Component {
  constructor(props) {
    super(props);
    this.xAxis = React.createRef()
    this.yAxis = React.createRef()
    this.wirePersonSection = React.createRef()
    this.wirePersonContainer = React.createRef()
    this.wirePerson = React.createRef()
    this.batchMain = React.createRef()
    this.state = {
      counter: 0,
      physicianMessage: '',
      patientEmail: '',
      patientPhone: '',
      patientId: '',
      confidenceFilter: true,
      diseaseFilter: false,
      confidenceValues: [0, 99],
      dateFilter: false,
      dateMin: '2018-11-25T15:34:11.285Z',
      isLoading: (this.props.isLoading) ? this.props.isLoading : true,
      checkBoxClasses: 'checkbox-div disappear',
      subFullMessage: false,
      selectedTestCoordinates: [0, 0],
      selectedTestFront: true,
      selectedTestSetIndex: null,

      patient: {
        birthday: 'YYYY-MM-DD',
        id: '',
        id2: '',
        image: undefined,
        lastDate: 'YYYY/MM/DD 00:00:00',
        name: '',
      },
    }

    this.pushIsEmailSent = this.pushIsEmailSent.bind(this)
    this.formatFileNameDate = this.formatFileNameDate.bind(this)
    this.goBack = this.goBack.bind(this)
    this.testSetClass = this.testSetClass.bind(this)
    this.onHoverTestSet = this.onHoverTestSet.bind(this)
    this.onClickTestSet = this.onClickTestSet.bind(this)
    this.sendPatientEmail = this.sendPatientEmail.bind(this)
    this.handleHiding = this.handleHiding.bind(this)
    this.handleHiding2 = this.handleHiding2.bind(this)
    this.handleWirePerson = this.handleWirePerson.bind(this)
    this.onChangePhysicianMessage = this.onChangePhysicianMessage.bind(this)
    this.onClickEmailBtn = this.onClickEmailBtn.bind(this)
    this.setWirePersonDimensions = this.setWirePersonDimensions.bind(this)
  }

  componentDidMount() {
    const self = this
    const batchId = queryString.parse(window.location.search).id
    if (this.props.firestore.app && batchId) { // If signedIn
      this.props.firestore.collection('PatientTests').doc(batchId).onSnapshot((doc) => {
        if (doc.exists) {
          const res = {
            ...doc.data(),
            batchId: batchId,
          }
          this.props.firestore.collection('OnlinePatients').doc(res.Patient).onSnapshot((patientDoc) => {
            if (patientDoc.exists) {
              this.setState({
                patient: patientDoc.data(),
                patientEmail: patientDoc.data().email,
                patientPhone: patientDoc.data().phone,
              }, () => {
                this.props.storePatientData({
                  id: this.state.patientId,
                  id2: this.state.patientId2,
                  patient: this.state.patient,
                })
              })
            }
          })
          self.setState({batch: res})
          // setTimeout(() => {
          //   this.setIsPdfReady()
          // }, 2000);
          this.props.storeBatch(res)
        }
      })
    }

    this.props.hideNav(false)
    const mainHeight = window.innerHeight
    this.setState({mainHeight})
    this.setWirePersonDimensions()
    window.addEventListener('resize', this.setWirePersonDimensions);
  }

  setWirePersonDimensions() {
    const mainHeight = window.innerHeight
    this.setState({mainHeight})
    const wirePersonContainer = this.wirePersonContainer.current
    // const wirePersonImage = this.wirePerson.current
    // const batchMain = this.batchMain.current

    const wirePersonSection = this.wirePersonSection.current

    if (wirePersonContainer && wirePersonSection) {
      wirePersonContainer.style.height = `${wirePersonSection.clientWidth * 1.45667}px`
      wirePersonContainer.style.width = `${wirePersonSection.clientWidth}px`
    }
  }

  pushIsEmailSent(bool) {
    const firestoreRef= this.props.firestore.collection('PatientTests')
    const batchId = queryString.parse(window.location.search).id
    if (batchId) {
      firestoreRef.doc(batchId).update({IsEmailSent: bool, PhysicianMessage: this.state.physicianMessage})
          .then((snapshot) => {
            console.log('snapshot', snapshot)
          })
    }
  }

  componentDidUpdate() {
    if (this.state.subFullMessage) {
      setTimeout(() => {
        this.setState({subFullMessage: false})
      }, 3000);
    }
    if (this.state.isPdfReady) {
      setInterval(async () => {
        if (document.getElementById('pdf-ready') && this.state.isPdfReady) { // button was being triggered several times
          await document.getElementById('pdf-ready').click()
          await this.setState({isPdfReady: false})
          // console.log('isPdf Ready', this.state.isPdfReady)
        }
      }, 4000)
    }
    // if (this.state.isPdfReady) {
    //   if (document.getElementById('pdf-ready')) {
    //     document.getElementById('pdf-ready').click()
    //     this.setState({isPdfReady: false})
    //   }
    // }
    if (this.state.diseaseFilter &&
       (this.state.checkBoxClasses === 'checkbox-div disappear' ||
        this.state.checkBoxClasses === 'checkbox-div slideOutUp')) {
      this.setState({checkBoxClasses: 'checkbox-div slideInDown'})
    } else if (!this.state.diseaseFilter && this.state.checkBoxClasses === 'checkbox-div slideInDown') {
      this.setState({checkBoxClasses: 'checkbox-div slideOutUp'})
    }
  }

  formatFileNameDate(str) {
    if (str) {
      const fileNameDate = str.slice(0, 4) + '_' + str.slice(5, str.length)
      return fileNameDate.replace('-', '_')
    } else {
      return ''
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  testSetClass(i) {
    const animation = 'animate' + i // toggles animation (prevent unselect animation from triggering on render)
    if (this.state[animation]) {
      if (this.state.selectedTestSetIndex === i) {
        return 'test-set-section selected-test-set-section'
      } else {
        return 'test-set-section unselected-test-set-section'
      }
    } else {
      return 'test-set-section'
    }
  }

  onHoverTestSet(testSet, i) {
    this.setState({selectedTestCoordinates: testSet.MoleCoordinates, selectedTestFront: testSet.Front})
    if (this.state.selectedTestCoordinates) {
      const yAxis = this.yAxis.current
      const xAxis = this.xAxis.current

      // accommodate photo that is 125% of its container to clip margins
      // const adjustedX = this.state.selectedTestCoordinates[0] * 1.25 - 12.5
      // const adjustedY = this.state.selectedTestCoordinates[1] * 1.25 - 12.5 // 15% margin

      // if coordinates are outside the boundaries of the 'cropped' photo, don't render coordinates
      // const isValidCoordinate = (adjustedX > 0 && adjustedY > 0) && (adjustedX < 100 && adjustedY < 100)
      // let x = isValidCoordinate ? adjustedX : 0
      // let y = isValidCoordinate ? adjustedY : 0
      // console.log('adjusted xy', adjustedX, adjustedY)
      // console.log('x, y', x, y)

      yAxis.style.width = `${testSet.MoleCoordinates[0]}%`
      xAxis.style.height = `${testSet.MoleCoordinates[1]}%`
    }
  }

  onClickTestSet(testSet, i) {
    if (this.state.selectedTestSetIndex === i) {
      this.setState({selectedTestSetIndex: -1})
    } else {
      const animation = 'animate' + i
      // to toggle animation (prevents unselected animation from firing on page load)
      this.setState({[animation]: true, selectedTestCoordinates: testSet.MoleCoordinates, selectedTestFront: testSet.Front, selectedTestSetIndex: i})
      if (this.state.selectedTestCoordinates) {
        const yAxis = this.yAxis.current
        const xAxis = this.xAxis.current

        yAxis.style.width = `${testSet.MoleCoordinates[0]}%`
        xAxis.style.height = `${testSet.MoleCoordinates[1]}%`
      }
    }
  }

  sendPatientEmail() {
    const dataToSend = {
      case: this.state.case,
      patient_email: this.state.patientEmail,
      physician_id: firebase.auth().currentUser.uid,
      notes: this.state.physicianMessage,
      test_time: this.state.batch.Time,
      test_id: queryString.parse(window.location.search).id
    }
    fetch('https://us-central1-raw-compute.cloudfunctions.net/cds-patient-contact', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend),
    })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            slideInSureModal: false,
            isEmailSending: false,
            emailSentModal: true,
            slideInSentModal: true,
            batch: {
              ...this.state.batch,
              IsEmailSent: true,
            }
          })
          this.pushIsEmailSent(true)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
  }

  handleHiding(defaultClass) {
    if (this.props.userData && this.props.userData.Settings && this.props.userData.Settings.IsAIHidden) {
      return defaultClass + ' hidden'
    } else {
      return defaultClass
    }
  }

  handleHiding2(defaultClass) {
    if (this.props.userData && this.props.userData.Settings && this.props.userData.Settings.IsAIHidden) {
      return 'display-none'
    } else {
      return defaultClass
    }
  }

  handleWirePerson() {
    if (this.state.patient.sex === 'Female') {
      if (this.state.batch && this.state.batch.TestSets[0].Front) {
        return womanFront
      } else {
        return womanBack
      }
    } else {
      if (this.state.batch && this.state.batch.TestSets[0].Front) {
        return manFront
      } else {
        return manBack
      }
    }
  }

  onChangePhysicianMessage(message) {
    this.setState({physicianMessage: message})
  }

  onClickEmailBtn(newState) {
    this.setState({
      ...this.state,
      ...newState,
    })
  }

  render() {
    if (this.props.firestore.app && this.props.signedIn) { // If signedIn
      let decision = 'N/A'
      if (this.state.batch && this.props.userData) {
        decision = this.props.userData.OnlineTests.Tests[this.state.batch.batchId].Decision
      }

      const benignEmailText = (
        <div className="email-preview-container">
          <p>
            {`Dear ${this.state.patient.name}, `}
            <br></br>
            <br></br>
            <i>Your skin screen results are available.</i>
            <br></br>
            <br></br>
            {`The skin screening you ordered ${this.state.batch && this.state.batch.TestSets.Date ? 'on ' + this.state.batch.TestSets.Date : ''}has been completed, and ${this.props.userData && this.props.userData.Info.Name} does not recommend further testing at this time.`}
            <br></br>
            <br></br>
            {`Your attention to your health is a good practice. To sustain it, consider scheduling a dermatology checkup. It is recommended to take one general dermatology checkup each year. If another specific concern arises, please schedule an appointment sooner.`}
            <br></br>
            <br></br>
            {this.state.physicianMessage &&
            <div className="physician-message-container">
              <p className="margin-0">{`${this.props.userData.Info.Name}'s Notes:`}</p>
              <br></br>
              <p className="margin-0">{this.state.physicianMessage}</p>
            </div>
            }
            <br></br>
            Sincerely,
            <br></br>
            <br></br>
            {this.props.userData && this.props.userData.Info.Name}
          </p>
        </div>
      )

      const malignantEmailText = (
        <div className="email-preview-container">
          <p>
            {`Dear ${this.state.patient.name}, `}
            <br></br>
            <br></br>
            <i>Your skin screening is complete: </i><strong>Immediate Followup Necessary</strong>
            <br></br>
            <br></br>
            {`The skin screening you ordered ${this.state.batch && this.state.batch.TestSets.Date ? 'on ' + this.state.batch.TestSets.Date : ''}has been completed, and ${this.props.userData.Info.Name} recommends immediate further testing for a complete diagnosis. Please review the included notes, and take the necessary steps to consult with a dermatologist in your area.`
            }
            <br></br>
            <br></br>
            {this.state.physicianMessage &&
            <Fragment>
              <div className="physician-message-container">
                <p className="margin-0">{`${this.props.userData.Info.Name}'s Notes:`}</p>
                <br></br>
                <p className="margin-0">{this.state.physicianMessage}</p>
              </div>
              <br></br>
            </Fragment>
            }
            {'For guidance on next steps, please contact a dermatologist to discuss your screening results and schedule an appointment for a followup visit.'}
            <br></br>
            <br></br>
            Sincerely,
            <br></br>
            {this.props.userData.Info.Name}
          </p>
        </div>
      )

      const {patient} = this.state

      const patientTests = this.state.batch ? this.state.batch.TestSets[0].Tests : []

      const testSets = this.state.batch ? this.state.batch.TestSets : null

      const patientsHash = {}

      if (patientTests) {
        patientTests.forEach((test) => {
          if (patientsHash.hasOwnProperty(test.data.Results)) {
            ++patientsHash[test.data.Results]
          } else {
            patientsHash[test.data.Results] = 1
          }
        })
      }

      return (
        <Fragment>
          <div id="patient-page" className="batch-page" style={{height: `${this.state.mainHeight - 110}px`}}>
            { this.state.subFullMessage &&
              <div className="failure-snack slideInLeft">
                <p>Your subscription is full</p>
              </div>
            }
            { this.state.patientContactModal &&
              <Fragment>
                <div className={this.state.slideInModal ? 'slideInUp contact-modal' : 'slideOutDown contact-modal' }>
                  <PatientContactModal
                    onChangePhysicianMessage={this.onChangePhysicianMessage}
                    onClickEmailBtn={this.onClickEmailBtn}
                    patientPhone={this.state.patientPhone}
                    patientEmail={this.state.patientEmail}
                    patientName={this.state.patient.name}
                  />
                  <div className="flex">
                    <div
                      className="btn-div2"
                      onClick={() => this.onClickEmailBtn({sureModal: true, slideInSureModal: true, case: 'benign'})}
                    >
                      <p>Benign</p>
                    </div>
                    <div
                      className="btn-div2"
                      onClick={() => this.onClickEmailBtn({ sureModal: true, slideInSureModal: true, case: 'malignant'})}
                    >
                      <p>Possibly Malignant</p>
                    </div>
                  </div>
                </div>
                { this.state.slideInModal &&
                  <div
                    className="grey-screen"
                    onClick={() => this.setState({slideInModal: false})}
                  >
                  </div>
                }
              </Fragment>
            }
            { this.state.emailSentModal &&
              <Fragment>
                <div id="sent-modal" className={this.state.slideInSentModal ? 'slideInUp contact-modal' : 'slideOutDown contact-modal' }>
                  <div>
                    <h2>An email has been sent to this patient</h2>
                    <p>{`Email: ${this.state.patientEmail}`}</p>
                    <p>{`Phone: ${this.state.patientPhone}`}</p>
                  </div>
                  <div
                    className="btn-div2"
                    onClick={() => this.setState({slideInSentModal: false})}
                  >
                    <p>Close</p>
                  </div>
                </div>
                { this.state.slideInSentModal &&
                  <div
                    className="grey-screen"
                    onClick={() => this.setState({slideInSentModal: false})}
                  >
                  </div>
                }
              </Fragment>
            }
            { this.state.sureModal &&
              <Fragment>
                {this.state.isEmailSending ?
                <Loading /> :
                <div
                  id="z-index-5"
                  className={this.state.slideInSureModal ? 'slideInUp contact-modal' : 'slideOutDown contact-modal' }
                >
                  <div>
                    <h3 style={{fontWeight: 'bold', marginTop: 0}}>Are you sure you would like to send this email?</h3>
                  </div>
                  {this.state.case === 'benign' ?
                    benignEmailText :
                    malignantEmailText
                  }
                  <div className="flex">
                    <div
                      className="btn-div2"
                      onClick={() => {
                        this.setState({sureModal: true, slideInSureModal: false, slideInModal: false, isEmailSending: true})
                        this.sendPatientEmail()
                      }}
                    >
                      <p>Yes</p>
                    </div>
                    <div
                      className="btn-div2"
                      onClick={() => this.setState({slideInSureModal: false})}
                    >
                      <p>No</p>
                    </div>
                  </div>
                </div>
                }
              </Fragment>
            }
            { this.state.slideInSureModal &&
                <div
                  className="grey-screen2"
                  onClick={() => this.setState({slideInSureModal: false})}
                >
                </div>
            }
            <header id="patient-header" className="patient-div">
              <div className="patient-img-div">
                <img className="patient-img" src={patient.image ? patient.image : profilePic} />
              </div>
              <p className="background-white patient-name-p">{patient.name ? patient.name : ''}</p>
              <div className="flex-center">
                <div className="background-white align-center">
                  <p className="background-white last-updated-p grey-text">Birthday:</p>
                  {<p className="no-margin background-white">
                    { patient.birthday && helpers.formatDate(patient.birthday) }
                  </p>
                  }
                </div>
              </div>
              <div className="background-white flex-column justify-center align-flex-end">
                { patient.birthday &&
                  <div className="align-center update-div">
                    <p className="background-white last-updated-p grey-text">Last Updated:</p>
                    <p className="no-margin background-white">
                      { patient.lastDate ?
                      helpers.formatDate(patient.lastDate.slice(0, patient.lastDate.length - 9)) :
                      "N/a"
                      }
                    </p>
                  </div>
                }
              </div>
            </header>
            <div className={ "patient-page-content" }>
              <aside id="patient-aside">
                <div id="aside-top">
                  <div className="aside-div total-tests-div">
                    <p>Total Test-sets</p>
                    <h1>{ this.state.batch ? testSets.length : 0 }</h1>
                  </div>
                  <div className="aside-div total-tests-div">
                    <p>Date</p>
                    <h1>{ helpers.formatDate(this.state.batch && testSets[0].Tests[0].data.DateTaken.slice(0, 10)) }</h1>
                  </div>
                  <div
                    className="btn-div2"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                    onClick={() => {
                      if (!this.state.batch.IsEmailSent) {
                        this.setState({patientContactModal: true, slideInModal: !this.state.slideInModal})
                      } else {
                        this.setState({emailSentModal: true, slideInSentModal: !this.state.slideInSentModal})
                      }
                    }}
                  >
                    <p>Contact Patient</p>
                  </div>
                  {queryString.parse(window.location.search).admin === 'true' &&
                  <div
                    className="btn-div2 pdf-btn"
                    onClick={() => {
                      this.setState({isPdfReady: true})
                      // setTimeout(() => {
                      //   if (document.getElementById('pdf-ready')) {
                      //     console.log('clicking pdf-ready')
                      //     document.getElementById('pdf-ready').click()
                      //     this.setState({isPdfLoading: false, isPdfReady: false})
                      //   }
                      // }, 2000);
                    }}
                  >
                    {this.state.isPdfLoading ? <p>Loading...</p> : <p>Download PDF</p>}
                  </div>
                  }
                  {this.state.isPdfLoading && <Loading />}
                  {this.state.isPdfReady && testSets &&
                  <PDFDownloadLink
                    document={
                      <TestPdf
                        physicianInfo={this.props.userData.Info}
                        physicianMessage={this.state.batch.PhysicianMessage || null}
                        patient={patient}
                        wirePerson={this.handleWirePerson()}
                        patientBirthday={helpers.formatDate(patient.birthday)}
                        patientNotes={this.state.batch && this.state.batch.TestSets[0].Notes}
                        testDate={helpers.formatDate(this.state.batch && testSets[0].Tests[0].data.DateTaken.slice(0, 10))}
                        testSet={testSets[0]}
                        test1Image={testSets[0].Tests[0].data.ImageRef}
                        isEmailSent={this.state.batch.IsEmailSent || null}
                        decision={decision || 'N/A'}
                        testSetId = {testSets[0].Id && testSets[0].Id.slice(0, testSets[0].Id.indexOf('-'))}
                      />
                    }
                    fileName={`Dm_${this.formatFileNameDate(testSets[0].Tests[0].data.DateTaken.slice(0, 10))}_${patient.name}_${this.props.userData.Info.Name}.pdf`}
                    className="link"
                  >
                    {({blob, url, loading, error}) => {
                      return (!loading && blob && url &&
                      <div id="pdf-ready"className="btn-div2 pdf-btn-hidden">
                      </div>
                      )
                    }}
                  </PDFDownloadLink>
                  }
                  {this.state.isPdfReady &&
                  <Loading />
                  }
                </div>
              </aside>
              <div id="batch-main" ref={this.batchMain}>
                <div className="test-set-list">
                  {/* Test sets go here */}
                  {this.state.batch && testSets.map((testSet, i) => {
                    return (
                      <div
                        className={this.testSetClass(i)}
                        onMouseEnter={() => this.onHoverTestSet(testSet, i)}
                        onClick={() => this.onClickTestSet(testSet, i)}
                        key={`testSet-${i}`}
                        // onMouseLeave={() => this.setState({ selectedTestSetIndex: 'none' })}
                      >
                        <div
                          className="test-set-container"
                        >
                          <div className="flex-column justify-space-between">
                            <div className="flex space-between">
                              <p>{`Test ID: ${ testSet.Id && testSet.Id.slice(0, testSet.Id.indexOf('-'))}`}</p>
                              <img src={deck} alt='deck' />
                            </div>
                            <div className="flex">
                              <p>{testSet['Date']}</p>
                            </div>
                          </div>
                          <h2>{testSet.MoleLocation}</h2>
                        </div>
                        <div className="test-set-tests">
                          { testSet.Tests.map((test, j) => {
                            return (
                              <Link
                                className={'link'}
                                style={{width: '90%'}}
                                key={`test-${i}-${j}`}
                                to={{
                                  pathname: `/test/?id=${test.id}`,
                                  query: {
                                    id: test.id,
                                  },
                                }}
                              >
                                <div
                                  className="test-set-test-div"
                                  onClick={() => this.props.storeTestSetData(test, this.state.patient, this.state.batch.TestSets[i], i, j)}
                                >
                                  <div className="test-set-img-div">
                                    <img className="test-set-img" src={test.data.ImageRef} />
                                  </div>
                                  <div className={this.handleHiding('confidence-container')}>
                                    <div className="confidence-div">
                                      <CircularProgressbarWithChildren
                                        value={
                                          test.data.Confidence && test.data.Confidence === 1 ? 100 :
                                          parseInt(test.data.Confidence.toString().slice(2, 4))
                                        }
                                        styles={buildStyles({
                                          textColor: 'black',
                                          pathColor: helpers.determinePathColor(test.data.Results, test.data.Confidence, test.data.Reasoning),
                                          trailColor: '#ececec'
                                        })}
                                        strokeWidth={4}
                                      >
                                        <h3 className="margin-0">{ test.data.Confidence && test.data.Confidence === 1 ? '100%' :
                                            test.data.Confidence.toString().slice(2, 4) + '%'
                                        }
                                        </h3>
                                        <p className="margin-0 size-10">Confidence</p>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                  </div>
                                  <div className={this.handleHiding('results')}>
                                    <p className="grey-text">Results</p>
                                    <p className={ helpers.isPotentialMelanoma(test.data.Reasoning) && test.data.Results.includes('lesion') ? 'smaller-text' : 'regular-text'}>{ helpers.isPotentialMelanoma(test.data.Reasoning) &&
                                          test.data.Results !== 'Melanoma' ? `${test.data.Results} (possible melanoma)` : test.data.Results
                                    }
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            )
                          })
                          }
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
                <div
                  id="wire-person-section"
                  ref={this.wirePersonSection}
                >
                  <div
                    id="wire-person-container"
                    ref={this.wirePersonContainer}
                  >
                    <div
                      id="x-axis"
                      ref={this.xAxis}
                      className="axis"
                      style={{ borderBottom: '0.2px solid rgb(145, 145, 145)' }}
                    >
                    </div>
                    <div id="y-axis" ref={this.yAxis} className="axis"></div>
                    <img src={this.handleWirePerson()} alt="wire-person" ref={this.wirePerson} />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {/* <Redirect to='/' /> */}
          <Loading />
        </Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  storage: state.firebaseReducer.storage,
  firestore: state.firebaseReducer.firestore,
  patientList: state.patientsReducer.patientList,
  signedIn: state.authReducer.signedIn,
  fullTestList: state.patientsReducer.patientTestData.fullTestList,
  patientTests: state.patientsReducer.patientTestData.patientTests,
  physicianTests: state.patientsReducer.patientTestData.tests,
  loadingTests: state.patientsReducer.patientTestData.loading,
  patientGender: state.patientsReducer.patientTestData.gender,
  signedIn: state.authReducer.signedIn,
  subscriptionData: state.authReducer.subscriptionData,
  userData: state.authReducer.userData,
  isSubscriptionFull: state.authReducer.isSubscriptionFull,
})

export default connect(mapStateToProps, {fetchPatientData, storePatientData, storeTestSetData, storeBatch, hideNav})(Batch)
