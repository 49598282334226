import firebase from 'firebase/app';
import types from './types'

export const fetchSubscriptionData = (database) => dispatch => {
  database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Sub').once("value", snapshot => {
    if (snapshot.exists()) {
      let dataToUse = snapshot.val()
      if (dataToUse["ID"] === "Promo") {
        let checkTime = dataToUse["Expires"]
        let nowTime = Date.now()
        if (nowTime < checkTime) {
          dispatch({
            type: types.FETCH_SUBSCRIPTION,
            payload: {
              status: "active",
              subPlan: "Promo",
              expires: checkTime,
              cusID: "None"
            }
          })
        } else {
          alert("Your promo trial has finished.")
          dispatch({
            type: types.FETCH_SUBSCRIPTION,
            payload: {
              status: "Expired",
              subPlan: "Promo",
              expires: checkTime,
              cusID: "None"
            }
          })
        }
      } else {
        fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/checkSub?subID=` + dataToUse["ID"])
          .then(res => res.json())
          .then(res => {
            dispatch({
              type: types.FETCH_SUBSCRIPTION,
              payload: {
                status: res["status"],
                subPlan: res["plan"]["id"],
                subID: res["id"],
                cusID: dataToUse["CusID"],
                amount: res["items"]["data"][0]["plan"]["amount"],
                interval: res["items"]["data"][0]["plan"]["interval"],
                start: res["current_period_start"],
                end: res["current_period_end"]
              }
            })
          })
          .catch(function (error) {
            alert("There was an error getting your subscription info")
            dispatch({
              type: types.FETCH_SUBSCRIPTION,
              payload: {
                status: "error",
                subPlan: "error",
                subID: "error",
                cusID: "error"
              }
            })
          })
      }
    } else {
      dispatch({
        type: types.FETCH_SUBSCRIPTION,
        payload: {
          status: "None",
          subPlan: "None",
          subID: "None",
          cusID: "None"
        }
      })
    }
  })
}
