import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {fetchPatientData} from '../../actions/fetchPatientDataAction'
import {storePatientData} from '../../actions/storePatientNameAction'
import {hideNav} from '../../../Auth/actions/hideNavAction'
import helpers from '../../../helperFunctions.js'
import QRCode from 'qrcode.react'
import ImageUpload from '../ImageUpload'
import Loading from '../../../RootComponents/Loading'

import {CircularProgressbarWithChildren, buildStyles}
  from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import Switch from '@material-ui/core/Switch'
import Slider from '@material-ui/core/Slider'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'

import profilePic from '../../../img/User.svg'
import flag from '../../../img/Flag.svg'
import notes from '../../../img/Notes.svg'

import queryString from 'query-string'
import './index.scss'

import firebase from 'firebase/app'
import 'firebase/auth'

function ValueLabelComponent(props) {
  const {children, open, value} = props

  const popperRef = React.useRef(null)
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update()
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

/* Patient renders local patient page and all local test links
   Includes filtering for tests,
   add test,
   and qr code for adding test with phone/tablet
*/

class Patient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      diseases: [
        'Actinic keratoses',
        'Basal cell carcinoma',
        'Benign keratosis-like lesions ',
        'Dermatofibroma',
        'Melanocytic nevi',
        'Melanoma',
        'Vascular lesions',
      ],
      lookingAtPhysician: true,
      qr: false,
      patientId: '',
      counter: 0,
      confidenceFilter: true,
      diseaseFilter: false,
      confidenceValues: [0, 99],
      dateFilter: false,
      dateMin: '2018-11-25T15:34:11.285Z',
      isLoading: true,
      checkBoxClasses: 'checkbox-div disappear',
      subFullMessage: false
    }

    this.isPatientInBillingCycle = this.isPatientInBillingCycle.bind(this)
    this.setQr = this.setQr.bind(this)
    this.onClickQR = this.onClickQR.bind(this)
    this.handleConfidenceFilter = this.handleConfidenceFilter.bind(this)
    this.goBack = this.goBack.bind(this)
    this.handleHiding = this.handleHiding.bind(this)
    this.handleHiding2 = this.handleHiding2.bind(this)
    this.clearSubFullMessage = this.clearSubFullMessage.bind(this)
    this.willExpandDiseaseFilter = this.willExpandDiseaseFilter.bind(this)
    this.willCollapseDiseaseFilter = this.willCollapseDiseaseFilter.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.isAIHidden = this.isAIHidden.bind(this)
  }

  UNSAFE_componentWillMount() {
    const patientId2 = queryString.parse(window.location.search).id2
    const patientId = queryString.parse(window.location.search).id

    if (this.props.firestore.app) { // If signedIn
      this.setState({patientId2: patientId2, patientId: patientId})
    }
  }

  componentDidMount() {
    const patient = this.props.patientList.filter((patient) => {
      return patient.id2 === this.state.patientId2
    })
    let mainHeight = window.innerHeight
    this.setState({patient, mainHeight})
    this.props.storePatientData({
      id: this.state.patientId,
      id2: this.state.patientId2,
      patient: patient[0],
    })
    this.props.hideNav(false)
    window.addEventListener('resize', () => {
      mainHeight = window.innerHeight
      this.setState({mainHeight})
    })
    setInterval(() => { // Hack: Wasn't triggered by CWRP
      this.setState({
        date: Math.floor(Date.now()/10000),
        filteredTests: this.props.physicianTests,
      })
    }, 1000)
    if (!this.props.physicianTests) {
      this.props.fetchPatientData(this.props.firestore, this.state.patientId2)
    } else {
      this.setState({isLoading: false})
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.patientList) {
      const patient = this.props.patientList.filter((patient) => {
        return patient.id2 === this.state.patientId2
      })
      this.setState({patient})
    }
    if (props.physicianTests) {
      this.setState({
        filteredTests: props.physicianTests,
        isLoading: props.loadingTests,
      })
    }
    if (this.props.physicianTests && !this.props.loadingTests) {
      this.setState({isLoading: false})
    }
  }

  clearSubFullMessage() {
    setTimeout(() => {
      this.setState({subFullMessage: false})
    }, 3000);
  }

  willExpandDiseaseFilter() {
    return (
      this.state.diseaseFilter &&
      (this.state.checkBoxClasses === 'checkbox-div disappear' ||
      this.state.checkBoxClasses === 'checkbox-div slideOutUp')
    )
  }

  willCollapseDiseaseFilter() {
    return (
      !this.state.diseaseFilter &&
      this.state.checkBoxClasses === 'checkbox-div slideInDown'
    )
  }

  componentDidUpdate() {
    if (this.state.subFullMessage) {
      this.clearSubFullMessage()
    }
    if (this.willExpandDiseaseFilter()) {
      this.setState({ checkBoxClasses: 'checkbox-div slideInDown'})
    } else if (this.willCollapseDiseaseFilter()) {
      this.setState({ checkBoxClasses: 'checkbox-div slideOutUp'})
    }
  }

  isPatientInBillingCycle() {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    let patientListKeys
    const patientListArr = []
    if (this.props.subscriptionData.subPlan.includes('year')) {
      patientListKeys = Object.keys(this.props.userData.PatientCount[year].Patients)
      patientListKeys.forEach((key) => {
        patientListArr.push(this.props.userData.PatientCount[year].Patients[key])
      })
    } else if (this.props.subscriptionData.subPlan.includes('month')) {
      patientListKeys = Object.keys(this.props.userData.PatientCount[year][month].Patients)
      patientListKeys.forEach((key) => {
        patientListArr.push(this.props.userData.PatientCount[year].Patients[key])
      })
    }
    return patientListArr.some((patient) => {
      return patient === this.state.patientId
    })
  }

  onClickQR() {
    if (this.props.isSubscriptionFull && !this.isPatientInBillingCycle()) {
      this.setState({subFullMessage: true})
    } else {
      this.setState({qr: !this.state.qr})
    }
  }

  handleConfidenceFilter(event, values) {
    this.setState({confidenceValues: values})
  }

  handleSwitch(event, name) {
    this.setState({[name]: event.target.checked});
  }

  goBack() {
    this.props.history.goBack();
  }

  isAIHidden() {
    return (
      this.props.userData &&
      this.props.userData.Settings &&
      this.props.userData.Settings.IsAIHidden
    )
  }

  handleHiding(defaultClass) {
    if (this.isAIHidden()) {
      return defaultClass + ' hidden'
    } else {
      return defaultClass
    }
  }

  handleHiding2(defaultClass) {
    if (this.isAIHidden()) {
      return 'display-none'
    } else {
      return defaultClass
    }
  }

  setQr() {
    if (this.state.qr) {
      this.setState({qr: false})
    }
  }

  render() {
    const isSignedIn = (
      this.props.firestore.app &&
      this.props.signedIn &&
      this.props.userData &&
      this.props.physicianTests &&
      this.state.patient
    )
    if (isSignedIn) {
      const patientTests = this.props.physicianTests
      const {patient} = this.state

      const filterTests =
        patientTests ? patientTests.filter((test) => {
          const flag = this.state.flagFilter ? test.data.Flag : true
          const notes = this.state.notesFilter ? test.data.Notes : true
          const confidence = this.state.confidenceFilter ?
            test.data.Confidence >= (this.state.confidenceValues[0] / 100) &&
            test.data.Confidence <=
              (this.state.confidenceValues[1] / 100 + 0.01) :
            true
          const date =
            this.state.dateFilter ?
            new Date(test.data.DateTaken).getTime() >=
              new Date(this.state.dateMin).getTime() :
            true
          const diseaseFilter = (disease) => {
            switch (disease) {
              case 'Actinic keratoses':
              case 'Basal cell carcinoma':
              case 'Benign keratosis-like lesions ':
              case 'Dermatofibroma':
              case 'Melanocytic nevi':
              case 'Melanoma':
              case 'Vascular lesions':
                if (this.state[disease] && this.state.diseaseFilter) {
                  return true
                } else if (this.state.diseaseFilter) {
                  return false
                } else {
                  return true
                }
              default: return true
            }
          }
          return (
            confidence && date && flag && notes &&
            diseaseFilter(test.data.Results)
          )
        }) : []

      const patientsHash = {}

      if (patientTests) {
        patientTests.forEach((test) => {
          if (patientsHash.hasOwnProperty(test.data.Results)) {
            ++patientsHash[test.data.Results]
          } else {
            patientsHash[test.data.Results] = 1
          }
        })
      }

      const numberOfClassifications = Object.keys(patientsHash).length

      const filterTestsLengthSingleParam = (param) => {
        return patientTests ? patientTests.filter((test) => {
          return test.data[param]
        }).length : 0
      }

      // const testRender = (
      //   <Fragment>
      //     <ul>
      //       { filterTests &&
      //         filterTests.map((test, i) => (
      //           <Link key={i} to={{
      //             pathname: `/test/?id=${test.id}`,
      //             state: {index: test.num},
      //             query: {
      //               id: test.id,
      //             },
      //           }}>
      //             <li>{test.data.Results}</li>
      //           </Link>
      //         ))
      //       }
      //     </ul>
      //   </Fragment>
      // )

      const qrLink = (
        'http://mobile.lazarusderm.com?doc=' +
        firebase.auth().currentUser.uid +
        '&patient=' + this.state.patientId +
        '&patient2=' + this.state.patientId2 +
        '&date=' + this.state.date * 10
      )

      const qrRender = (
        <Fragment>
          { firebase.auth().currentUser &&
            <div id="qr-modal" onClick={(event) => event.stopPropagation()}>
              <QRCode className="qr" size={256} value={qrLink}/>
              <button className="btn" onClick={this.onClickQR}>Close</button>
            </div>
          }
        </Fragment>
      )

      const checkBoxRender = (disease) => {
        return (
          <div>
            <Checkbox
              onChange={(event) => {
                this.handleSwitch(event, disease)
              }}
              name={disease}
              disabled={
                patientsHash[disease] ?
                false : true
              }
              style={{padding: '0'}}
              color="default"
            />
            <label
              htmlFor={disease}
              className={
                patientsHash[disease] ?
                null : 'grey-text'
              }
            >{disease}
            </label>
          </div>
        )
      }

      const checkBoxes = (diseaseArr = this.state.diseases) => {
        return (
          <Fragment>
            {diseaseArr.map((disease) => {
              return (
                checkBoxRender(disease)
              )
            })}
          </Fragment>
        )
      }

      return (
        <Fragment>
          { this.state.qr &&
            <div id="grey-screen"
              onClick={() => this.setState({qr: false})}
            ></div>
          }
          { this.state.isLoading || this.props.loadingTests ?
            <Loading /> :
            <div
              id="patient-page"
              style={{height: `${this.state.mainHeight - 110}px`}}
              onClick={this.setQr}
            >
              { this.state.qr && qrRender }
              { this.state.subFullMessage &&
                <div className="failure-snack slideInLeft">
                  <p>Your subscription is full</p>
                </div>
              }
              <header id="patient-header" className="patient-div">
                <div className="patient-img-div">
                  <img
                    className="patient-img"
                    src={patient[0].image ? patient[0].image : profilePic}
                  />
                </div>
                <p
                  className="background-white patient-name-p"
                >{patient[0].name}
                </p>
                <div className="flex-center">
                  <div className="background-white align-center">
                    <p
                      className="background-white last-updated-p grey-text"
                    >Birthday:
                    </p>
                    {<p className="no-margin background-white">
                      { patient[0].birthday &&
                        helpers.formatDate(patient[0].birthday)
                      }
                    </p>
                    }
                  </div>
                </div>
                <div className="background-white align-center flex-end">
                  { patient[0].birthday &&
                    <div className="align-center update-div">
                      <p
                        className="background-white last-updated-p grey-text"
                      >Last Updated:
                      </p>
                      <p className="no-margin background-white">
                        { patient[0].lastDate &&
                          helpers.formatDate(
                              patient[0].lastDate.slice(
                                  0, patient[0].lastDate.length - 9,
                              ),
                          )
                        }
                      </p>
                    </div>
                  }
                </div>
              </header>
              <div className={'patient-page-content'}>
                <aside id="patient-aside">
                  <div id="aside-top">
                    <div id="total-tests-div" className="aside-div">
                      <p>Total Tests</p>
                      <h1>
                        {this.props.physicianTests ?
                        this.props.physicianTests.length : 'N/A'}
                      </h1>
                    </div>
                    <div
                      className={
                        this.state.flagFilter ?
                        'filter-div selected-div' :
                        'filter-div'
                      }
                    >
                      <span>Flagged Tests</span>
                      <div>
                        <span>
                          {' ' + filterTestsLengthSingleParam('Flag')}
                        </span>
                        <Switch
                          color={'default'}
                          onChange={(event) => {
                            this.handleSwitch(event, 'flagFilter')
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        this.state.notesFilter ?
                        'filter-div selected-div' :
                        'filter-div'
                      }
                    >
                      <span>with Notes</span>
                      <div>
                        <span>
                          {' ' + filterTestsLengthSingleParam('Notes')}
                        </span>
                        <Switch
                          color={'default'}
                          onChange={(event) => {
                            this.handleSwitch(event, 'notesFilter')
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={this.handleHiding2(
                        this.state.diseaseFilter ?
                        'filter-container selected-div':
                        'filter-container',
                      )}
                    >
                      <div
                        id="disease-filter-div"
                        className="filter-div"
                      >
                        <span>Classification</span>
                        <div>
                          <span>
                            {' ' + numberOfClassifications.toString()}
                          </span>
                          <Switch
                            color={'default'}
                            onChange={(event) => {
                              this.handleSwitch(event, 'diseaseFilter')
                            }}
                          />
                        </div>
                      </div>
                      <div className={this.state.checkBoxClasses}>
                        {checkBoxes()}
                      </div>
                    </div>
                    <div
                      className={this.handleHiding2(
                        this.state.confidenceValues[0] > 0 ||
                          this.state.confidenceValues[1] < 99 ?
                        'confidence-filter-div-container selected-div' :
                        'confidence-filter-div-container',
                      )}
                    >
                      <div className="flex-between">
                        <span>Confidence</span>
                        <span>
                          {' ' + this.state.confidenceValues[0].toString() +
                          '-' + this.state.confidenceValues[1].toString() + '%'}
                        </span>
                      </div>
                      <div
                        className={this.handleHiding('confidence-filter-div')}
                      >
                        <span className="margin-r-5">0%</span>
                        <Slider
                          onChange={(e, val) => {
                            this.handleConfidenceFilter(e, val)
                          }}
                          ValueLabelComponent={ValueLabelComponent}
                          defaultValue={0}
                          value={this.state.confidenceValues}
                          max={99}
                          style={{width: '60%', display: 'inline-block'}}
                        />
                        <span className="margin-l-5">99%</span>
                      </div>
                    </div>
                    <div className="back-btn" onClick={ () => this.goBack() }>
                      <h3 className="white-text">Back</h3>
                    </div>
                  </div>
                  <div className="image-btn-container">
                    <ImageUpload
                      patientId2={this.state.patientId2}
                      patientId={this.state.patientId}
                      isPatient={false}
                    />
                    <h3 className="image-btn-h3" onClick={this.onClickQR}>
                      QR Code
                    </h3>
                  </div>
                </aside>
                <div className="list-div">
                  { this.props.physicianTests &&
                    this.props.physicianTests.length === 0 &&
                    <h3
                      className="center-text"
                    >{`Click 'Upload Image' or 'QR Code' to get started`}
                    </h3>
                  }
                  { this.props.physicianTests &&
                    this.props.physicianTests.length !== 0 &&
                    filterTests.length === 0 &&
                    <h3 className="center-text">
                      All tests have been filtered out
                    </h3>
                  }
                  { filterTests &&
                    filterTests.map((test, i) => (
                      <Link className={'link'} key={i} to={{
                        pathname: `/test/?id=${test.id}`,
                        query: {
                          id: test.id,
                        },
                      }}>
                        <div className="test-div">
                          <div className="test-img-div">
                            <img
                              className="test-img"
                              src={test.data.ImageRef}
                            />
                          </div>
                          <div
                            className={this.handleHiding(
                                'confidence-container',
                            )}
                          >
                            <div className="confidence-div">
                              <CircularProgressbarWithChildren
                                value={
                                  test.data.Confidence &&
                                  test.data.Confidence === 1 ?
                                  100 :
                                  parseInt(
                                      test.data.Confidence.toString()
                                          .slice(2, 4),
                                  )
                                }
                                styles={buildStyles({
                                  textColor: 'black',
                                  pathColor: helpers.determinePathColor(
                                      test.data.Results,
                                      test.data.Confidence,
                                      test.data.Reasoning,
                                  ),
                                  trailColor: '#ececec',
                                })}
                                strokeWidth={4}
                              >
                                <h3 className="margin-0">
                                  { test.data.Confidence &&
                                    test.data.Confidence === 1 ? '100%' :
                                    test.data.Confidence.toString()
                                        .slice(2, 4) + '%'
                                  }
                                </h3>
                                <p className="margin-0 size-10">Confidence</p>
                              </CircularProgressbarWithChildren>
                            </div>
                          </div>
                          <div className={this.handleHiding('results')}>
                            <p className="grey-text">Results</p>
                            <p
                              className={
                                helpers.isPotentialMelanoma(
                                    test.data.Reasoning,
                                ) &&
                                test.data.Results.includes('lesion') ?
                                'smaller-text' :
                                'regular-text'
                              }
                            >{helpers.isPotentialMelanoma(test.data.Reasoning) &&
                              test.data.Results !== 'Melanoma' ?
                              `${test.data.Results} (possible melanoma)` :
                              test.data.Results
                              }
                            </p>
                          </div>
                          <div className="flex-center-align-center">
                            <div className="flag-tag-notes">
                              <div>
                                { test.data.Flag ?
                                  <img
                                    className="icon"
                                    src={flag}
                                    alt="flag"
                                  /> :
                                  <div className="empty-div"></div>
                                }
                              </div>
                              <div>
                                { test.data.Notes ?
                                  <img
                                    className="icon"
                                    src={notes}
                                    alt="notes" /> :
                                  <div className="empty-div"></div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="date-taken">
                            <p className="grey-text">Test Date</p>
                            <p>
                              {helpers.formatDate(test.data.DateTaken.slice(0, 10))}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))
                  }
                </div>
              </div>
            </div>
          }
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Loading />
        </Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  signedIn: state.authReducer.signedIn,
  firestore: state.firebaseReducer.firestore,
  patientList: state.patientsReducer.patientList,
  fullTestList: state.patientsReducer.patientTestData.fullTestList,
  patientTests: state.patientsReducer.patientTestData.patientTests,
  physicianTests: state.patientsReducer.patientTestData.tests,
  loadingTests: state.patientsReducer.patientTestData.loading,
  patientGender: state.patientsReducer.patientTestData.gender,
  signedIn: state.authReducer.signedIn,
  subscriptionData: state.authReducer.subscriptionData,
  userData: state.authReducer.userData,
  isSubscriptionFull: state.authReducer.isSubscriptionFull,
})

export default connect(mapStateToProps, {fetchPatientData, storePatientData, hideNav})(Patient)

