const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: "https://lazarus-cds.firebaseio.com",
  projectId: "lazarus-cds",
  storageBucket: "lazarus-cds.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
}
export default firebaseConfig
