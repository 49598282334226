import types from '../actions/types'

export const startUpUpdateFunctions = () => (dispatch) => {
  const detatchFunc = function() {
    console.log('Nothing to detatch')
  }

  const modifyFunc = function() {
    console.log('Nothing to modify')
  }

  dispatch({
    type: types.UPDATE_PATIENT_LISTENER_FUNCTIONS,
    payload: {
      from: 'Startup',
      detatch: detatchFunc,
      modify: modifyFunc,
    },
  })
}
