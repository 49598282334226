import types from './types'
import firebase from 'firebase/app';
import 'firebase/auth';

export const onClickSignUp = (userInfo, database) => dispatch => {
  const userData = {
    Info : {
      Name : `${userInfo.firstName} ${userInfo.lastName}`,
      ContactEmail : userInfo.email,
      Practice : userInfo.practiceName,
      Address : userInfo.practiceAddress,
      ImageRef : userInfo.image ? userInfo.image : 'none',
      ImageName: userInfo.imageName? userInfo.imageName : ''
    },
    Membership : {
      Active : "created"
    }
  }
  firebase.auth().createUserWithEmailAndPassword(userInfo.email, userInfo.password).then(() => {
    if (firebase.auth().currentUser) {
      database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid).set(userData).then(() => {
        database.ref('Dermatology/NewRequests').push().set(firebase.auth().currentUser.uid)
      }).catch((error) => console.log(error))
    }
  }).then(() => dispatch({
    type: types.SIGN_UP,
    payload: {
      signedIn: false,
      userMessage: 'Please wait whie we review your qualifications'
    }
  })).catch((error) => {
    dispatch({
      type: types.SIGN_UP,
      payload: {
        signedIn: false,
        userMessage: 'There was an error creating your account'
      }
    })
  })
}
