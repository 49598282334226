import types from '../actions/types'

const initialState = { hideNav: false }

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SIGN_IN: case types.SIGN_UP:
      return {
        ...state,
        signedIn: action.payload.signedIn,
        userMessage: action.payload.userMessage,
        userData: action.payload.userData,
        isSubscriptionActive: action.payload.isSubscriptionActive,
        subscriptionData: action.payload.subscriptionData
      }
    case types.SIGN_OUT:
      return {
        signedIn: action.payload.signedIn,
        userMessage: action.payload.userMessage
      }
    case types.CLEAR_USER_MESSAGE:
      return {
        ...state,
        userMessage: action.payload
      }
    case types.CLEAR_PROMO_MESSAGE:
      return {
        ...state,
        promoMessage: action.payload
      }
    case types.HIDE_NAV:
      return {
        ...state,
        hideNav: action.payload
      }
    case types.SUBMIT_PROMO_CODE:
      if (action.payload.isSubscriptionActive) {
        return {
          ...state,
          hideNav: action.payload,
          isSubscriptionActive: action.payload.isSubscriptionActive,
          promoMessage: action.payload.promoMessage,
          time: action.payload.time
        }
      } else {
        return {
          ...state,
          hideNav: action.payload,
          promoMessage: action.payload.promoMessage,
          time: action.payload.time
        }
      }
    case types.STORE_USAGE:
      return {
        ...state,
        isSubscriptionFull: action.payload
      }
    case types.ACTIVATE_SUBSCRIPTION:
      return {
        ...state,
        isSubscriptionActive: action.payload
      }
    default:
      return state
  }
}
