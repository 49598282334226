import types from './types'
import firebase from 'firebase/app';

export const signOut = () => (dispatch) => {
  firebase.auth().signOut()
  .then(() => {
    dispatch({
      type: types.SIGN_OUT,
      payload: {
        signedIn: false,
        userMessage: null,
      }
    })
  })
  .then(() => dispatch({type: 'CLEAR_STORE'}))
  .catch((error) => {
    console.log('Error on sign out', error)
  });
}
