import types from '../actions/types'

function initFunc() {
  console.log('========Testing========')
}

const initialState = {
  patientList: [],
  patientTestData: [],
  search: null,
  patientData: null,
  testData: null,
  testSetTest: null,
  personImageUrl: null,
  userData: {},
  createPatientResponse: {responseMessage: '', patientCreated: false},
  detatch: initFunc,
  modifyPatientTests: initFunc,
  isBatchView: true,
  batches: [],
  isLoadingBatches: true,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PATIENTS:
      return { 
        ...state,
        patientList: action.payload,
        // patientData: null, // Do we need this?
        testData: null,
        testSetTest: null,
        createPatientResponse: {responseMessage: '', patientCreated: false}}
    case types.FETCH_PATIENT_DATA:
      return {
        ...state,
        patientTestData: action.payload,
        createPatientResponse: null,
        testSetTest: null,
        testSetPatient: null,
      }
    case types.POST_PERSON_IMAGE:
      return {
        ...state,
        personImageUrl: action.payload.payloadDownloadUrl,
        imageName: action.payload.imageName,
      }
    case types.FETCH_USER_STATS:
      return {...state, userStats: action.payload}
    case types.SEARCH_PATIENTS:
      return {...state, search: action.payload}
    case types.STORE_PATIENT_DATA:
      return {...state, patientData: action.payload, testData: null} // Data used for breadcrumbs in navbar (name)
    case types.STORE_TEST_DATA:
      return {...state, testData: action.payload} // Data used for breadcrumbs in navbar (time of test)
    case types.CREATE_PATIENT:
      return {
        ...state,
        createPatientResponse: action.payload,
      }
    case types.TOGGLE_LOADER:
      return {...state, patientTestData: {...state.patientTestData, loading: action.payload}}
    case types.UPDATE_PATIENT_LISTENER_FUNCTIONS:
      return {...state, detatch: action.payload.detatch, modifyPatientTests: action.payload.modify}
    case types.STORE_TESTSET_DATA:
      return {
        ...state,
        testSetTest: action.payload.test,
        testSetPatient: action.payload.patient,
        testSetNotes: action.payload.notes,
        testSetIndex: action.payload.testSetIndex,
        testIndex: action.payload.testIndex,
      }
    case types.STORE_BATCH:
      return {
        ...state,
        batch: action.payload,
      }
    case types.STORE_ISBATCHVIEW:
      return {
        ...state,
        isBatchView: action.payload,
      }
    case types.FETCH_BATCHES:
      return {
        ...state,
        batches: action.payload.batches,
        isLoadingBatches: action.payload.isLoadingBatches,
      }
    default:
      return state
  }
}
