import React, {Component, Fragment} from 'react';
import dm from '../../../img/Dm.svg'
import notes from '../../../img/Notes.svg'
import profile from '../../../img/Default.svg'

class PatientContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return(
      <div className="email-modal-content">
        <div>
          <div className="flex space-between">
            <img className="dm" src={dm} alt="Dm" />
            <div className="m-proc">
              <span><strong>M-PROC</strong></span>
            </div>
          </div>
          <div>
            <p className="margin-0">Clinical Decision Support</p>
            <p className="margin-0"><strong>Dermatology</strong></p>
          </div>
          <div className="testing-for-div selected-div">
            <div>
              <p>Testing for</p>
              <img src={profile} />
            </div>
            <p>{this.props.patientName}</p>
          </div>
          <div className="patient-info-div">
            <p>Patient Email</p>
            <p>{this.props.patientEmail}</p>
          </div>
          <div className="patient-info-div">
            <p>Patient Phone</p>
            <p>{this.props.patientPhone}</p>
          </div>
        </div>
        <div>
          <div className="flex space-between">
            <p>Message to patient</p>
            <img src={notes} />
          </div>
          <div>
            <p>{this.props.defaultMessage}</p>
          </div>
          <div className="physician-message-div">
            <textarea
              onChange={(event) => this.props.onChangePhysicianMessage(event.target.value)}
            />
          </div>
        </div>
      </div>
    )
  }
}


export default PatientContactModal
