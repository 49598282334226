import firebase from 'firebase/app';
import types from './types'

export const cancelSubscription = data => dispatch => {
  dispatch({
    type: types.LOADING_SUBSCRIPTION,
    payload: {
      isLoadingDone: false,
      userMessage: null
    }
  })
  let subID = data["subID"]

  async function func() {
    try {
      let rawResponse = await fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/deleteSub?subID=` + subID, {
        method: 'GET',
      })
      const res = await rawResponse.json();
      if (res['status'] != 'ERROR') {
        dispatch({
          type: types.FETCH_SUBSCRIPTION,
          payload: {
            status: res["status"],
            subPlan: res["plan"]["id"],
            subID: res["id"],
            cusID: res["customer"],
            amount: res["items"]["data"][0]["plan"]["amount"],
            interval: res["items"]["data"][0]["plan"]["interval"],
            start: res["current_period_start"],
            end: res["current_period_end"]
          }
        })
        dispatch({
          type: types.LOADING_SUBSCRIPTION,
          payload: {
            isLoadingDone: true,
            userMessage: 'Your subscription will not renew at the end of your billing cycle'
          }
        })
      } else {
        dispatch({
          type: types.LOADING_SUBSCRIPTION,
          payload: {
            isLoadingDone: true,
            userMessage: 'There was an error updating your subscription'
          }
        })
      }
    } catch {
      dispatch({
        type: types.LOADING_SUBSCRIPTION,
        payload: {
          isLoadingDone: true,
          userMessage: 'There was an error updating your subscription'
        }
      })
    }
  }
  func()
}
