import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'
import {CookiesProvider} from 'react-cookie'
import store from './store'
import App from './App';

const rootElement = document.getElementById('root');

const routing = (
  <CookiesProvider>
    <Provider store={store}>
      <Router>
        <Fragment>
          <App />
        </Fragment>
      </Router>
    </Provider>
  </CookiesProvider>
);

ReactDOM.render(routing, rootElement);
