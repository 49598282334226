import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { signOut } from '../Auth/actions/signOutAction'
import '../index.scss';
import defaultImg from '../img/User.svg'
import Switch from '@material-ui/core/Switch'
import Slider from '@material-ui/core/Slider'
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import firebase from 'firebase/app';
import { withCookies, Cookies } from 'react-cookie';

class ProfilePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAIHidden: false
    }
  }

  componentDidMount() {
    if (this.props.userData && this.props.userData.Settings && this.props.userData.Settings.hasOwnProperty('IsAIHidden')) {
      this.setState({isAIHidden: this.props.userData.Settings.IsAIHidden})
    }
  }

  signOut = () => {
    const signOutPromise = new Promise((res, rej) => {
      // console.log('signing out')
      res()
    })

    signOutPromise
      .then(() => {
        this.props.cookies.remove('email', { path: '/ '})
        this.props.cookies.remove('password', { path: '/ '})
      })
      .then(() => this.props.signOut())
  }

  handleAISwitch = () => {
    this.props.database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Settings').update({
      IsAIHidden: !this.state.isAIHidden
    })
    this.setState({isAIHidden: !this.state.isAIHidden})
  }


  render() {
    const hoverLogic = () => {
      if (!this.props.hasBeenHovered) {
        return 'disappear'
      }
      else if (this.props.isHovering) {
        return 'popup-container fadeIn'
      } else {
        return 'popup-container fadeOut'
      }
    }
    return (
      <Fragment>
        <div
          // onMouseEnter={() => this.props.onHover(true)}
          onMouseLeave={() => this.props.onHover(false)}
          className={hoverLogic()}
        >
          <div id="pic-name-email-div" className={this.props.isHovering ? 'show' : 'hide'}>
            <img src={ this.props.userData && this.props.userData.Info.ImageRef !== 'none' ? this.props.userData.Info.ImageRef : defaultImg } />
            <div>
              <p>{this.props.userData && this.props.userData.Info.Name}</p>
              <p className="grey-text">{this.props.userData && this.props.userData.Info.ContactEmail}</p>
              <div>
                <span>AI</span>
                <Switch
                  color={'default'}
                  onChange={this.handleAISwitch}
                  checked={!this.state.isAIHidden}
                />
              </div>
            </div>
          </div>
          <div id="settings-signout-div" className={ this.props.isHovering ? 'fadeIn' : 'fadeOut' }>
            <Link id="nested-settings-btn" to="/settings" className={ this.props.isHovering ? 'fadeIn' : 'fadeOut' }>
              <div>
                <p className={this.props.isHovering ? 'show' : 'hideFast'}>Settings</p>
              </div>
            </Link>
            <div id="sign-out-btn" onClick={this.signOut}>
              <p className={this.props.isHovering ? 'show' : 'hideFast'}>Sign out</p>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  userData: state.authReducer.userData,
  cookies: ownProps.cookies
})

export default withCookies(withRouter(connect(mapStateToProps, { signOut })(ProfilePopup)))