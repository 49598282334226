const types = {
  FETCH_PATIENTS: 'FETCH_PATIENTS',
  FETCH_PATIENT_DATA: 'FETCH_PATIENT_DATA',
  POST_PERSON_IMAGE: 'POST_PERSON_IMAGE',
  FETCH_USER_STATS: 'FETCH_USER_STATS',
  SEARCH_PATIENTS: 'SEARCH_PATIENTS',
  STORE_PATIENT_DATA: 'STORE_PATIENT_DATA',
  STORE_TEST_DATA: 'STORE_TEST_DATA',
  CREATE_PATIENT: 'CREATE_PATIENT',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  UPDATE_PATIENT_LISTENER_FUNCTIONS: 'UPDATE_PATIENT_LISTENER_FUNCTIONS',
  STORE_TESTSET_DATA: 'STORE_TESTSET_DATA',
  STORE_BATCH: 'STORE_BATCH',
  STORE_ISBATCHVIEW: 'STORE_ISBATCHVIEW',
  FETCH_BATCHES: 'FETCH_BATCHES'
}

export default types
