import React, { Component, Fragment } from 'react';
import '../index.scss';

export default class Loading extends Component {
  render() {
    return (
      <div id="loading-container">
        <div className="box">
          <div className="b b1"></div>
          <div className="b b2"></div>
          <div className="b b3"></div>
          <div className="b b4"></div>
        </div>
      </div>
    )
  }
}
