/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, {Component} from 'react';
import queryString from 'query-string';
import Loading from '../RootComponents/Loading.js'

class PayoutConnect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'Loading',
    };
  }

  componentDidMount() {
    const self = this;
    if (queryString.parse(window.location.search).state && queryString.parse(window.location.search).code) {
      fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/handlePayoutAccount?state=` + queryString.parse(window.location.search).state.slice(0, -1) + `&code=` + (queryString.parse(window.location.search).code), {
        method: 'GET',
      }).then((res) => res.json()
      ).then((res) => {
        // self.setState({ status: res['status'] });
        if (res['status'] === 'Success') {
          // alert('You can close this tab and return to the original tab');
          self.setState({status: 'Success! You can close this tab'});
        } else {
          // alert('There was an error.')
          self.setState({status: 'There was an error. Please try again later.'});
        }
      }).catch(function(error) {
        // alert('There was an error.');
        console.log(error);
        // self.setState({ status: error });
        self.setState({status: 'There was an error. Please try again later.'});
      });
    } else {
      alert('You should not be here');
    }
  }

  render() {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {this.state.status === 'Loading' ?
          <Loading /> :
          <h3 style={{textAlign: 'center'}}>{this.state.status}</h3>
        }
      </div>
    );
  }
}

export default PayoutConnect;
