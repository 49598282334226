import types from './types'

export const storeUsage = usage => dispatch => {
    let isSubscriptionFull = false
    if (usage[0] >= usage[1]) {
        isSubscriptionFull = true
    }
    dispatch({
        type: types.STORE_USAGE,
        payload: isSubscriptionFull
    })
}