import firebase from 'firebase/app';
import types from './types'
import {fetchPatientData} from '../Patients/actions/fetchPatientDataAction'

export const createPatient = (database, firestore, patientData) => (dispatch) => {

  // Generate unique keys from database

  let refKey = database.ref().push().key
  let newRefKey = database.ref().push().key

  // Add random number to key to make second key less guessable

  refKey += Math.random().toString().split('.')[1]
  newRefKey += Math.random().toString().split('.')[1]
  const newDate = new Date()
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const hour = newDate.getHours();
  const min = newDate.getMinutes();
  const sec = newDate.getSeconds();

  const birthdayCheck = patientData.birthday.split('-')

  const isNotNumeric = (value) => {
    return !(/^\d+$/.test(value))
  }

  const isBirthdayValid = () => {
    return (
      birthdayCheck.length != 3 ||
      isNotNumeric(birthdayCheck[0]) ||
      isNotNumeric(birthdayCheck[1]) ||
      isNotNumeric(birthdayCheck[2]) ||
      birthdayCheck[0].toString().length != 4 ||
      birthdayCheck[1].toString().length > 2 ||
      parseInt(birthdayCheck[2]).toString().length > 2
    )
  }

  const handleInputs = () => {
    if (patientData.firstName === '' || patientData.lastName === '') {
      return ('Please enter the full name of the patient')
    } else if (patientData.birthday === '') {
      return ('Please enter the birthday of the patient')
    } else if (isBirthdayValid()) {
      return ('Please enter the birthday in the correct format')
    } else if (patientData.sex === '') {
      return ('Please select the sex of your patient')
    } else {
      return ('Valid entry')
    }
  }

  if (handleInputs() === 'Valid entry') {
    database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Patients/'+ refKey).set({
      ID: newRefKey,
      Name: `${patientData.firstName} ${patientData.lastName}`,
      Created: `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`} ${hour<10?`0${hour}`:`${hour}`}:${min<10?`0${min}`:`${min}`}:${sec<10?`0${sec}`:`${sec}`}`,
      LastUpdate: `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`} ${hour<10?`0${hour}`:`${hour}`}:${min<10?`0${min}`:`${min}`}:${sec<10?`0${sec}`:`${sec}`}`,
      ImageRef: patientData.image ? patientData.image : null,
      Birthday: patientData.birthday
    }).then(() => {
      firestore.collection('Patients').doc(`${newRefKey}`).set({
        Birthday: patientData.birthday,
        Gender: patientData.sex,
        Tests: [],
      })
    }).then(() => {
      database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/PatientList/'+ refKey).set(true)
    }).then(() => {
      const newDate = new Date()
      const date = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/NewPatients`).once('value').then(function(snapshot) {
        if (snapshot.exists()) {
          database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/NewPatients`).set(snapshot.val() + 1)
        } else {
          database.ref('Dermatology/Stats/' + firebase.auth().currentUser.uid + `/${year}/${month}/${date}/NewPatients`).set(1)
        }
      })
    }).then(() => {
      dispatch(fetchPatientData(firestore, newRefKey))
    }).then(() => {
      dispatch({
        type: types.CREATE_PATIENT,
        payload: {
          patientCreated: true,
          responseMessage: 'New patient added',
          id: refKey,
          id2: newRefKey,
        },
      })
    }).catch((error) => {
      console.log(error)
      dispatch({
        type: types.CREATE_PATIENT,
        payload: {
          patientCreated: false,
          responseMessage: 'There was an error adding a patient. Please try again later',
        },
      })
    })
  } else {
    dispatch({
      type: types.CREATE_PATIENT,
      payload: {
        patientCreated: false,
        responseMessage: handleInputs(),
      }
    })
  }
}
