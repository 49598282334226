import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {hideNav} from '../../../Auth/actions/hideNavAction'
import './index.scss';
import profilePic from '../../../img/User.svg'
import notesImg from '../../../img/Notes.svg'
import heatMapImg from '../../../img/Block.svg'
import flagImg from '../../../img/Flag.svg'
import check from '../../../img/Check.svg'
import Loading from '../../../RootComponents/Loading'
import {storeTestData} from '../../actions/storeTestDataAction.js'
import helpers from '../../../helperFunctions.js'

import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Switch from '@material-ui/core/Switch'

import queryString from 'query-string'

/* Same component used for physican-taken and patient-taken tests
   Checks for !this.props.testSetTest for physician-test specific elements
*/

class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patientNotes: '',
      patientTestData: {
        Flag: false,
        Tag: '',
        Notes: ''
      },
      areNotesSet: false // notes are set in CDU when state.patientTest is truthy
    }

    this.notesRef = React.createRef()
    this.setNotes = this.setNotes.bind(this)
    this.handleSubmitTestSetNotes = this.handleSubmitTestSetNotes.bind(this)
    this.handleSubmitNotes = this.handleSubmitNotes.bind(this)
    this.handleFlag = this.handleFlag.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.goBack = this.goBack.bind(this)
    this.handleHiding = this.handleHiding.bind(this)
    this.handleHiding2 = this.handleHiding2.bind(this)
  }

  componentDidMount() {
    if (this.props.firestore.app) { // If signedIn
      let patientNotes
      if (this.props.testSetNotes) {
        patientNotes = this.props.testSetNotes
      }
      this.props.hideNav(false)
      const testId = queryString.parse(window.location.search).id
      let patientTest
      if (this.props.testSetTest) {
        patientTest = this.props.testSetTest
      } else {
        patientTest = this.props.patientTests.filter(test => {
          return test.id === testId
        })[0]
      }
      this.props.storeTestData({
        dateTaken: patientTest.data.DateTaken,
        id: testId
      })
      this.setState({
        testId: testId,
        patientTest: patientTest,
        patientTestData: { ...this.state.patientTestData, ...patientTest.data },
        patientNotes: patientNotes
      })
      this.setNotes()
    }
  }

  setNotes() { // Render notes in the text area after dom element is rendered
    const checkAndSet = (notes) => { // check for the existance of notes text area before setting notes
      if (this.notesRef.current) {
        this.notesRef.current.value = notes || ''
        this.setState({areNotesSet: true})
      }
    }
    if (!this.props.testSetTest) {
      checkAndSet(this.state.patientTestData.Notes)
    } else {
      checkAndSet(this.props.batch.TestSets[this.props.testSetIndex].Tests[this.props.testIndex].PhysicianNotes)
    }
  }

  componentDidUpdate() {
    if (this.state.submitNotes) {
      setTimeout(() => {
        this.setState({ submitNotes: false})
      }, 3000);
    }
    if (this.state.patientTest && !this.state.areNotesSet) {
      this.setNotes()
    }
  }

  handleChange(event) {
    this.setState({
      patientTestData: {
        ...this.state.patientTestData,
        [event.target.name]: event.target.value
      }
    })
  }

  handleSubmitTestSetNotes(event) { // Submit physician notes to remote test
    event.preventDefault()
    const newTest = {
      ...this.props.batch.TestSets[this.props.testSetIndex].Tests[this.props.testIndex],
      PhysicianNotes: this.state.patientTestData.Notes
    }
    const newTestSets = this.props.batch.TestSets
    newTestSets[this.props.testSetIndex].Tests[this.props.testIndex] = newTest
    // console.log('new test sets', newTestSets)
    this.props.firestore.collection("PatientTests").doc(this.props.batch.batchId).update({
      TestSets: newTestSets
    })
    if (!this.state.submitNotes) {
      this.setState({ submitNotes: true })
    }
  }

  handleSubmitNotes(event) { // Submit physician notes to local test
    event.preventDefault()
    this.props.firestore.collection("Tests").doc(this.state.testId).update({
      Notes: this.state.patientTestData.Notes
    })
    if (!this.state.submitNotes) {
      this.setState({ submitNotes: true })
    }
    this.props.modifyPatientTests(this.state.patientTest.num, "notes", this.state.patientTestData.Notes)
  }

  handleFlag() { // Local test exclusive
    this.setState({
      patientTestData: {
        ...this.state.patientTestData,
        Flag: !this.state.patientTestData.Flag
      }
    })
    this.props.firestore.collection("Tests").doc(this.state.testId).update({
      Flag: !this.state.patientTestData.Flag
    })
    this.props.modifyPatientTests(this.state.patientTest.num,"flag",!this.state.patientTestData.Flag)
  }

  handleSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  }

  goBack() {
    this.props.history.goBack();
  }

  handleHiding(defaultClass) {
    if (this.props.userData && this.props.userData.Settings && this.props.userData.Settings.IsAIHidden) {
      return defaultClass + ' hidden'
    } else {
      return defaultClass
    }
  }

  handleHiding2(defaultClass) {
    if (this.props.userData && this.props.userData.Settings && this.props.userData.Settings.IsAIHidden) {
      return 'display-none'
    } else {
      return defaultClass
    }
  }

  render() {
    if (this.props.firestore.app && this.props.patientData) { // If signedIn
      let patient

      if (this.props.testSetTest) {
        patient = this.props.testSetPatient
      } else {
        patient = this.props.patientData.patient
      }

      const mainHeight = window.innerHeight - 110
      if (this.state.patientTest) {
        return (
          <div
            id="test-page"
            className={this.props.testSetTest ? 'test-set-test-page': 'test-page'}
            style={{ height: `${mainHeight}px` }}
          >
            <header id="test-header">
              <div className="patient-img-div">
                <img className="patient-img" src={patient.image ? patient.image : profilePic} />
              </div>
              <p className="background-white patient-name-p">{patient.name}</p>
              <div className="flex-center">
                <div className="background-white align-center">
                  <p className="background-white last-updated-p grey-text">Birthday:</p>
                  { <p className="no-margin background-white">
                      { patient.birthday && helpers.formatDate(patient.birthday) }
                    </p>
                  }
                </div>
              </div>
              <div className="background-white align-center flex-end">
                { patient.birthday &&
                  <div className="align-center update-div">
                    <p className="background-white last-updated-p grey-text">Last Updated:</p>
                    <p className="no-margin background-white">
                      { patient.lastDate ?
                      helpers.formatDate(patient.lastDate.slice(0, patient.lastDate.length - 9)) :
                      "N/a"
                      }
                    </p>
                  </div>
                }
              </div>
            </header>
            <div id="test-main">
              {/*<aside id="test-aside" style={{ height: window.innerHeight - 300 }}> */} {/* 20px of margin, height of top components is 280px */}
              <aside id="test-aside">
                <div className="aside-top">
                <div className="aside-div">
                  <p>Test Date</p>
                  <span className="font-16">{helpers.formatDate(this.state.patientTest.data.DateTaken.slice(0, 10))}</span>
                </div>
                <div className={ 
                  this.handleHiding2(this.state.heatMap ? "aside-div selected-div" : "aside-div")
                }>
                  <div className="flex-between">
                    <p className="margin-5">AI</p>
                    <img className="icon" src={heatMapImg} />
                  </div>
                  <div className="flex-between">
                    <span className="font-16">Heat Map</span>
                    <Switch
                      onChange={this.handleSwitch('heatMap')}/>
                  </div>
                </div>
                {!this.props.testSetTest &&
                <div className={ this.state.patientTestData.Flag ? "aside-div selected-div" : "aside-div" }>
                  <div className="flex-between">
                    <p className="margin-5">Test</p>
                    <img className="icon" src={flagImg} />
                  </div>
                  <div className="flex-between">
                    <span className="font-16 margin-5">Flag Test</span>
                    <Switch
                      onChange={this.handleFlag}
                      checked={this.state.patientTestData.Flag}
                      />
                  </div>
                </div>
                }
                { this.state.submitNotes &&
                <div className="success-snack slideInLeft">
                  <img src={check} /><p>Notes saved!</p>
                </div>
                }
              </div>
              <div className="back-btn" onClick={ () => this.goBack() } style={{ marginTop: '41px' }}>
                <h3 className="white-text">Back</h3>
              </div>
              </aside>
              <div id="test-image-dif-div">
                <img id="test-img-full" src={this.state.heatMap && this.state.patientTest.data.HeatMapRef
                  ? this.state.patientTest.data.HeatMapRef
                  : this.state.patientTest.data.ImageRef} />
                <div
                  id="differential-div"
                  className={this.handleHiding2(null)}
                >
                  <p className="grey-text">Differential</p>
                  { this.state.patientTest.data.Reasoning.map((disease, index)=> {
                    return(
                      <div key={'disease-' + index}>
                        <p className="disease-p">{ disease.Name }</p>
                        <p>{(disease.Confidence).toString() + '%'}</p>
                      </div>
                    )})
                  }
                </div>
              </div>
              <div id="test-results-notes-div">
                <div
                  id="test-page-results"
                  className={this.handleHiding2(null)}
                >
                  <div className="confidence-container">
                    <div className="confidence-div">
                      <CircularProgressbarWithChildren
                        value={
                          this.state.patientTest.data.Confidence === 1 ? 100 :
                          parseInt(this.state.patientTest.data.Confidence.toString().slice(2, 4))
                        }
                        styles={buildStyles({
                          textColor: "black",
                          pathColor: helpers.determinePathColor(this.state.patientTest.data.Results,this.state.patientTest.data.Confidence, this.state.patientTest.data.Reasoning),
                          trailColor: "#ececec"
                        })}
                        strokeWidth={4}
                      >
                        <h3 className="margin-0">{ this.state.patientTest.data.Confidence === 1 ? '100%'
                          : this.state.patientTest.data.Confidence.toString().slice(2, 4) + '%'
                        }
                        </h3>
                        <p className="margin-0 size-10">Confidence</p>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                  <div>
                    <p className="grey-text test-page-result-p">Result</p>
                    <p className={helpers.isPotentialMelanoma(this.state.patientTest.data.Reasoning) &&  this.state.patientTest.data.Results !== "Melanoma" ? "test-page-disease-p-small" : "test-page-disease-p"}>
                      { helpers.isPotentialMelanoma(this.state.patientTest.data.Reasoning) &&
                        this.state.patientTest.data.Results !== "Melanoma" ?
                        `${this.state.patientTest.data.Results} (possible melanoma)` :
                        this.state.patientTest.data.Results
                      }
                    </p>
                  </div>
                </div>
                <Fragment>
                  <div className="notes-div">
                    <p>Notes</p>
                    <img src={notesImg} />
                  </div>
                  {this.props.testSetTest &&
                  <Fragment>
                    <div className="patient-notes">
                      <p><strong>Patient: </strong>{this.props.testSetNotes || 'None'}</p>
                    </div> 
                  </Fragment>
                  }
                  <form id="text-field-form"
                    onSubmit={this.props.testSetTest ? this.handleSubmitTestSetNotes : this.handleSubmitNotes}
                  >
                    <textarea ref={this.notesRef} id="notes" name="Notes" onChange={this.handleChange} />
                    <input
                      id="notes-submit"
                      type="submit"
                      value="Submit"
                    />
                  </form>
                </Fragment>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <Loading />
        )
      }
    } else {
      return (
        <Redirect to='/' />
      )
    }
  }
}

const mapStateToProps = state => ({
  patientTests: state.patientsReducer.patientTestData.fullTestList,
  firestore: state.firebaseReducer.firestore,
  patientData: state.patientsReducer.patientData,
  modifyPatientTests: state.patientsReducer.modifyPatientTests,
  batch: state.patientsReducer.batch,
  testSetTest: state.patientsReducer.testSetTest,
  testSetPatient: state.patientsReducer.testSetPatient,
  testSetNotes: state.patientsReducer.testSetNotes,
  testSetIndex: state.patientsReducer.testSetIndex,
  testIndex: state.patientsReducer.testIndex,
  userData: state.authReducer.userData
})

export default connect(mapStateToProps, {storeTestData, hideNav})(Test)
