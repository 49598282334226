import types from './types'
import firebase from 'firebase/app';
import 'firebase/database';

export const postPersonImage = (originalImageName = '', imageFile, storage, database, personType) => dispatch => {
  let payloadDownloadUrl
  const uuidv4 = require('uuid/v4');
  const newImageName = uuidv4()
  const storageRef = storage.ref(personType)
  storageRef.child(newImageName).put(imageFile).then((snapshot) => //edit this
    snapshot.ref.getDownloadURL().then(downloadURL => {
      payloadDownloadUrl = downloadURL
      if (personType === 'Physicians') {
        database.ref('Dermatology/Physicians/' + firebase.auth().currentUser.uid + '/Info').update({ ImageRef: payloadDownloadUrl, ImageName: newImageName })
        if (originalImageName !== '') {
          storageRef.child(originalImageName).delete()
          // .then(() => console.log('User photo deleted'))
          // .catch(() => console.log('There was an error deleting the user photo'))
        }
      }
      // let newDate = new Date()
      //   let date = newDate.getDate();
      //   let month = newDate.getMonth() + 1;
      //   let year = newDate.getFullYear();
      //   let hour = newDate.getHours();
      //   let min = newDate.getMinutes();
      //   let sec = newDate.getSeconds();
      // firestore.collection("Patients").doc(`${imageName}`).set({
      //   DateTaken : `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`} ${hour<10?`0${hour}`:`${hour}`}:${min<10?`0${min}`:`${min}`}:${sec<10?`0${sec}`:`${sec}`}`,
      //   ImageRef : downloadURL
      // })
    }))
    .then(downloadURL => {
      dispatch({
        type: types.POST_PERSON_IMAGE,
        payload: {
          payloadDownloadUrl,
          imageName: newImageName
        }
      })
    })
}
