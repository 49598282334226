import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Redirect } from 'react-router-dom'
import { createPatient } from './createPatientAction'
import { toggleLoader } from '../Patients/actions/toggleLoaderAction'
import { hideNav } from '../Auth/actions/hideNavAction'
import ImageUpload from '../Patients/components/ImageUpload'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import exclam from '../img/Exclamation.svg';

import './index.scss';


class AddPatient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createPatientResponse: {
        patientCreated: false,
        responseMessage: ''
      },
      patientData: {
        firstName: '',
        lastName: '',
        birthday: 'yyyy-mm-dd',
        sex: '',
        image: null, // Url recieved from ImageUpload component via redux store
        month: 'mm',
        day: 'dd',
        year: 'yy'
      }
    }
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    this.props.hideNav(false)
    let mainHeight = window.innerHeight
    this.setState({ mainHeight })
    window.addEventListener('resize', () => {
      mainHeight = window.innerHeight
      this.setState({ mainHeight })
    })
  }

  componentWillReceiveProps(props) {
    this.setState({
      patientData: { ...this.state.patientData, image: props.personImageUrl },
      createPatientResponse: props.createPatientResponse
    })
  }

  componentDidUpdate() {
    if (this.state.createPatientResponse && this.state.createPatientResponse.responseMessage !== '') {
      setTimeout(() => {
        this.setState({
          ...this.state, createPatientResponse: { ...this.state.createPatientResponse, responseMessage: '' }
        })
      }, 3000);
    }
  }

  handleChange = event => {
    this.setState({ patientData: { ...this.state.patientData, [event.target.name]: event.target.value }})
  }

  handleBirthday = () => {
    let birthday = ''
    birthday+= (this.state.patientData.year + '-')
    birthday+= (this.state.patientData.month + '-')
    birthday+= (this.state.patientData.day)
    return birthday
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (this.state.patientData.month > 12) {
      alert('Birthday must by formatted: "mm/dd/yyyy", example: 12/31/1999')
    } else {
      this.props.detatch()
      this.props.toggleLoader(true)
      this.props.createPatient(
        this.props.database,
        this.props.firestore,
        { ...this.state.patientData, birthday: this.handleBirthday() }
      )
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    return (
      <div id="add-patient-page" style={{ height: `${this.state.mainHeight - 110}px` }}>
        <div id="add-patient-container">
          <div id="image-upload">
            <p>Patient Image</p>
            <ImageUpload isPatient={true} />
          </div>
          <div id="add-patient-form">
            <div>
              <label>First Name</label>
              <br></br>
              <input className="text-input" type="text" name="firstName" onChange={this.handleChange}/>
            </div>
            <div>
              <label>Last Name</label>
              <br></br>
              <input className="text-input" type="text" name="lastName" onChange={this.handleChange}/>
            </div>
            <div className="display-flex birthday-sex">
              <div className="width-45 birthday">
                <label>Birthday (mm/dd/yyyy)</label>
                <br></br>
                {/*<input className="text-input" style={{ height: '33px' }} type="date" placeholder="yyyy-mm-dd" name="birthday" onChange={this.handleChange}/>*/}
                <form>
                  <input className="text-input two-digit date-input month" type="number" max="12" min="1" placeholder="mm" name="month" onChange={this.handleChange}/>
                  <span>/</span>
                  <input className="text-input two-digit date-input day" type="number" max="31" min="1" placeholder="dd" name="day" onChange={this.handleChange}/>
                  <span>/</span>
                  <input className="text-input four-digit date-input year" type="number" min="1900" placeholder="yyyy" name="year" onChange={this.handleChange}/>
                </form>
              </div>
              <div className="width-45 sex">
                <label>Sex</label>
                <br></br>
                <Select
                  className="text-input2"
                  name="sex"
                  onChange={this.handleChange}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </div>
            </div>
            <div id="add-patient-btn-div">
              { this.state.createPatientResponse && this.state.createPatientResponse.responseMessage !== '' &&
                <div className="failure-snack slideInLeft">
                  <img src={exclam} />
                  <p>{this.state.createPatientResponse.responseMessage}</p>
                </div>
              }
              <div>
                <button id="back-btn" className="submit-btn" onClick={this.goBack}>Cancel</button>
                <button id="add-patient-btn" className="submit-btn" onClick={ this.handleSubmit }>Submit</button>
              </div>
            </div>
          </div>
        </div>
        { this.state.createPatientResponse && this.state.createPatientResponse.patientCreated
          && <Redirect
          to={{
            pathname: `/patient/?id2=${this.state.createPatientResponse.id2}&id=${this.state.createPatientResponse.id}`,
            query: {
              id: this.state.createPatientResponse.id2,
              id2: this.state.createPatientResponse.id
            }
          }}></Redirect>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientList: state.patientsReducer.patientList,
  createPatientResponse: state.patientsReducer.createPatientResponse,
  personImageUrl: state.patientsReducer.personImageUrl,
  detatch: state.patientsReducer.detatch
})

export default withRouter(connect(mapStateToProps, { createPatient, hideNav , toggleLoader })(AddPatient))
